angular.module('contacts.modals', [])

.factory("ModalContactWarning", [function(){
	 
	var modalContactWarning = {};          
	
	
	modalContactWarning.show = function(data, permissions){ 
		return {
                template: 
				`<form name="contactWarningsForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">{{title}}</h3>
					</div>
					<div class="modal-body default">  				
						<div class="form-group" ng-if="data.id != 'new'">
							<label>ID</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="id"					
								   ng-readonly="true"
								   ng-model="data.id" required/>                
						</div>

						<div class="form-group" ng-class="{'has-error': contactWarningsForm.warning.$invalid && contactWarningsForm.warning.$touched}">
							<label>Warning</label>
							<textarea ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="warning"
								   ng-readonly="!permissions['contacts.update']"
								   ng-model="data.warning" required/>
							</textarea>
							<p ng-show="contactWarningsForm.warning.$invalid && contactWarningsForm.warning.$touched" class="help-block">
											Required
							</p>
						</div>
						
						<div class="form-group" ng-if="!isNew">
							<label>Date Created</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="created_at"
									ng-readonly="true"
								   ng-model="data.created_at" required/>                
						</div>
						
						<div class="form-group" ng-if="!isNew">
							<label>User</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="user"					
								   ng-readonly="true"
								   ng-model="data.user" required/>                
						</div>
						
						<div class="form-group" ng-class="{'has-error': contactWarningsForm.status.$invalid && contactWarningsForm.status.$touched}">
							<label>Status</label>
							<select class="form-control" required name="status"  ng-model="data.status">
								<option value="{{status}}" ng-selected="data.status===status"
										ng-repeat="status in contact_warning_states">{{status | capitalize}}</option>                    
							</select>
							<p ng-show="contactWarningsForm.status.$invalid && contactWarningsForm.status.$touched" class="help-block">
								Required
							</p>					
						</div>   
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="saveRecord()"
										show-spinner="showSaveSpinner"												
										ng-disabled="$scope.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,      
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Guest Warning"
					},					
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, $uibModalInstance, data, title, permissions, 
						ContactsService, UtilBroadcastService, $timeout) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;
					$scope.contact_warning_states = ContactsService.getStatusForContactWarnings();
					$scope.isNew = ($scope.data.id==='new');
									
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = ContactsService.postContactWarningsStore($scope.data);
							message = 'Record created';			
						} else {
							promise = ContactsService.postContactWarningsUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.contactWarningsForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.isNew) $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();							
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);						
						});							
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}
				}
            };
	}	
	
			
	return modalContactWarning;
}])