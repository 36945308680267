angular.module('test-reports.controllers', [])

.controller('TestReportController', ['$state', '$http', function($state, $http) {
    var self = this;	
   
    var reportdata = {
                    "template": { "shortid" : "4ygFEoupKx" },
                    "data": { "aProperty": "value" }
                 };
    
    $http({
        method: 'POST',
        url: 'https://sams-wla.jsreportonline.net/api/report',
        data: reportdata,
        headers: {'Authorization': 'Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ=='}
    }).then(function(success){
        console.log(success);
    }, function(error){
        console.log(error);
    });
}])