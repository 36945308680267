angular.module('dashboard.directives')

	.directive('dashboardBookings', function (DashboardService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',	
				type: '@'
			},      
			template:	`<div class="panel panel-primary">
                            <div class="panel-heading clearfix">
                                <span class="panel-title pull-left"><i class="fa fa-clock-o fa-fw"></i> {{chartTitle}}</span>
								<div class="pull-right">
									<button type="button" class="btn btn-default rounded" 
											ng-click="getBookings()">
										<i class="fa fa-refresh"></i>
									</button>
								</div>
                            </div>
                            <div class="panel-body" style="height: 300px; overflow-y: scroll;">
                                <div  class="list-group">
									<div ng-show="!dataLoaded" class="text-center">
										<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
									</div>
									<table ng-show="dataLoaded" 
										class="table table-striped table-hover clickable"  >
										<thead>
											<tr>
												<th>Guest</th>											
												<th>Client</th>
												<!--<th>Check-In</th>-->
												<th ng-if="type!='partiallyInvoiced'">Check-Out</th>
												<th ng-if="type=='partiallyInvoiced'">Invoiced To</th>
												<th ng-if="type=='maintenance-held'">Type</th>
											</tr>
										</thead>
										<tbody >
											<tr ng-repeat="item in bookings" 
												class="table-row" 
												ui-sref="main.booking({ id: '{{item.id}}' })"
												href="">
												<td>{{item.contact_name}}</td>											
												<td>{{item.organisation_name }}</td>
												<!--<td><span class="badge">{{item.check_in_date | date:'dd-MMM-yyyy'}}</span></td>	-->
												<td ng-if="type!='partiallyInvoiced'"><span class="badge">{{item.check_out_date | date:'dd-MMM-yyyy'}}</span></td>	
												<td ng-if="type=='partiallyInvoiced'"><span class="badge">{{item.max_invoice_date | date:'dd-MMM-yyyy'}}</span></td>
												<td ng-if="type=='maintenance-held'"><span class="badge">{{item.type | capitalize}}</span></td>
											</tr>											
										</tbody>										
									</table>   							                   
                                </div>                                
                            </div>
                        </div>`,
			link: function (scope, element, attr) {
				
				
				scope.dataLoaded = false;
				scope.bookings = [];	
								
				scope.getBookings = function(){
					var promise;
					scope.dataLoaded = false;
					switch(scope.type) {
						case 'uninvoiced':
							promise = DashboardService.getUninvoicedBookings();
							break;
						case 'partiallyInvoiced':
							promise = DashboardService.getPartiallyInvoicedBookings();
							break;
						case 'maintenance':
							promise = DashboardService.getMaintenanceBookings();
							break;
						case 'held':
							promise = DashboardService.getHeldBookings();
							break;
						case 'maintenance-held':
							promise = DashboardService.getMaintenanceHeldBookings();
							break;						
						case 'extension':
							promise = DashboardService.getExtensionBookings();
							break;
						default:
							promise = DashboardService.getUninvoicedBookings();
					} 
					promise.then(function(success){						
						scope.bookings = success.data;							
					}, function(error){
						console.log(error);			
					}).finally(function() {
						scope.dataLoaded = true;
					});
				}
				
				scope.getBookings();
				
				
			}
		};
	})