angular.module('channel-manager')

    .controller('ModalCmDevelopmentViewController', function ($scope, $uibModalInstance, data, prefix, title, permissions, SettingsService,
        DevelopmentsService, ChannelManagerService, UtilBroadcastService, UtilService, $timeout) {
        $scope.title = title;
        $scope.data = data;
        $scope.showSaveSpinner = false;
        $scope.showAlert = false;
        $scope.alertDetails = {};
        $scope.permissions = permissions;
        $scope.channeldata = {};
              
        function getDevelopment() {
            DevelopmentsService.getDevelopment($scope.development_id).then(function (success) {
                var development = success.data;
                var hotelCode = prefix + UtilService.pad(development.id, 2);
                $scope.channeldata.development_id = $scope.development_id;
                $scope.channeldata.hotelCode = hotelCode;
                $scope.channeldata.hotelName = development.name;
                $scope.channeldata.lat = development.lat;
                $scope.channeldata.lng = development.lng;
            }, function (error) {
                console.log(error);
            });
        }

        $scope.$watch('development_id', function (newValue, oldValue) {
            if ((newValue != oldValue) && angular.isDefined(newValue)) getDevelopment();
        });

        function getSettings(){
            SettingsService.postByGroupV2('Channel Manager Settings').then(function (success) {
                var settings = success.data;
 
                $scope.channeldata.contactGivenName = settings.cm_contact_given_name;
                $scope.channeldata.contactSurname = settings.cm_contact_surname;
                $scope.channeldata.contactAddressLine = settings.cm_contact_address_line;
                $scope.channeldata.contactCityName = settings.cm_contact_city_name;
                $scope.channeldata.contactPostalCode = settings.cm_contact_postal_code;
                $scope.channeldata.contactNotificationEmail = settings.cm_notification_email;
                $scope.channeldata.contactPhoneNumber = settings.cm_notification_telephone;
            }, function(error){

            })
        }

        getSettings();

        $scope.saveRecord = function () {           

            $scope.showSaveSpinner = true;

            var promise; var message;	
            if( $scope.data.id==='new' ){					
                $scope.channeldata.type = 'New';		
            	promise = ChannelManagerService.postDevelopment($scope.channeldata);
            	message = 'Record created';			
            } else {
            	return;
            }

            promise.then(function(success){						
            	$scope.addChannel.$setPristine();		
            	UtilBroadcastService.showAlert($scope, 'Success', message, false);	
            	if($scope.data.id==='new') $scope.data.id = success.data;
            }, function(error){
            	console.log(error);
            	UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
            }).finally(function(){
            	UtilBroadcastService.broadcastUpdate();							
            	$timeout(function() {
            		$uibModalInstance.close('close');
            	}, 2000);						
            });							
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

    })