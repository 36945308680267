angular.module('bookings.directives')

	.directive('bookingInvoices', function ($rootScope, InvoicesService, BookingsService,
		$uibModal, ModalFeedback, $timeout, $window, $state) {
		return {
			restrict: 'EAC',
			replace: true,
			scope: {
				booking: '=',
				invoiceStates: '=',
				invoiceCount: '=',
				permissions: '='
			},
			template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<p ng-show="!permissions['invoices.list'] && dataLoaded">Unable to view due to permissions</p>
							<table ng-show="permissions['invoices.list'] && dataLoaded" class="table"  >
								<tr>
									<th>Number</th>		
									<th>CN</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th>Total</th>
									<th>Due Date</th>
									<th>Status</th>									
									<th style="width: 10%" ng-if="permissions['invoices.reports']">Print</th>
									<th style="width: 10%" ng-if="permissions['invoices.show']">Edit</th>
								</tr>
								<tr ng-repeat="item in invoices">
									<td><b>{{item.invoice_number}}</b></td>	
									<td><i ng-class="{'fa fa-check-circle': item.credit_note == '1'}"></i></td>
									<td>{{item.start_date | date:'mediumDate'}}</td>
									<td>{{item.end_date | date:'mediumDate'}}</td>
									<td class="text-right">{{item.total | number:2}}</td>
									<td>{{item.due_date | date:'mediumDate'}}</td>
									<td><span ng-class="{'label-info': item.status_formatted == 'new', 'label-warning': item.status_formatted == 'sent', 'label-success': item.status_formatted == 'paid', 'label-danger': item.status_formatted == 'overdue'}" class="label">{{item.status_formatted | capitalize}}</span></td>
									<td>
										<a ng-if="permissions['invoices.reports']" href="" ng-click="printInvoicePDF('detailed', item)" class="btn btn-primary btn-outline btn-sm">
											<span ng-show="item.showSpinner == 1"><i class="fa fa-cog fa-spin" style="font-size:16px"></i></span> <span ng-show="item.showSpinner != 1"><i class="fa fa-print"></i></span>
										</a>
									</td>							
									<td><a ng-if="permissions['invoices.show']" href="" ng-click="go(item.id)" class="btn btn-primary btn-outline btn-sm"><i class="fa fa-binoculars"></i></a></td>
								</tr>								
							</table>
							<a href="" 
										ng-show="permissions['invoices.create']"
										ui-sref="main.invoice({id: 'add-new', booking_id: booking.id})" 
										class="btn btn-success btn-outline btn-sm pull-right">
											<i class="fa fa-plus-square"></i></a>
							<div class="row">            
								<display-report 
									ng-show="false"
									ng-if="report_data.data!='no'" 
									report-data="report_data"></display-report>
							</div>
						</div>`,
			link: function (scope, element, attr) {

				scope.dataLoaded = false;
				scope.invoices = [];
				scope.report_data = { 'data': 'no' };

				function modalMissingInfoAlert(msg) {
					var modalInstance = $uibModal.open(ModalFeedback.showFeedback(msg.title, msg.description, 'alert-default', false));
				}

				function getInvoices() {
					BookingsService.getInvoicesByBooking(scope.booking.id).then(function (success) {
						scope.invoices = success.data;
						if (scope.invoices.length === 0 && !scope.isNew) {
							var msg = {}; msg.title = 'No Invoices'; msg.description = 'Warning: No invoices found with this booking.';
							modalMissingInfoAlert(msg);
						}
						scope.invoiceCount = scope.invoices.length;
						scope.dataLoaded = true;
					}, function (error) {
						console.log(error);
					});
				}

				getInvoices();

				function broadcastUpdate() {
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}

				scope.$on('updateSidebars', function (event, result) {
					getInvoices();
				});

				scope.go = function(id){
					$state.go('main.invoice', {'id': id}, {reload: true});
				}


				/**************************************/
				/*			Print Invoices            */
				/**************************************/

				scope.printInvoicePDF = function (type, item) {
					var config = { params: { type: type, credit_note: item.credit_note } };
					item.showSpinner = 1;

					InvoicesService.getStandardInvoiceReport(item.id, config).then(function (url) {
						var printwWindow = $window.open(url);
					}, function (error) {
						console.log(error);
					}).finally(function () {
						item.showSpinner = 0;
					});

				}

				scope.printInvoiceWord = function (type, item) {
					var config = { params: { type: type } };
					var data = {};
					data.type = type;
					data.credit_note = item.credit_note;
					item.showSpinner = 1;

					InvoicesService.getStandardInvoiceReportForWord(item.id, data, config).then(function (success) {
						var download = 'results/' + item.invoice_number + '.docx';
						var hiddenElement = document.createElement('a');

						hiddenElement.href = download;
						hiddenElement.target = '_blank';
						hiddenElement.download = item.invoice_number + '.docx';
						document.body.appendChild(hiddenElement);
						hiddenElement.click();
						$timeout(function () {
							hiddenElement.remove();
						}, 50);
					}, function (error) {
						console.log(error);
					}).finally(function () {
						item.showSpinner = 0;
					});
				}

			}
		};
	})