angular.module('google-maps.services', [])

.factory("GoogleMapsService", ['$http',
	function($http){
	 
	var googleMapsService = {};  
	var geocodeApi = 'AIzaSyBF9e0KxSlwKNeF5D-8mTWT82TJtNcNzLg';
	
	googleMapsService.getGeocode = function(postcode){ 
				   
		return $http({
			method: 'GET',
			skipAuthorization: true,
			url: 'https://maps.googleapis.com/maps/api/geocode/json?address='+postcode+'&key='+geocodeApi              
		});
	}
	
	return googleMapsService;
}])