angular.module('organisations.controllers', [])

.controller('OrganisationsController', ['$state', 'OrganisationsService', '$scope', '$filter', 'PermissionsService', 
		'organisation_type',
        function($state, OrganisationsService, $scope, $filter, PermissionsService, organisation_type) {
    var self = this;

    self.dataLoaded = false;
	self.report_data = {'data': 'no'};
	self.summarySpinner = false;		
    self.organisations = [];
    self.organisations.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;    
	self.canCreate=false;
    self.title = organisation_type.title;
	
	var config = {
		params: { 	
			orderby: 'organisations.organisation_name',
			findby: 'organisations.type',
			findbyvalue: organisation_type.type,
			page: self.organisations.current_page, 				
			per_page: 20
		}		
		
	};
	    
	PermissionsService.getByModuleByUser('organisations').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   

	
	function getOrganisations(){
		self.dataLoaded = false;
		OrganisationsService.getOrganisationsPaginate(config).then(function(success){
			self.organisations = success.data;			
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
    
	getOrganisations();

    self.go = function(id){
        $state.go(organisation_type.state, {'id': id}, {});
    }
	
	self.goToNew = function(id){
        $state.go(organisation_type.state, {'id': 'add-new'}, {});
    }

	self.pagination = function(){			
		config.params.page = self.organisations.current_page;
		getOrganisations();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getOrganisations();
	   
	}
	
	self.search = function(){
		self.organisations.current_page = 1;
		config.params.page = self.organisations.current_page;
		config.params.search = self.search_value;
		getOrganisations();
	}		
	
	self.printSummary = function(){
		self.summarySpinner = true;
		OrganisationsService.getOrganisationSummaryReport().then(function(success) {
			self.report_data= {
				"template": { "shortid" : "416GTCQje" },
				"title": "A Title",
				"data": success.data		  
			};           
			
		}, function(error){
			console.log(error);
		}).finally(function(){
			setTimeout(function(){
				self.summarySpinner = false;
			}, 2000);
			
		});
			
		   
	};
		
	self.print = function(item) {
		item.detailSpinner = true;
		OrganisationsService.getOrganisationDetailedReport(item.id).then(function(success) {
				self.report_data= {
				"template": { "shortid" : "EyS_afKjx" },
				"title": 'Client Report',
				"data": success.data
			};         
			
		}, function(error){
			console.log(error);
		}).finally(function(){
			item.detailSpinner = false;
		});		  
	}
}])