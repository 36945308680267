angular.module('invoices.controllers')

.controller('InvoiceStandardReportController', ['InvoicesService', '$timeout',
    function(InvoicesService, $timeout) {
        var self = this; 
		
		self.dataLoaded = false;
		self.report_data = {'data': 'no'};
		self.invoices = [];
		self.invoice={};
		self.display_invoice = {};
		
		var config = {};
		config.params = {dropdown: true, orderby: 'invoices.invoice_number', sort: 'Desc'};
		
		InvoicesService.getInvoices(config).then(function(success){
            self.invoices = success.data;			
			self.dataLoaded = true;
        }, function(error){
            console.log(error);
        });
		
		self.show = function(){				
			var config = {	params: { 	type: 'detailed' } };
			InvoicesService.getStandardInvoiceReport(self.invoice.data.id, config).then(function(success){	
                self.display_invoice = success.data;
            }, function(error){
                console.log(error);
            });
		}
		
				
		self.print = function(type){
			var config = {	params: { 	type:type } };
			self.showSpinner = 1;
			if(self.invoice.data.credit_note == 0){	
				InvoicesService.getStandardInvoiceReport(self.invoice.data.id, config).then(function(success){							
					self.report_data= {				
						"template": { "shortid" : '4ywwJ9P6e'  },
						"title": "Invoice Detailed",
						"data": success.data
					};
				}, function(error){
					console.log(error);
				}).finally(function() {
					self.showSpinner = 0;
				});
			} else {			
				InvoicesService.getStandardInvoiceReport(self.invoice.data.id, config).then(function(success){		
					self.report_data= {
						"template": { "shortid" : '41KeObuXW' },						
						"title": "Invoice Detailed",
						"data": success.data
					};
				}, function(error){
					console.log(error);
				}).finally(function() {
					self.showSpinner = 0;
				});
			}
		}
	
		self.printWord = function(type){
			var config = {	params: { 	type:type } };
			var data = {};
			data.type = type;			
			data.credit_note = self.invoice.credit_note;
			self.showSpinner = 1;
			
			InvoicesService.getStandardInvoiceReportForWord(self.invoice.data.id, data, config).then(function(success){	
				
				var download = 'results/'+self.invoice.data.invoice_number+'.docx';			
				var hiddenElement = document.createElement('a');

				hiddenElement.href = download;
				hiddenElement.target = '_blank';
				hiddenElement.download = self.invoice.data.invoice_number+'.docx';
				document.body.appendChild(hiddenElement);
				hiddenElement.click();
				$timeout(function(){
					hiddenElement.remove();
				}, 50);
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.showSpinner = 0;
			});
		}		
	}
])