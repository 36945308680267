angular.module('apartments.modals')

.factory("ModalApartmentIssue", [function(){
	 
	var modalApartmentIssue = {};          
	
	
	modalApartmentIssue.show = function(data, permissions, issue_types, issue_states){ 
		return {
                template: 
				`<form name="issueForm" novalidate>
					<div class="modal-header">
						<div class="col-sm-9">
							<h3 class="modal-title">Issues</h3>
						</div>
						<div class="col-sm-3">
							<a ng-show="data.id != 'new'" 
								ng-click="goToIssue()"
								class="btn btn-primary pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i> Go To Issue</a>
						</div>
					</div>
					<div class="modal-body default">  	
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group" >
									<label>Apartment</label>                
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="address1"
										ng-model="data.address1" readonly/>				
								</div>					
							</div>
							<div class="col-sm-6">
								<user-dropdown ng-model="data.assigned_to" form-name="issueForm" is-new="true" title="Assigned To *"
									is-required="true">
								</user-dropdown>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group"  ng-class="{'has-error': issueForm.issue.$invalid && issueForm.issue.$touched}">
									<label>Issue *</label>                
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="issue"
										ng-model="data.issue" ng-readonly="data.id != 'new'" required/>		
									<p ng-show="issueForm.issue.$invalid && issueForm.issue.$touched" class="help-block">
										Required
									</p>
								</div>		
							</div>
						
							<div class="col-sm-6">

								<div class="form-group"  ng-class="{'has-error': issueForm.description.$invalid && issueForm.description.$touched}">
									<label>Description</label>                
									<textarea ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="description" ng-readonly="data.id != 'new'"
										ng-model="data.description" /></textarea>			
									<p ng-show="issueForm.description.$invalid && issueForm.description.$touched" class="help-block">
										Required
									</p>
								</div>				
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group" ng-class="{'has-error': issueForm.type.$invalid && issueForm.type.$touched}">
									<label>Type</label>
									<input ng-show="data.id != 'new'" ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="type"
										value="{{data.type | capitalize}}" readonly/>
									<select ng-show="data.id == 'new'" class="form-control" required name="type" 
										ng-change="updateDueBy()"
										ng-model="data.type">
										<option value="{{type}}" ng-selected="data.type===type" ng-readonly="data.id != 'new'"
												ng-repeat="type in issue_types">{{type | capitalize}}</option>                    
									</select>
									<p ng-show="issueForm.type.$invalid && issueForm.type.$touched" class="help-block">
										Required
									</p>					
								</div>   
								
							</div>
						
							<div class="col-sm-6">
								<label class="form-group dropdown-wide" ng-class="{'has-error': issueForm.related_to.$invalid && issueForm.related_to.$touched}">
									<label>Related To *</label>
									<select class="form-control" required name="related_to" ng-model="data.related_to">
										<option value="{{item}}" ng-selected="data.related_to===item" ng-repeat="item in issue_related_to">{{item
											| capitalize}}</option>
									</select>
									<p ng-show="issueForm.related_to.$invalid && issueForm.related_to.$touched" class="help-block">
										Required
									</p>
								</label>
								
							</div>		
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group" ng-class="{'has-error': issueForm.status.$invalid && issueForm.status.$touched}">
									<label>Status *</label>
									<select class="form-control" required name="status"  ng-model="data.status" ng-disabled="data.id != 'new'">
										<option value="{{status}}" ng-selected="data.status===status"
												ng-repeat="status in issue_states">{{status | capitalize}}</option>                    
									</select>
									<p ng-show="issueForm.status.$invalid && issueForm.status.$touched" class="help-block">
										Required
									</p>					
								</div>   								
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Due By *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="due_by"
										ng-model="data.due_by" readonly required/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group" ng-if="data.id != 'new'">
									<label>Date Completed </label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="date_completed"
										ng-model="data.date_completed" readonly required/>
								</div>
							</div>
							<div class="col-sm-6">
							
							</div>
						</div>

						<hr ng-show="data.id != 'new'">
						<div class="row show-slide" ng-show="data.id != 'new'">
							<div class="col-sm-12">
								<table class="table table-striped">
									<thead>
										<tr>							
											<th>User</th>
											<th>Notes</th>
											<th>Date</th>								
										</tr>
									</thead>
									<tbody>
										<tr ng-repeat="item in data.issue_details_extended">							
											<td>{{item.name}}</td>
											<td>{{item.notes}}</td>
											<td>{{item.created_at | fullDateFormatted}}</td>								
										</tr>
										<tr>
											<td colspan="2">
												<textarea style="width: 100%" 
												placeholder="Add Note"
												ng-model="issue_details.notes">
												
												</textarea>
											</td>
											<td>
												<button type="button" 
													class="btn btn-success" 
													ng-disabled="issue_details.notes == null"
													ng-click="saveDetail()">
													<span ng-show="showIssueDetailSpinner === 1">
														<i class="fa fa-cog fa-spin" style="font-size:16px"></i>
													</span>
													<span ng-show="showIssueDetailSpinner === 0">
														<i class="fa fa-plus" style="font-size:16px"></i>
													</span>
												</button>
											
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="saveRecord()"
										show-spinner="showSaveSpinner"												
										ng-disabled="issueForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,   
				windowClass: 'app-modal-window',
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Apartment Issues"
					},
					permissions: function(){
						return permissions;
					},
					issue_types: function(){							
						return issue_types;
					},
					issue_states: function(){
						return issue_states;
					}
				},
				controller: function($scope, $uibModalInstance, data, title, permissions, $state, SettingsService,
								IssuesService, UtilBroadcastService, issue_types, issue_states, $timeout) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.issue_types = issue_types;
					$scope.issue_states = issue_states;
					$scope.permissions = permissions;	
					$scope.showIssueSpinner = 0;
					$scope.showIssueDetailSpinner = 0;
					$scope.issue_response_times = [];		
					$scope.issue_related_to = IssuesService.getRelatedToForIssues();

					if( $scope.data.id == 'new' ){ 		
						$scope.data.focus = 'apartment';				
						$scope.data.status = 'new';						
					}  

					function getIssueTimeResponses(){
						SettingsService.postByGroup('Issue Response Times')
							.then(function(success){
								$scope.issue_response_times = success.data;		
							}, function(error){
								console.log(error);
							});
					}
					
					getIssueTimeResponses();
				
					$scope.updateDueBy = function(){						
						$scope.data.due_by = IssuesService.getDueByDate($scope.issue_response_times, $scope.data.type);
					}		
										
				
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = IssuesService.postStore($scope.data);
							message = 'Record created';			
						} else {
							promise = IssuesService.postUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.issueForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();											
						});							
					}
					
					$scope.saveDetail = function(){
						$scope.showIssueDetailSpinner = 1;
						$scope.issue_details.issue_id = $scope.data.id;
						IssuesService.postDetailStore($scope.issue_details).then(function(success){
							return IssuesService.getDetailByIssue($scope.data.id);
						}).then(function(success1){	
							$scope.data.issue_details_extended = success1.data;
							$scope.issue_details.notes = '';
						}, function(error){
							console.log(error);
						}).finally(function(){
							$scope.showIssueDetailSpinner = 0;
						});
					}
					
					$scope.goToIssue = function(){
						$state.go('main.issue', {id: data.id});
						$uibModalInstance.close();
					}

					$scope.cancel = function() {
						$uibModalInstance.close('success');
					}

					function getIssue(id){
						IssuesService.getShow(id).then(function(success){                
							$scope.data = success.data;															
						}, function(error){
							console.log(error);
						});
					}		

					$scope.$watch('data.id', function (newValue, oldValue) {
						if(newValue != oldValue){
							if(newValue != 'new'){
								getIssue(newValue)
							}
						}						
					}, true);
				}
            };
	}	
	
			
	return modalApartmentIssue;
}])