angular.module('dashboard.directives')

	.directive('dashboardApartmentsOverdueContracts', function (DashboardService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@'				
			},      
			template:	`<div class="panel panel-default">
                            <div class="panel-heading clearfix">
                                <span class="panel-title pull-left"><i class="fa fa-clock-o fa-fw"></i> {{chartTitle}}</span>
								<div class="pull-right">
									<button type="button" class="btn btn-default rounded" 
											ng-click="getApartmentContracts()">
										<i class="fa fa-refresh"></i>
									</button>
								</div>
                            </div>
                            <div class="panel-body" style="height: 300px; overflow-y: scroll;">
                                <div  class="list-group">
									<div ng-show="!dataLoaded" class="text-center">
										<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
									</div>
									<table ng-show="dataLoaded" 
										class="table table-striped table-hover clickable"  >
										<thead>
											<tr>
												<th>Apartment</th>											
												<th>Contract Start Date</th>
												<th>Contract End Date</th>												
											</tr>
										</thead>
										<tbody >
											<tr ng-repeat="item in apartment_contracts" 
												class="table-row" 
												ui-sref="main.apartment({ id: '{{item.id}}' })"
												href="">
												<td>{{item.address1}}</td>											
												<td><span class="badge">{{item.start_date | date:'dd-MMM-yyyy'}}</span></td>	
												<td><span class="badge">{{item.end_date | date:'dd-MMM-yyyy'}}</span></td>	
											</tr>											
										</tbody>										
									</table>   							                   
                                </div>                                
                            </div>
                        </div>`,
			link: function (scope, element, attr) {
				
				
				scope.dataLoaded = false;
				scope.apartment_contracts = [];	
								
				scope.getApartmentContracts= function(){
					var promise;
					scope.dataLoaded = false;
					promise = DashboardService.getApartmentsOverdueContracts();
					promise.then(function(success){						
						scope.apartment_contracts = success.data;							
					}, function(error){
						console.log(error);			
					}).finally(function() {
						scope.dataLoaded = true;
					});
				}
				
				scope.getApartmentContracts();
				
				
			}
		};
	})