angular.module('actionslog.services', [])

.factory("ActionsLogService", ['$http',	function($http){
	 
	var actionslogService = {};  
	
	actionslogService.getActionsLogsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/actionslog/paginate', config);
	}

	actionslogService.getActionsLogs = function(){            
		return $http.get('api/actionslog');
	}
	
	actionslogService.getActionsLog = function(id){            
		return $http.get('api/actionslog/show/'+id);
	}

	actionslogService.getLogsByObject = function(object, id){       
		var data = {};
		data.table = object;
		data.id = id;
		return $http.post('api/log/by-object', data);
	}
	  
	return actionslogService;
}])