angular.module('invoices.controllers', [])

.controller('InvoicesController', ['$state', 'InvoicesService', 'PermissionsService', '$timeout', '$window',
        function($state, InvoicesService, PermissionsService, $timeout, $window) {
    var self = this;

    self.dataLoaded = false;
    self.invoices = [];
    self.bookings = [];
    self.invoices.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
    self.permissions = [];
	self.report_data = {'data': 'no'};
	
	var config = {
		params: { 	
			page:self.invoices.current_page, 	
			orderby: 'invoices.id',
			sort: 'DESC',
			per_page:30
		}				 
	};

    PermissionsService.getByModuleByUser('invoices').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});  
	
	function getInvoices(){
		self.dataLoaded = false;
		InvoicesService.getInvoicesPaginate(config).then(function(success){			
			self.invoices = success.data;			
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
	
	getInvoices();
    

    self.go = function(id){
        $state.go('main.invoice', {'id': id}, {});
    }
	
	self.pagination = function(){			
		config.params.page = self.invoices.current_page;
		getInvoices();	   
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getInvoices();	   
	}
	
	self.search = function(){
		self.invoices.current_page = 1;
		config.params.page = self.invoices.current_page;
		config.params.search = self.search_value;
		getInvoices();	   
	}
	
	self.print = function(type, item){
		var config = {	params: { 	type:type, credit_note: item.credit_note } };
		item.showSpinner = 1;

		InvoicesService.getStandardInvoiceReport(item.id, config).then(function(url){					
			var printwWindow = $window.open(url);			  
		}, function(error){
			console.log(error);
		}).finally(function() {
			item.showSpinner = 0;
		});		

	}
	
	self.printWord = function(type, item){
		var params = {};
		params.type = type;
		var data = {};
		data.type = type;
		data.credit_note = item.credit_note;
		item.showSpinner = 1;
		
		InvoicesService.getStandardInvoiceReportForWord(item.id, data, params).then(function(url){		
			var hiddenElement = document.createElement('a');	
			hiddenElement.href = url;
			hiddenElement.target = '_blank';
			hiddenElement.download = item.invoice_number +'.docx';
			document.body.appendChild(hiddenElement);
			hiddenElement.click();
			$timeout(function(){
				hiddenElement.remove();
			}, 50);
		}, function(error){
			console.log(error);
		}).finally(function() {
			item.showSpinner = 0;
		});
	}
   
}])