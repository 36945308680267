angular.module('channel-manager')

    .controller('CmDevelopmentController', ['ChannelManagerService', 'PermissionsService', 'data', 'ModalConfigurationService', 'ModalConfirmAction', '$uibModal', '$timeout', 'ModalFeedback', '$state',
        function (ChannelManagerService, PermissionsService, data, ModalConfigurationService, ModalConfirmAction, $uibModal, $timeout, ModalFeedback, $state) {
            var self = this;

            self.dataLoaded = true;
            self.dataLoadedAvailability = true;
            self.permissions = [];
            self.roomtypes = [];
            self.rateplans = [];
            self.reservations = [];
            self.availabilityData = {};
            self.availability = {};
            self.inventory = [];

            self.dataLoadedRoomTypes = false;
            self.dataLoadedRateplans = false;
            self.dataLoadedReservations = false;

            self.datePickerOptions = {
                minDate: moment(), monthColumns: 3, minMode: 'month'
            }


            self.data = data;

            PermissionsService.getByModuleByUser('channel-manager').then(function (success) {
                self.permissions = success.data;
            }, function (error) {
                console.log(error);
            });

            function setAvailabilityDefaults() {
                self.availability.fromDate = moment().format('YYYY-MM-DD');
                self.availabilityData.hotel_id = data.cm_property_id;
                self.availabilityData.rate_id = null;
                self.availabilityData.room_id = null;
                self.availabilityData.from = null;
                self.availabilityData.to = null;
            }

            setAvailabilityDefaults();

            function getRoomTypesByDevelopment() {
                self.dataLoadedRoomTypes = false;
                ChannelManagerService.getRoomTypesByDevelopment(self.data.cm_property_id).then(function (success) {
                    self.roomtypes = success.data;
                }, function (error) {
                    console.log(error);
                }).finally(function () {
                    self.dataLoadedRoomTypes = true;
                });
            }

            getRoomTypesByDevelopment();

            function getRatePlansByDevelopment() {
                self.dataLoadedRateplans = false;
                ChannelManagerService.getRatePlansByDevelopment(self.data.cm_property_id).then(function (success) {
                    self.rateplans = success.data;
                }, function (error) {
                    console.log(error);
                }).finally(function () {
                    self.dataLoadedRateplans = true;
                });
            }

            getRatePlansByDevelopment();

            // function getReservations() {
            //     self.dataLoadedReservations = false;
            //     ChannelManagerService.postReservationList({'hotelId' : self.data.cm_property_id}).then(function (success) {
            //         self.reservations = success.data;
            //     }, function (error) {
            //         console.log(error);
            //     }).finally(function () {
            //         self.dataLoadedReservations = true;
            //     });
            // }

            // getReservations();
            

            self.addNewRoomType = function () {

                var modalInstance = ModalConfigurationService.channelManagerRoomTypeView({ 'hotelcode': data.cm_property_id, 'type': 'Initial', 'development_name': data.name }, self.permissions);

                modalInstance.result.then(function (data) {
                    getRoomTypesByDevelopment();
                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');

            }

            self.editRoomtype= function (item) {

                var editdata = {};

                editdata.hotelcode = data.cm_property_id;
                editdata.type = 'Modify';
                editdata.roomid = item.roomid;
                editdata.roomrate = item.rate*1;
                editdata.roomtype = item.roomtype;
                editdata.maxoccupancy = item.maximumoccupancy*1;
                editdata.sizemeasurement = item.sizemeasurement*1;
                editdata.sizemeasurementunit = 'sqft';
                editdata.text = item.roomname;
                editdata.invCode = item.roomid;

                var modalInstance = ModalConfigurationService.channelManagerRoomTypeView(editdata, self.permissions);

                modalInstance.result.then(function (data) {
                    getRoomTypesByDevelopment();
                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');

            }

            self.addNewRatePlan = function () {

                var modalInstance = ModalConfigurationService.channelManagerRatePlanView({ 'type': 'New', 'hotelCode': data.cm_property_id }, self.permissions);

                modalInstance.result.then(function (data) {
                    getRatePlansByDevelopment();
                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');

            }

            self.editRateplan= function (item) {

                var editdata = {};

                editdata.hotelCode = data.cm_property_id;
                editdata.type = 'Overlay';
                editdata.ratePlanId = item.rateplanid;
                editdata.name = item.rateplanname;

                var modalInstance = ModalConfigurationService.channelManagerRatePlanView(editdata, self.permissions);

                modalInstance.result.then(function (data) {
                    getRatePlansByDevelopment();
                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');

            }


            self.addNewAvailability = function () {
                var roomtypes = self.roomtypes.rooms.room;
               
                var rate_plans = self.rateplans.rateplans.rateplan;
                var initData = {};
                initData.development_name = data.name;
                initData.hotelId = data.cm_property_id;
                initData.closed = '0';
                initData.closedOnArrival = '0';
                initData.closedOnDeparture = '0';

                var modalInstance = ModalConfigurationService.channelManagerAvailabilityView(initData, roomtypes, rate_plans, self.permissions);

                modalInstance.result.then(function (data) {

                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');

            }

            self.getAvailabilityList = function () {
                self.inventory = [];


                if (self.availability.fromDate) {
                    var start = moment(self.availability.fromDate);
                    var now = moment();
                    if (start.isBefore(now)) {
                        start = moment();
                    }
                    self.availabilityData.from = start.format('YYYY-MM-DD');
                    self.availabilityData.to = start.add(30, 'days').format('YYYY-MM-DD');
                }


                if (self.availabilityData.hotel_id === null) return;
                if (self.availabilityData.rate_id === null) return;
                if (self.availabilityData.room_id === null) return;
                if (self.availabilityData.from === null) return;
                if (self.availabilityData.to === null) return;

                self.dataLoadedAvailability = false;


                ChannelManagerService.postAvailabilityList(self.availabilityData).then(function (success) {
                    self.inventory = success.data;
                }, function (error) {
                    console.log(error);
                }).finally(function () {
                    self.dataLoadedAvailability = true;
                });
            }

            self.deleteDevelopment = function(){
                var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
                    'Warning!', 
                    'Are you sure you would like to delete this development from the channel manager? Deletions cannot be undone.', 
                    'alert-danger')
                );
                
                modalInstance.result.then(function () {
                    var deletedata = {};
                    deletedata.hotelCode = data.cm_property_id;
                    deletedata.development_id = data.id;
                  
                    ChannelManagerService.postDevelopmentDelete(deletedata).then(function(success){
                        var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Development Deleted deleted!', 'alert-success', true));						
                        $timeout(function() { 
                            modalInstance1.close('cancel'); 
                            $state.go('main.channel-manager', {}, {reload: true});
                        }, 2000);
                        
                    }, function(error){
                        console.log(error);
                        var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
                                    'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
                        $timeout(function() { modalInstance1.close('cancel'); }, 2000);
                    });
                });
            }
        }])
