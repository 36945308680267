angular.module('google-maps.directives', [])

.directive('googleMap', [function() {		
        return {
      template: '<iframe width="100%" height="350" frameborder="0" style="border:0"></iframe>',
      restrict: 'E',
      scope: {        
		lat: '=',
		lng: '='
      },
      link: function postLink(scope, element) {		 
        var mapFrame = element.find("iframe");       
        mapFrame.attr('src', "https://www.google.com/maps/embed/v1/place?key=AIzaSyBF9e0KxSlwKNeF5D-8mTWT82TJtNcNzLg&q="+scope.lat+","+scope.lng);
         
      }
    };
  
		

}])
