angular.module('login-activity')

.controller('LoginActivityController', ['$scope', 'UtilBroadcastService', 'LoginActivityService', 
            '$stateParams', '$uibModal', '$state', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout',
        function($scope, UtilBroadcastService, LoginActivityService,
                $stateParams, $uibModal, $state, ModalConfirmAction, ModalFeedback, PermissionsService, $timeout) {
    var self = this;    
    
    self.dataLoaded = false;   
    self.login_activity;
    self.showSaveSpinner = false;   
    self.showAlert = false;
    self.alertDetails = {};
    var id = $stateParams.id;   
    self.permissions = [];
    
    PermissionsService.getByModuleByUser('logins').then(function(success){               
        self.permissions = success.data;
    }, function(error){
        console.log(error);
    });   
   
    function getLoginActivity(){
        LoginActivityService.getShow(id).then(function(success){                 
            self.login_activity = success.data;
            self.dataLoaded = true;         
        }, function(error){
            console.log(error);
        }); 
    }
	
	getLoginActivity();  
    
     
}])


