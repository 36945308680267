angular.module('bookings.controllers')

    .controller('BookingController', ['$scope', 'UtilBroadcastService', 'BookingsService', 
			'$stateParams', '$uibModal', '$state', 'ModalConfirmAction', 'ModalFeedback', 'ModalConfigurationService',
			'PermissionsService', '$timeout', '$location', 'ModalGetEmailAddress', 'UtilLookUpService', 'UsersService',
        function($scope, UtilBroadcastService, BookingsService,
			$stateParams, $uibModal, $state, ModalConfirmAction, ModalFeedback, ModalConfigurationService,
			PermissionsService, $timeout, $location, ModalGetEmailAddress, UtilLookUpService, UsersService) {

            var self = this;
            var id = $stateParams.id;
			var config = {	params: {}	};
            self.dataLoaded = false;
			self.showSaveSpinner = false;	
			self.showEmailConfirmationSpinner = false;
			self.showEmailCheckInSpinner = false;
			self.showEmailCheckOutSpinner = false;
			self.showAlert = false;
			self.alertDetails = {};
            self.isNew = (id=='add-new');
            self.permissions = [];
			self.title='Booking';
            self.booking_states = UtilLookUpService.getStatusForBookings();
			self.booking_types = UtilLookUpService.getTypeForBookings();
			self.booking_subtypes = UtilLookUpService.getSubtypesForBookings(); 
			self.booking_payment_terms = UtilLookUpService.getPaymentTermsForBookings();
			self.booking_cancellation_terms = UtilLookUpService.getCancellationTermsForBookings();
			self.booking_deposit_information = UtilLookUpService.getDepositInformationForBookings();
			self.booking_check_in_types = UtilLookUpService.getCheckInTypesForBookings();
			self.apartments = [];
			self.booking = {};
			self.invoices = [];
			self.logs = [];
			self.invoice_count = -1;
			self.sources = UtilLookUpService.getEnquirySources();
			self.location = $location.protocol()+'://'+$location.host();
			self.booking_id = id;
			
			
			PermissionsService.getByModuleByUser('bookings|invoices|channel-manager').then(function(success){   			
				self.permissions = success.data;	
			}, function(error){
				console.log(error);
			});           

			UsersService.getUsers().then(function(success){
				self.users = success.data
			}, function(error){
				console.log(error)
			});
		
            function getBooking(){
                BookingsService.getBooking(id).then(function(success){                         
					self.booking = success.data;                 
					var url = $location.absUrl().split('?')[0];
					if($location.$$host == 'localhost'){
						//convert to integers for dropdowns
						self.booking.contact_id = self.booking.contact_id*1;
						self.booking.organisation_id = self.booking.organisation_id*1;
					}
					
					self.dataLoaded = true;
                }, function(error){
                    console.log(error);
                });
			}
			
			function getUnsentInvoices(){
				BookingsService.getUnsentInvoices(id).then(function(success){
					if(success.data.length > 0) showUnsentInvoices();
				}, function(error){
					console.log(error);
				});
			}

			function showUnsentInvoices(){
				var modalInstance = ModalConfigurationService.unsentInvoicesByBooking(self.permissions, id);
					
				modalInstance.result.then(function (data) {
					
				});
			}

            if( !self.isNew ){ 
				getBooking();
				getUnsentInvoices(); 
            } else {
                self.booking.booking_date = moment().format('YYYY-MM-DD');
                self.booking.fully_invoiced = 'No';
				self.booking.vat_reduction = 'No';
				self.booking.unbalanced_invoice = 'No';
				self.booking.paid = 'No';
				self.booking.number_of_guests = 1;
				self.booking.payment_terms = 'payment_in_advance';
				self.booking.cancellation_terms = '7_nights';
				self.booking.disable_invoice_notification = 'No';
				self.booking.show_booking_start_on_schduler = 1;
				self.booking.show_end_booking_notification = 'Yes';
				self.booking.type = 'booking';
				self.booking.subtype = 'standard';
				self.booking.status = 'active';
				self.booking.check_in_date = null;
				self.booking.check_out_date = null;
				self.booking.check_in_details_type = 'full_service';
				self.booking.person_checking_in = 5;
				self.booking.automatic_invoicing = 'No';
				self.booking.parking_required = 'No';
				self.booking.booking_extras = [];
				self.dataLoaded = true;				
            }
            
                        
            self.updateDuration = function(){					
				if(self.booking.check_out_date != null && self.booking.check_in_date != null){							
					self.booking.duration = BookingsService.getDuration(self.booking.check_in_date, self.booking.check_out_date);					
				}				
			
				checkBookingDates();
			}
	
          
			function checkBookingDates(){
				if(self.booking.check_in_date == false || self.booking.check_out_date == false) return;
				
				if( self.booking.check_in_date > self.booking.check_out_date ){
					$scope.bookingForm.check_out_date.$setValidity("checkOutBeforeCheckIn", false);
					return;
				} else {
					$scope.bookingForm.check_out_date.$setValidity("checkOutBeforeCheckIn", true);					
				}		
			}		

			self.saveBookingExtra = function(){
				self.booking_extras_new.booking_id = id;
				self.booking.booking_extras.push(self.booking_extras_new);
				self.booking_extras_new = {};
				$scope.bookingExtraForm.$setPristine();		
			}

			self.removeBookingExtra = function(item){
				if(self.booking.booking_extras.length > 1){
					self.booking.booking_extras.splice( self.booking.booking_extras.indexOf(item), 1 );
				} else {
					self.booking.booking_extras = [];
				}
			}
			
			self.sendCheckInEmail = function(){
				var modalInstance = $uibModal.open(ModalGetEmailAddress.show(id, self.permissions, 'Check-in Email'));

				modalInstance.result.then(function (email_address) {
					var mailConfig = {	params: { }	};
					mailConfig.params.email_address = email_address;
					self.showEmailCheckInSpinner = true;
		
					BookingsService.getSendCheckInEmail(id, mailConfig).then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));						
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
									'Unable to send email. Please ensure that the associated apartment has a check-in attachment.', 'alert-danger'));			
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}).finally(function(){
						self.showEmailCheckInSpinner = false;
					});
				});
			}

			self.sendCheckOutEmail = function(){
				var modalInstance = $uibModal.open(ModalGetEmailAddress.show(id, self.permissions, 'Check-out Email'));

				modalInstance.result.then(function (email_address) {
					var mailConfig = {	params: { }	};
					mailConfig.params.email_address = email_address;
					self.showEmailCheckOutSpinner = true;
		
					BookingsService.getSendCheckOutEmail(id, mailConfig).then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));						
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
									'Unable to send email. Please ensure that the associated apartment has a check-in attachment.', 'alert-danger'));			
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}).finally(function(){
						self.showEmailCheckOutSpinner = false;
					});
				});
			}

			self.sendConfirmationEmail = function(){

				var modalInstance = $uibModal.open(ModalGetEmailAddress.show(id, self.permissions, 'Booking Confirmation'));
 
				modalInstance.result.then(function (email_address) {
					var mailConfig = {	params: { }	};
					mailConfig.params.email_address = email_address;
					self.showEmailConfirmationSpinner = true;
		
					BookingsService.getSendEmailConfirmation(id, mailConfig).then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));						
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}, function(error){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Unable to send email!',
									error.data, 'alert-danger'));			
						$timeout(function() { modalInstance1.close('cancel'); }, 5000);
					}).finally(function(){
						self.showEmailConfirmationSpinner = false;
					});
				});
			}

		
			self.saveRecord = function(){    
				self.showSaveSpinner = true;   
				var promise; var message;	
				if( self.isNew ){
					self.showSaveSpinner = true; 
					promise = BookingsService.postStore(self.booking);
					message = 'Record created';			
				} else {
					promise = BookingsService.postUpdate(id, self.booking);
					message = 'Record updated';			
				}
				
				promise.then(function(success){
					var newId=false;
					if(self.isNew) newId = success.data;
					$scope.bookingForm.$setPristine();		
					UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.booking');								   
				}, function(error){
					console.log(error);
					UtilBroadcastService.showAlert(self, 'Failure', 'Unable to create/update record.');
				}).finally(function(){
					UtilBroadcastService.broadcastUpdate();
				});		
            }

            self.deleteRecord = function() {
                var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!', 
					'Are you sure you would like to delete this record? Deletions cannot be undone.', 
					'alert-danger')
				);

                modalInstance.result.then(function (msg) {
                    BookingsService.getDelete(id).then(function (success) {
                        var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
						$timeout(function() { 
							modalInstance1.close('cancel'); 
							$state.go('main.bookings', {}, {reload: true});
						}, 2000);
						
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
									'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					});
                });
			}
			
			function getAccountingInformation(){				
                BookingsService.getAccountingInformation(self.booking.organisation_id).then(function(success){     	
					self.booking.commission = success.data.commission;
					self.booking.deposit_information = success.data.deposit_information;
					self.booking.payment_terms = success.data.payment_terms;
					self.booking.cancellation_terms = success.data.cancellation_terms;		
					self.booking.booking_confirmation_email_address = success.data.sales_email_address;
					self.booking.accounting_email_address = success.data.accounting_email_address;			
                }, function(error){
                    console.log(error);
                });
            }

			$scope.$watch(function () {
				return self.booking.organisation_id;
			},function(newValue, oldValue){
				if( self.isNew ){
					if( (newValue != oldValue) && angular.isDefined(newValue)) 
						getAccountingInformation();
				} else {
					if( (newValue != oldValue) && angular.isDefined(oldValue) && angular.isDefined(newValue)) 
						getAccountingInformation();
				}
			});			

			$scope.$watch(function () {
				return self.booking.type;
			},function(newValue, oldValue){
				if( (newValue != oldValue) && angular.isDefined(newValue)) 
				{
					self.booking.subtype = 'standard';
				}		
			});		

			$scope.$on('updateRecord', function (event, result) {					
				getBooking();
			});
			
}])