angular.module('modals.services')

.factory("ModalGetEmailAddress", [function(){
	 
	var modalInvoiceEmail = {};          
	
	
	modalInvoiceEmail.show = function(booking_id, permissions, title){ 
		return {
                template: 
				`<div class="modal-header">
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="modal-body alert">    
					<div class="row">
						<div class="col-lg-3"></div>
						<div class="col-lg-9">
							<div class="btn-group btn-group-justified pull-right">	
								<div class="btn-group" role="group">
									<button  type="button" class="btn btn-warning" ng-click="getEmailAddress('confirmation')">
											<span ng-show="showConfirmationEmailSpinner === true"><i class="fa fa-cog fa-spin" style="font-size:16px"></i></span>
											<span ng-show="showConfirmationEmailSpinner === false">Confirmation</span>
									</button>
								</div>     
								<div class="btn-group" role="group">
									<button  type="button" class="btn btn-info" ng-click="getEmailAddress('accounting')">
											<span ng-show="showAccountingEmailSpinner === true"><i class="fa fa-cog fa-spin" style="font-size:16px"></i></span>
											<span ng-show="showAccountingEmailSpinner === false">Accounting</span>
									</button>
								</div>                    
								<div class="btn-group" role="group">
									<button  type="button" class="btn btn-success" ng-click="getEmailAddress('agent')">
											<span ng-show="showAgentEmailSpinner === true"><i class="fa fa-cog fa-spin" style="font-size:16px"></i></span>
											<span ng-show="showAgentEmailSpinner === false">Agent</span>
									</button>
								</div>
								<div class="btn-group" role="group">
									<button " type="button" class="btn btn-primary" ng-click="getEmailAddress('guest')">
											<span ng-show="showGuestEmailSpinner === true"><i class="fa fa-cog fa-spin" style="font-size:16px"></i></span>
											<span ng-show="showGuestEmailSpinner === false">Guest</span>
									</button>
								</div>
														
							</div>
						</div>
						

					</div>
					<form name="emailForm">			
						
						<div class="form-group" ng-class="{'has-error': emailForm.email_address.$invalid && emailForm.email_address.$touched}">       
							<label>Email Address</label>
							<input ng-focus="focus=false" 								
									ng-blur="focus=true" 				
									type="text" class="form-control" name="email_address"
									ng-model="email_address" required/>  
							<p ng-show="emailForm.email_address.$invalid && emailForm.email_address.$touched" class="help-block">
											Required
							</p>
						</div>					
					</form>
							
				</div>
				<div class="modal-footer">
					<div class="row">					
						<div class="col-lg-3"><button class="btn btn-warning btn-block btn-outline" ng-disabled="emailForm.$invalid"
							type="button" ng-click="useEmail()">Use Email</button></div>
						<div class="col-lg-6"></div>
						<div class="col-lg-3"><button class="btn btn-warning btn-block btn-outline" 
							type="button" ng-click="cancel()">Close</button></div>
					</div>				
				</div>
                `,
				animation: true,      
				resolve: {
					title: function(){
						return title
					},

					booking_id: function(){
						return booking_id;
					},					
					
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, $uibModalInstance, booking_id, title, permissions, 
							BookingsService, UtilBroadcastService, UtilService, $timeout) {
					
					$scope.title = title;
					$scope.booking_id = booking_id;
									
					$scope.showSaveSpinner = false;	
					$scope.showAgentEmailSpinner = false;
					$scope.showGuestEmailSpinner = false;		
					$scope.showAccountingEmailSpinner = false;
					$scope.showConfirmationEmailSpinner = false;		
					$scope.showAlert = false;
					$scope.alertDetails = {};
					
					$scope.permissions = permissions;	

					$scope.getEmailAddress = function(type){

						if(type=='agent') $scope.showAgentEmailSpinner = true;
						if(type=='guest') $scope.showGuestEmailSpinner = true;
						if(type=='accounting') $scope.showAccountingEmailSpinner = true;
						if(type=='confirmation') $scope.showConfirmationEmailSpinner = true;

						var config = {params: {}};
						config.params.type = type;
						
						BookingsService.getRelatedEmailAddress($scope.booking_id, config)
							.then(function(success){
								$scope.email_address = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to retrieve email address.');
						}).finally(function(){
							$scope.showAgentEmailSpinner = false;
							$scope.showGuestEmailSpinner = false;
							$scope.showAccountingEmailSpinner = false;
							$scope.showConfirmationEmailSpinner = false;
						});
					}			
				
				
					$scope.useEmail = function () {	
						$uibModalInstance.close($scope.email_address);		
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}
				}
            };
	}	
	
			
	return modalInvoiceEmail;
}])
