angular.module('apartments.services', [])

.factory("ApartmentsService", ['$http',
	function($http){
	 
	var apartmentsService = {};          
	
	apartmentsService.getApartments = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/apartment', config);
	}
	
	apartmentsService.getApartmentsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/apartment/paginate', config);
	}
	
	apartmentsService.getApartment = function(id){            
		return $http.get('api/apartment/show/'+id);			
	}
	
	apartmentsService.postUpdate = function(id, apartment){            
		return $http.post('api/apartment/update/'+id, apartment);
	}
	
	apartmentsService.postStore = function(apartment){            
		return $http.post('api/apartment/store', apartment);
	}     
	
	apartmentsService.getDelete = function(id){            
		return $http.get('api/apartment/destroy/'+id);
	}     
	
	apartmentsService.getStatusForApartments = function(){            
		return ['active', 'inactive'];
	}

	apartmentsService.getTypesForApartments = function(){            
		return ['Apartment', 'Studio'];
	}

	apartmentsService.getBookingsByApartment = function(id){            
		return $http.get('api/booking/by-apartment/'+id);
	}
	
	
	/*
	 *              Apartment Details
	 */
	
	apartmentsService.getApartmentDetailByApartment = function(id){            
		return $http.get('api/apartment-details/by-apartment/'+id);
	}
	
	apartmentsService.postApartmentDetailUpdate = function(id, data){            
		return $http.post('api/apartment-details/update/'+id, data);
	}
	
	apartmentsService.postApartmentDetailStore = function(data){            
		return $http.post('api/apartment-details/store', data);
	}
	
	apartmentsService.getApartmentDetailDelete = function(id){            
		return $http.get('api/apartment-details/destroy/'+id);
	}
	
	apartmentsService.getAddApartmentDetailsDefaults = function(id){            
		return $http.get('api/apartment-details-defaults/add-all-defaults-to-single-apartment/'+id);
	}
	
	/*
	 *              Apartment Charges
	 */
	
	apartmentsService.getApartmentChargeByApartment = function(id){            
		return $http.get('api/apartment-charges/by-apartment/'+id);
	}
	
	apartmentsService.postApartmentChargeUpdate = function(id, data){            
		return $http.post('api/apartment-charges/update/'+id, data);
	}
	
	apartmentsService.postApartmentChargeStore = function(data){            
		return $http.post('api/apartment-charges/store', data);
	}
	
	apartmentsService.getApartmentChargeDelete = function(id){            
		return $http.get('api/apartment-charges/destroy/'+id);
	}
	
	/*
	 *              Apartment Contracts
	 */
	
	apartmentsService.getApartmentContractByApartment = function(id){            
		return $http.get('api/apartment-contracts/by-apartment/'+id);
	}
	
	apartmentsService.postApartmentContractUpdate = function(id, data){            
		return $http.post('api/apartment-contracts/update/'+id, data);
	}
	
	apartmentsService.postApartmentContractStore = function(data){            
		return $http.post('api/apartment-contracts/store', data);
	}
	
	apartmentsService.getApartmentContractDelete = function(id){            
		return $http.get('api/apartment-contracts/destroy/'+id);
	}

	/*
	 *              Apartment Hand Over
	 */
	
	apartmentsService.getApartmentHandOverByApartment = function(id){            
        return $http.get('api/apartment-hand-over/by-apartment/'+id);
    }
    
    apartmentsService.postApartmentHandOverUpdate = function(id, data){            
        return $http.post('api/apartment-hand-over/update/'+id, data);
    }
    
    apartmentsService.postApartmentHandOverStore = function(data){            
        return $http.post('api/apartment-hand-over/store', data);
    }
    
    apartmentsService.getApartmentHandOverDelete = function(id){            
        return $http.get('api/apartment-hand-over/destroy/'+id);
	}
	
	/*
     *             Apartment Attachment
     */

	apartmentsService.getAttachmentTypes = function(){            
		return ['check_in_email_limited_service', 'check_in_email_full_service', 'check_out_email', 'marketing_attachment', 'other'];
	}
    
    apartmentsService.getApartmentAttachmentByApartment = function(id){            
        return $http.get('api/apartment-attachments/by-apartment/'+id);
    }
    
    apartmentsService.postApartmentAttachmentUpdate = function(id, data){            
        return $http.post('api/apartment-attachments/update/'+id, data);
    }
    
    apartmentsService.postApartmentAttachmentStore = function(data){            
        return $http.post('api/apartment-attachments/store', data);
    }
    
    apartmentsService.getApartmentAttachmentDelete = function(id){            
        return $http.get('api/apartment-attachments/destroy/'+id);
    }
    



   
		  
	/* Reports */
	
	apartmentsService.getApartmentDetailsReport = function(id){            
		return $http.get('api/apartment/apartment-details/'+id);
	}
			
	return apartmentsService;
}])

  



