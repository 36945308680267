angular.module('organisations.services', [])

.factory("OrganisationsService", ['$http',
	function($http){
	 
	var organisationsService = {};                  
	
	organisationsService.getOrganisations = function(config){
		return $http.get('api/organisation', config == null ? [] : config);
	}
	
	organisationsService.getOrganisationsPaginate = function(config){
		return $http.get('api/organisation/paginate', config == null ? [] : config);
	}
	
	organisationsService.getOrganisation = function(id){            
		return $http.get('api/organisation/show/'+id);
	}
	
	organisationsService.postUpdate = function(id, organisation){ 		
		return $http.post('api/organisation/update/'+id, organisation);
	}
	
	organisationsService.postStore = function(organisation){            
		return $http.post('api/organisation/store', organisation);
	}     
	
	organisationsService.getDelete = function(id){            
		return $http.get('api/organisation/destroy/'+id);
	}
	
	
	/*Contact Organisations*/

	organisationsService.getContactsByOrganisation = function(organisation_id){            
		return $http.get('api/contact-organisation/by-organisation/'+organisation_id);
	}
	
	organisationsService.postContactOrganisationUpdate = function(id, contactOrganisation){ 		
		return $http.post('api/contact-organisation/update/'+id, contactOrganisation);
	}
	
	organisationsService.postContactOrganisationStore = function(contactOrganisation){            
		return $http.post('api/contact-organisation/store', contactOrganisation);
	}     
	
	organisationsService.getContactOrganisationDelete = function(id){            
		return $http.get('api/contact-organisation/destroy/'+id);
	}



	/*Reports*/
	
	organisationsService.getOrganisationSummaryReport = function(){            
		return $http.get('api/organisation/summary-report');
	}
	
	organisationsService.getOrganisationDetailedReport = function(id){            
		return $http.get('api/organisation/detailed-report/'+id);
	}
	
	
			
	return organisationsService;
}])