angular.module('apartments.directives')

	.directive('apartmentContracts', function ($rootScope, ApartmentsService, OrganisationsService,
												ModalFeedback, ModalConfirmAction, $uibModal, $timeout, ModalApartmentContract, AttachmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				apartmentId: '=',
				showAlert: '=',				
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>
										<th>Type</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Rent</th>      
										<th>Managed By</th>     
										<th>Attachment</th> 
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>	
									</tr>
									<tr ng-repeat="item in apartment_contracts" 
											ng-class="{'success': item.status == 'active', 'danger': item.status == 'ended'}">
										<td>{{item.type | capitalize}}</td>
										<td>{{item.start_date | date:'dd/MM/yyyy' }}</td>
										<td>{{item.end_date | date:'dd/MM/yyyy'}}</td>  
										<td>{{item.rent}}</td>  
										<td><a href="" ui-sref="main.client({ id: '{{item.organisation_id}}' })">
											{{item.organisation_name}}</a></td>  
										<td><a href="" ng-click="openAttachment(item)">{{item.attachment}}</a></td>
										<td><a href="" ng-click="showApartmentContract(item)" 									
											class="btn btn-primary btn-outline btn-sm"><i class="fa fa-binoculars" aria-hidden="true"></i></a></td>
										<td><a href="" ng-click="deleteApartmentContract(item)" 
											ng-show="permissions['apartments.delete']"
											class="btn btn-danger btn-outline btn-sm"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
									</tr>									
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['apartments.create']" 
									click-fn="createApartmentContract()" button-type="add">
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.apartmentId;
				var config={params: {}};
				var apartmentContractData;
				scope.dataLoaded = false;
				scope.apartment_contracts = [];	
				scope.organisations = [];
									
				function updateAlert(value){
					setTimeout(function(){
						scope.$apply(function(){
							scope.showAlert=value;							
						});		
					}, 500);						
				}
							
				function getOrganisations(){
					config.params = {};	
					config = {	params: { 	orderby: 'organisations.organisation_name', dropdown: true	}  };	
					OrganisationsService.getOrganisations(config).then(function(success){     
						scope.organisations = success.data;                
					}, function(error){
						console.log(error);
					});
					
				}
				
				getOrganisations();
	

				function getApartmentContracts(){
					ApartmentsService.getApartmentContractByApartment(id).then(function(success){     
						scope.apartment_contracts = success.data; 						
						updateAlert(scope.apartment_contracts.length==0);
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getApartmentContracts();
				
				function broadcastUpdate(){
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getApartmentContracts();
				});

				scope.openAttachment = function(item){
					var params = {};
					params.disk = 'apartment-contract-attachments';
					params.item_id = item.apartment_id;
					params.file = item.attachment;
					
					AttachmentsService.getAttachment(params).then(function(url){
						var hiddenElement = document.createElement('a');
	
						hiddenElement.href = url;
						hiddenElement.target = '_blank';
						hiddenElement.download = item.attachment;
						document.body.appendChild(hiddenElement);
						hiddenElement.click();
						$timeout(function(){
							hiddenElement.remove();
						}, 50);
					});		
				}
				
				function showContract(){
					var modalInstance = $uibModal.open(ModalApartmentContract.show(apartmentContractData, scope.permissions));
					
					modalInstance.result.then(function (data) {
						getApartmentContracts();     
					});
				}
				
				scope.showApartmentContract = function(item){
					apartmentContractData = item;
					showContract();
					
				}
				
				scope.createApartmentContract = function(){
					apartmentContractData = {id: 'new', apartment_id: id};
					showContract();
				}
				
				
				
				
				scope.deleteApartmentContract = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					
					modalInstance.result.then(function (data) {                  
						ApartmentsService.getApartmentContractDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();	
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						
					});
				}
				
				
				
			
			}
		};
	})