angular.module('enquiries')

.controller('EnquirySummaryReportController', ['EnquiryService', '$timeout', 'REPORT_DETAILS',
	function(EnquiryService, $timeout, REPORT_DETAILS) {
		var self = this;
		self.reportSpinner = false;
		self.report_data = {'data': 'no'};
		self.dataLoaded = false;
		self.dates = {};			
		self.enquiries = {};
		
		self.report_details = REPORT_DETAILS.reportEnquirySummary();
		
		function defaultDates(){
			const startDateOfMonth = moment().format("YYYY-MM-01");
			const endDateOfMonth = moment().format("YYYY-MM-") + moment().daysInMonth();
			self.dates.start_date = startDateOfMonth;
			self.dates.end_date = endDateOfMonth; 
			
			getSummaryReport();		
		}
	
		defaultDates();
		
		function getSummaryReport(){
			self.dataLoaded = false;
			EnquiryService.postSummaryReport(self.dates).then(function(success){
				self.enquiries = success.data;				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			getSummaryReport();
		}
		
		self.print = function(type){			
			self.reportSpinner = true;		
			self.report_data= {
				"template": { "shortid" : 'Byg_IF4ox'  },
				"title": "Enquiry Summary",
				"data": self.enquiries 
			};
			
			$timeout(function() {
				self.report_data = {'data': 'no'};	
				self.reportSpinner = false;					
			}, 3000);
	
		}
		
		
					
	}
])