angular.module('angular-utils.filters')

.filter('filter_apartments', function() {
    return function(apartments, input) {
        if (!input) {
            return apartments;
        }
        var out = [];
        angular.forEach(apartments, function(value, key) {
            
            var canAddBeds = false;
            var canAddIDS = false;
            
            if(typeof input.beds !== "undefined"){                
                if(value.beds==input.beds){                    
                    canAddBeds=true;    
                }
            } else {
                canAddBeds=true; 
            }
            
            if(input.ids !== -1){   
                
                for(var i = 0; i<input.ids.length; i++){
                    if(input.ids[i] === value.id){
                        canAddIDS = true;
                        break;
                    }
                }    
               
                 
            } else {
                canAddIDS =true; 
            }
            
            if(canAddBeds && canAddIDS)out.push(value);
        });
        
        return out;
    }
})