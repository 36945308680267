angular.module('schedulers.services', [])

.factory("SchedulersService", ['$http', 
	
	function($http){
	 
	var schedulersService = {};                  
	
	schedulersService.postShow = function(dates){            
		return $http.post('api/scheduler/show', dates);
	}
	
	schedulersService.postShowNew = function(data){     		
		return $http.post('api/scheduler/show-new', data);
	}
		   
	schedulersService.postGetApartmentsByDates = function(dates){            
		return $http.post('api/scheduler/apartments-available-by-dates', dates);
	}
	
	schedulersService.postApartmentOrder = function(apartments){            
		return $http.post('api/scheduler/apartment-order', apartments);
	}
	
			
	return schedulersService;
}])