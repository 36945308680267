angular.module('dashboard.directives', [])

	.directive('dashboardOccupancyByDate', function (DashboardService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				startDate: '=',	
				endDate: '=',
				chartTitle: '@'
			},      
			template:	`<div class="panel panel-success">
                            <div class="panel-heading clearfix">
                                <span class="panel-title pull-left"><i class="fa fa-long-arrow-right fa-fw"></i> {{chartTitle}}</span>
								<div class="pull-right">
									<button type="button" class="btn btn-default rounded" 
											ng-click="getOccupancy()">
										<i class="fa fa-refresh"></i>
									</button>
								</div>
                            </div>
                            <div class="panel-body text-center" style="min-height: 150px;">
								<div ng-if="!dataLoaded" class="text-center">
									<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
								</div>
								<div ng-if="dataLoaded">
									<ridge-speedometer 
											x-val="occupancy" 
											x-conf="xConf" 
											x-label="{{occupancy}}%" />
								</div>
                            </div>
                        </div>`,
			link: function (scope, element, attr) {				
				scope.dataLoaded = false;
				scope.occupancy = 0;
				scope.xConf = {backgroundColor: 'black',
					size: '250',					
					normalColor: 'red',
					warningColor: 'transparent',
					criticalColor: 'green',
					endOfNormalDegree: 250,
					endOfWarningDegree: 250,
					needleColor: 'yellow'};			
								
				scope.getOccupancy = function(){
					scope.dataLoaded = false;
					var dates = {};
					dates.end_date = scope.endDate;		
					dates.start_date = scope.startDate;
					
					DashboardService.getOccupancyByDate(dates).then(function(success){
						scope.occupancy = success.data;			
					}, function(error){
						console.log(error);			
					}).finally(function() {
						scope.dataLoaded = true;
					});
				}
				
				scope.getOccupancy();
				
				scope.$watch('startDate', function(newValue, oldValue) {			
					if(newValue != oldValue){						
						scope.getOccupancy();		
					}				
				});
				
				scope.$watch('endDate', function(newValue, oldValue) {			
					if(newValue != oldValue){						
						scope.getOccupancy();		
					}				
				});
			}
		};
	})