angular.module('xero', [])

.controller('XeroController', ['XeroService', '$uibModal', 'ModalConfirmAction', 'ModalFeedback', '$timeout', 
        function(XeroService, $uibModal, ModalConfirmAction, ModalFeedback, $timeout) {
	var self = this;
	self.showSpinnerRI = false;
	self.showSpinnerRP = false;

    self.getSyncPayments = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to Sync Xero invoice payments?', 
			'alert-info')
		);

        modalInstance.result.then(function (msg) {
			self.showSpinnerRP  = true;
            XeroService.getSyncPayments().then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Xero Payments Synced!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 					
				}, 2000);				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to sync payments. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			}).finally(function(){
				self.showSpinnerRP = false;
			});
		});
	}

	self.getSyncInvoices = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to Sync Xero invoices?', 
			'alert-info')
		);

        modalInstance.result.then(function (msg) {
			self.showSpinnerRI = true;
            XeroService.getSyncInvoices().then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Xero invoices Synced!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 					
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to sync invoices. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			}).finally(function(){
				self.showSpinnerRI = false;
			});
		});
	}


}])