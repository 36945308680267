angular.module('invoices.controllers')

.controller('InvoiceOverdueReportController', ['InvoicesService', '$scope',  '$window',
    function(InvoicesService, $scope, $window) {
        var self = this; 
		self.dataLoaded = false;
		self.showSpinner = false;		
		self.invoices = [];	
		self.organisation_id = -1;		
		var config = { params: { organisation_id: -1}	};
	
					
		function InvoiceOverdueReport(){
			self.dataLoaded = false;
			config.params.organisation_id =  self.organisation_id;
			InvoicesService.getInvoiceOverdueReport(config).then(function(success){
				self.invoices = success.data;
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}


		InvoiceOverdueReport();

		self.print = function(){
			self.dataLoaded = false;
			config.params.organisation_id =  self.organisation_id;
			InvoicesService.getInvoiceOverdueReportPDF(config).then(function(url){
				var printwWindow = $window.open(url);
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			InvoiceOverdueReport();			
		}				

		$scope.$watch(function(){
			return self.organisation_id;
		}, function(newVal, oldVal){		
			if(newVal != oldVal) InvoiceOverdueReport();
		});
		
	}
])