angular.module('dashboard.services', [])

.factory("DashboardService", ['$http',
	function($http){
	 
	var dashboardService = {};                  
	
	dashboardService.getOccupancyLastYears = function(previous_years){            
		return $http.get('api/dashboard/occupancy-last-years/'+previous_years);
	}
	
	dashboardService.getOccupancyByDate = function(dates){            
		return $http.post('api/dashboard/occupancy-by-dates', dates);
	}
	
	dashboardService.getUninvoicedBookings = function(){            
		return $http.get('api/dashboard/uninvoiced-bookings');
	}    
	
	dashboardService.getPartiallyInvoicedBookings = function(){            
		return $http.get('api/dashboard/partially-invoiced-bookings');
	}   

	dashboardService.getMaintenanceBookings = function(){            
		return $http.get('api/dashboard/maintenance-bookings');
	}   	

	dashboardService.getHeldBookings = function(){            
		return $http.get('api/dashboard/held-bookings');
	}   

	dashboardService.getMaintenanceHeldBookings = function(){            
		return $http.get('api/dashboard/maintenance-held-bookings');
	}   

	dashboardService.getApartmentsOverdueContracts = function(){            
		return $http.get('api/dashboard/apartments-overdue-contracts');
	}   

	dashboardService.getApartmentsOverdueHandOvers = function(){            
		return $http.get('api/dashboard/overdue-apartment-hand-overs');
	}   

	dashboardService.getIncomeByClients = function(object){            
		return $http.post('api/dashboard/income-by-clients', object);
	}	

	dashboardService.getExtensionBookings = function(){            
		return $http.get('api/dashboard/extension-bookings');
	}   
	
	dashboardService.getInvoicesOverdue = function(){            
		return $http.get('api/dashboard/overdue-invoices');
	} 
	
	dashboardService.postBookingFigures = function(object){     
		return $http.post('api/dashboard/booking-figures', object);
	} 

	dashboardService.postInvoiceFigures = function(object){     
		return $http.post('api/dashboard/invoice-figures', object);
	} 

	dashboardService.postClientAverageLengthOfStay = function(){     
		return $http.post('api/dashboard/client-als', []);
	} 

	dashboardService.postAverageLengthOfStay = function(){     
		return $http.post('api/dashboard/als', []);
	} 

	dashboardService.postBookingOccupancy = function(object){     
		return $http.post('api/dashboard/booking-occupancy', object);
	} 

	dashboardService.postUnpaidInvoices = function(object){     
		return $http.post('api/dashboard/unpaid-invoices', object);
	}

	dashboardService.postOverdueInvoices = function(object){     
		return $http.post('api/dashboard/overdue-invoices', object);
	}

	dashboardService.getOverdueTasks = function(){     
		return $http.get('api/dashboard/overdue-tasks');
	}

	dashboardService.getMainHeldBookings = function(){     
		return $http.get('api/dashboard/main-held-bookings');
	}

	dashboardService.getUninvoicedBookingsValue = function(){     
		return $http.get('api/dashboard/uninvoiced-bookings-value');
	}

	dashboardService.getClientConversionRates = function(){     
		return $http.get('api/dashboard/client-conversion-rates');
	}
			
	return dashboardService;
}])