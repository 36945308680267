angular.module('form-controls.directives')

.factory("ModalAddArea", [function(){
	 
	var modalAddArea = {};          
	
	
	modalAddArea.addArea = function(){ 
		return {
                template: 
				`<form name="areaForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Add Area</h3>
					</div>
					<div class="modal-body default">           
                    
						<div class="form-group" ng-class="{'has-error': areaForm.area.$invalid && areaForm.area.$touched}">
							<label>Area</label>
							<input  type="text" class="form-control" name="area" 
								   ng-model="area.area" required />
							<p ng-show="areaForm.area.$invalid && areaForm.area.$touched" class="help-block">Required</p>                   
						</div>
						<div class="form-group" ng-class="{'has-error': areaForm.code.$invalid && areaForm.code.$touched}">
							<label>Code</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="code" 
								   ng-model="area.code" maxlength="7" required />
							<p ng-show="areaForm.code.$invalid && areaForm.code.$touched" class="help-block">
								Required - max 6 characters
							</p>
						</div>


						<div class="form-group" ng-class="{'has-error': areaForm.status.$invalid && areaForm.status.$touched}">
							<label>Status</label>
							<select class="form-control" name="status" ng-model="area.status" required>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>                        
							</select>
							<p ng-show="areaForm.status.$invalid && areaForm.status.$touched" class="help-block">
								Required
							</p>
						</div>
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="save()"
										show-spinner="showSaveSpinner"												
										ng-disabled="areaForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,                  
				controller: function ($uibModalInstance, $scope, AreasService, 
										 UtilBroadcastService, $timeout) {	
					var config = {	params: {}	};
					$scope.areas = [];
					$scope.area = {};
					$scope.showSaveSpinner = false;	
					$scope.showAlert = false;
					$scope.alertDetails = {};		
					
					$scope.area.status = 'active';
											
					$scope.save = function () {						
						$scope.showSaveSpinner = true; 							
						var newId;
						AreasService.postStore($scope.area).then(function(success){							
							newId=success.data;								
							UtilBroadcastService.showAlert($scope, 'Success', 'Record created', false);								   
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							$timeout(function() {
								$uibModalInstance.close(newId);
							}, 2000);
							
						});	
						
						 
					};
					
					$scope.cancel = function() {
					   $uibModalInstance.dismiss();
					}
				}
            };
			
		
	}	
	
			
	return modalAddArea;
}])