angular.module('issues.controllers', [])

.controller('IssuesController', ['$state', 'IssuesService', 
			'$scope', '$filter', 'PermissionsService', '$http', '$timeout',
        function($state, IssuesService, $scope, $filter, PermissionsService, $http, $timeout) {
        var self = this;

        self.dataLoaded = false;
        self.issues = [];    
        self.showSpinner=0;
        self.issues.current_page = 1;        
		self.maxSize = 5;
		self.search_value;
		self.sortASC = true;
        self.permissions = [];
		
		var config = {
			params: { 	
				page:self.issues.current_page, 				
				per_page:30
			}				 
		};

        PermissionsService.getByModuleByUser('issues').then(function(success){   			
			self.permissions = success.data;
		}, function(error){
			console.log(error);
		});   
		
		function getIssues(){
			self.dataLoaded = false;
			IssuesService.getIssuesPaginate(config).then(function(success){
				self.issues = success.data;				
				self.dataLoaded = true;
			}, function(error){
				console.log(error);
			}).finally(function(){
				defaultConfig();
			});
		}
		
		getIssues();
        
        self.getByStatus = function(status){
            config.params.findby = 'issues.status';
			config.params.findbyvalue = status;
			config.params.orderby = 'issues.due_by';
			config.params.sort = 'ASC';
			getIssues();
        }
		
		self.pagination = function(){			
			config.params.page = self.issues.current_page;
			getIssues();
		}
		
		self.sortTable = function(heading){
			config.params.orderby = heading;
			config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
			self.sortASC = !self.sortASC;
			getIssues();
		   
		}
		
		self.search = function(){
			self.issues.current_page = 1;
			config.params.page = self.issues.current_page;
			config.params.search = self.search_value;
			getIssues();
		}
        
       
}])