angular.module('form-controls.directives', [])

	.directive('apartmentDropdown', function (ApartmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				apartmentId: '=ngModel',
				formName: '=',
				availableByDates: '=',
				availableStartDate: '=',
				availableEndDate: '=',
				isDisabled: '=',
				isRequired: '=',
				isNew: '='
			},    
			require: 'ngModel',
			template:	`<div class="form-group" 
							ng-class="{'has-error': formName.apartment_id.$invalid && formName.apartment_id.$touched}">
							<label>Apartment *</label>

							<select class="form-control" name="apartment_id" 
								ng-model="apartmentId" 
								ng-disabled="isDisabled"
								ng-options="option.id as (option.address1 + ', ' + option.address2 + ', ' + option.type + ', ' + option.beds + ' bed') for option in apartments" ng-required="isRequired">
							</select>

							<p ng-show="formName.apartment_id.$invalid && formName.apartment_id.$touched" 
								class="help-block">
								Required
							</p>
						</div>`,
			link: function (scope, element, attr) {				
				var config={};
				scope.apartments;

				function setDefaults(){			
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = false;
					if (angular.isUndefined(scope.isRequired)) scope.isRequired = true;
				}
				
				setDefaults();
							
				function getApartments(){
					config.params = {};
					config = {	params: { orderby:'address1', dropdown: true}};	
					if(scope.availableByDates){
						config.params.available_by_dates = true;
						config.params.available_start_date= scope.availableStartDate;
						config.params.available_end_date = scope.availableEndDate;
						
					}
					
					ApartmentsService.getApartments(config).then(function(success){		
						scope.apartments = success.data;
					}, function(error){
						console.log(error);
					});	
				}
				
				getApartments(); 
				
				scope.$watch('availableStartDate', function(newId, oldId){					
					if(newId != oldId){						
						getApartments();
					}
				});
				
				scope.$watch('availableEndDate', function(newId, oldId){					
					if(newId != oldId){						
						getApartments();
					}
				});
				
			}
		};
	})