angular.module('organisations.controllers')

.controller('OrganisationReportsController', ['OrganisationsService', '$localstorage', '$state',
        function(OrganisationsService, $localstorage, $state) {
    var self = this;

    self.report_data = {'data': 'no'};
    self.organisations = [];

    OrganisationsService.getOrganisations().then(function(success){
        self.organisations = success.data;
    }, function(error){
        console.log(error);
    });

    self.goToSummary = function(){
		
		OrganisationsService.getOrganisationSummaryReport().then(function(success) {
			self.report_data= {
				"template": { "shortid" : "416GTCQje" },
				"title": "A Title",
				"data": success.data	
			  
			};            
			
		}, function(error){
			console.log(error);
		});
			
           
    };

    self.goToDetail = function(organisation) {
		OrganisationsService.getOrganisationDetailedReport(organisation.id).then(function(success) {
				self.report_data= {
				"template": { "shortid" : "EyS_afKjx" },
				"title": organisation.organisation_name,
				"data": success.data
			};         
			
		}, function(error){
			console.log(error);
		});
          
    }
}])