angular.module('developments.directives')

    .directive('developmentAttachments', function ($rootScope, DevelopmentsService, ModalFeedback, 
                ModalConfirmAction, $uibModal, $timeout, AttachmentsService, ModalConfigurationService) {
        return {
            restrict : 'EAC',
            replace : true,
            scope :{
                developmentId: '=',                   
                permissions: '='
            },      
            template:   `<div>
                            <div ng-show="!dataLoaded" class="row text-center">
                                <span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
                            </div>
                            <div ng-show="dataLoaded">
                                <table class="table">                                   
                                    <tr>
                                        <th>Title</th>
                                        <th>Type</th>                                      
                                        <th>Attachment</th>
                                        <th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>   
                                    </tr>
                                    <tr ng-repeat="item in development_attachments">
                                        <td>{{item.title}}</td> 
                                        <td>{{item.type | underscoreless | capitalize}}</td>                                     
                                        <td><a href="" ng-click="openAttachment(item)">{{item.attachment}}</a></td>
                                        <td><my-sub-form-button click-fn="showDevelopmentAttachment(item)" button-type="edit"></my-sub-form-button></td>
                                        <td><my-sub-form-button ng-show="permissions['developments.update']" click-fn="deleteDevelopmentAttachment(item)" button-type="delete"></my-sub-form-button></td>                                
                                    </tr>                                   
                                </table>
                                <div class="pull-right">
                                   <my-sub-form-button ng-show="permissions['developments.create']" 
                                    click-fn="createDevelopmentAttachment()" button-type="add">
                                </div>
                            </div>
                        
                        </div>`,
            link: function (scope, element, attr) {
                
                const id = scope.developmentId;
                var developmentAttachmentData;
                scope.dataLoaded = false;
                scope.development_attachments = [];                                   
                    

                function getDevelopmentAttachments(){                 
                    scope.dataLoaded = false;
                    DevelopmentsService.getDevelopmentAttachmentByDevelopment(id).then(function(success){     
                        scope.development_attachments = success.data;                         
                    }, function(error){
                        console.log(error);
                    }).finally(function(){
                        scope.dataLoaded = true;
                    });
                }
                
                getDevelopmentAttachments();
                
                function broadcastUpdate(){
                    console.log('broadcasting update');
                    $rootScope.$broadcast('updateSidebars', {
                        data: true
                    });
                }
                
                scope.$on('updateSidebars', function (event, result) {                  
                    getDevelopmentAttachments();
                });

                scope.openAttachment = function(item){

                    var params = {};
                    params.disk = 'development-attachments';
                    params.item_id = item.development_id;
                    params.file = item.attachment;
                    
                    AttachmentsService.getAttachment(params).then(function(url){
                        var hiddenElement = document.createElement('a');
    
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = item.attachment;
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        $timeout(function(){
                            hiddenElement.remove();
                        }, 50);
                    });         
                
                }
                
                function showDevelopmentAttachment(){
                    var modalInstance = ModalConfigurationService.getDevelopmentAttachment(developmentAttachmentData, scope.permissions);                  
                    modalInstance.result.then(function (data) {
                        getDevelopmentAttachments();     
                    });
                }
                
                scope.showDevelopmentAttachment = function(item){
                    developmentAttachmentData = item;
                    showDevelopmentAttachment();                  
                }
                
                scope.createDevelopmentAttachment = function(){
                    developmentAttachmentData = {id: 'new', development_id: id};
                    showDevelopmentAttachment();
                }
                
                scope.deleteDevelopmentAttachment = function(item){
                    var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
                        'Warning!', 
                        'Are you sure you would like to delete this record? Deletions cannot be undone.', 
                        'alert-danger')
                    );
                    
                    modalInstance.result.then(function (data) {                     
                        DevelopmentsService.getDevelopmentAttachmentDelete(item.id).then(function(success){                      
                            var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));                     
                            $timeout(function() { modalInstance1.close('cancel'); }, 2000);
                            broadcastUpdate();                              
                        }, function(error){
                            console.log(error);
                            var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
                                        'Unable to delete record. Please contact a system administrator.', 'alert-danger'));            
                            $timeout(function() { modalInstance1.close('cancel'); }, 2000);
                        });                           
                    });
                }
            }
        };
    })


