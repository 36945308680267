angular.module('invoices.controllers')

.controller('InvoiceScheduleReportController', ['InvoicesService', 'SettingsService', '$timeout',
    function(InvoicesService, SettingsService, $timeout) {
        var self = this; 
		var start_month = 1;
		self.dataLoaded = false;
		self.showSpinner = false;
		self.report_data = {'data': 'no'};
		self.dates = {};
		self.invoices = [];
		self.schedule_years = [];
		self.schedule_year = '';
		
		function setDropDown(){
			var now = moment(); 
			for(var i= now.year(); i>2009; i--){				
				var object = {};
				object.year = i;
				object.name = i+'/'+(i+1)
				self.schedule_years.push(object);
			}
				
			
		}
		
		setDropDown();
		
		function defaultDates(){
			var object = {};
			object.setting = 'date_of_incorporation';
			SettingsService.postBySetting(object).then(function(success){
				
				var d = moment(success.data.value);
				start_month = d.month(); 				
				
				var now = moment(); 
				self.dates.end_date = moment().year(now.year()).month(start_month).date(1).format('YYYY-MM-DD');
				self.dates.start_date = moment().year(now.year()-1).month(start_month).date(1).format('YYYY-MM-DD');
				self.schedule_year = now.year()-1;
				
				InvoiceScheduleReport();
			}, function(error){
				console.log(error);
			});
			
			
		}
	
		defaultDates();
		
		function InvoiceScheduleReport(){
			self.dataLoaded = false;
			InvoicesService.postInvoiceScheduleReport(self.dates).then(function(success){
				self.invoices = success.data;
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			InvoiceScheduleReport();
		}
		
		self.changeYear = function(){
					
			self.dates.end_date = moment().year(self.schedule_year+1).month(start_month).date(1).format('YYYY-MM-DD');
			self.dates.start_date = moment().year(self.schedule_year).month(start_month).date(1).format('YYYY-MM-DD');				
			InvoiceScheduleReport();
		}
		
		self.print = function(){
			self.showSpinner = true;
			self.report_data= {
				"template": { "shortid" : "NkSyMbGr-" },
				"title": "Invoice Schedule",
				"data": self.invoices
			};  
			$timeout(function() {
				self.showSpinner = false;				
			}, 10000);
		}
		
		
		
	}
])