angular.module('menus.controllers', [])

.controller('MenuController', ['$state', 'MenuService', 'PermissionsService', '$http', '$uibModal', 'ModalConfirmAction',
    function ($state, MenuService, PermissionsService, $http, $uibModal, ModalConfirmAction) {
            var self = this;
            self.authenticated_user = '';
            self.permissions = [];
            self.header_text = 'Serviced Apartment Management System';
            self.mode = 'production';
            self.showLogMeOutSpinner = false;

            self.openNode = '';

            function getMode() {
                return $http.get('api/mode').then(function (success) {
                    self.mode = success.data;
                    if (self.mode == 'testing') {
                        self.header_text = 'SAMS TESTING MODE';
                    }

                    if (self.mode == 'production') {
                        self.header_text = 'Serviced Apartment Management System';
                    }
                }, function (error) {
                    console.log(error);
                });
            }

            getMode();

            PermissionsService.getByModuleByUser('all').then(function (success) {
                self.permissions = success.data;
            }, function (error) {
                console.log(error);
            });

            MenuService.getAuthenticatedUser().then(function (success) {
                self.authenticated_user = success.data;
            }, function (error) {
                console.log(error);
            });

            self.logMeOutAs = function(){
                console.log('logmeoutas');
                var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
                    'Confirm Action',
                    'Are you sure you would like to log back in as an Admin?',
                    'info-alert'
                ));

                modalInstance.result.then(function(msg){
                    self.showLogMeOutSpinner = true;
                    MenuService.getLogMeOutAs().then(function(success){
                        $state.go('main.dashboard', {}, {reload: true});
                    }, function(error){
                        console.log(error);
                    }).finally(function(){
                        self.showLogMeOutSpinner = false;
                    });
                });
            }

            self.logout1 = function () {
                $state.go('logout', {}, { reload: true });
            }

        }])
