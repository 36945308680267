angular.module('angular-utils.services')

	.factory('UtilLookUpService', [function () {

		var utilLookUpService = {};
		
		utilLookUpService.getStatusForBookings = function(){            
			return ['active', 'cancelled'];
		}
		
		utilLookUpService.getTypeForBookings = function(){            
			return ['booking', 'held', 'maintenance'];
		}   
		
		utilLookUpService.getPaymentTermsForBookings = function(){            
			return ['payment_in_advance', '30_days_from_invoice_date', '60_days_from_invoice_date', '30_days_from_check-in_date', '60_days_from_check-in_date'];
		}   
	
		utilLookUpService.getCancellationTermsForBookings = function(){            
			return ['7_nights', '14_nights', '28_nights', '90_nights'];
		}   
	
		utilLookUpService.getDepositInformationForBookings = function(){            
			return ['0.00', '150.00', 'Other'];
		}   

		utilLookUpService.getApartmentTypeForBookings = function(){            
			return ['Studio', '1_bed', '2_bed', '3_bed'];
		}   

		utilLookUpService.getCheckInTypesForBookings = function(){            
			return ['full_service', 'limited_service'];
		}  
		
		utilLookUpService.getSubtypesForBookings = function(){            
			return ['standard', 'insurance'];
		}  

		utilLookUpService.getEnquirySources = function(){            
			return ['agent', 'airbnb', 'website', 'linked_in', 'social_media', 'other'];
		}
		

		return utilLookUpService;


	}])