angular.module('form-controls.directives')

	.directive('userDropdown', function (UsersService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				userId: '=ngModel',
				formName: '=',				
				isDisabled: '=',
				isRequired: '=',
				isNew: '=',
				title: '@'
			},    
			require: 'ngModel',
			template:	`<div class="form-group" 
							ng-class="{'has-error': formName.user_id.$invalid && formName.user_id.$touched}">
							<label>{{title}}</label>

							<select class="form-control" name="user_id" 
								ng-model="userId" 
								ng-disabled="isDisabled"
								ng-options="option.id as option.name for option in users" ng-required="isRequired">
							</select>

							<p ng-show="formName.user_id.$invalid && formName.user_id.$touched" 
								class="help-block">
								Required
							</p>
						</div>`,
			link: function (scope, element, attr) {				
				var config={};
				scope.users;				

				function setDefaults(){			
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = false;
					if (angular.isUndefined(scope.isRequired)) scope.isRequired = true;
				}
				
				setDefaults();
							
				function getUsers(){
					config.params = {};
					config = {	params: { orderby:'name', dropdown: true, findby: 'status', findbyvalue: 'active'}};						
					
					UsersService.getUsers(config).then(function(success){					
						scope.users = success.data;
					}, function(error){
						console.log(error);
					});	
				}
				
				getUsers(); 				
			
				
			}
		};
	})