angular.module('permissions.services', [])

.factory("PermissionsService", ['$http',
	function($http){
	 
	var permissionsService = {};                  
	
	permissionsService.getPermissions = function(module){            
		return $http.get('api/permission/by-module/'+module);
	}  
	
	permissionsService.postPermissions = function(permissions){            
		return $http.post('api/permission/update', permissions);
	}   
	
	permissionsService.getPermissionsByUser = function(){            
		return $http.get('api/role-permission/permissions-by-user');
	}  
	
	permissionsService.getByModuleByUser = function(module){            
		return $http.get('api/permission/by-module-by-user/'+module);
	}  
	
	permissionsService.getVersion = function(){            
		return $http.get('api/permission/version');
	}  

	permissionsService.canUserNameUpdate = function(type){            
		return $http.get('api/role-permission/has-permission/'+type+'.nameupdate');
	} 
	
	permissionsService.canUserEmailUpdate = function(type){            
		return $http.get('api/role-permission/has-permission/'+type+'.emailupdate');
	} 

	permissionsService.canUserPasswordUpdate = function(type){            
		return $http.get('api/role-permission/has-permission/'+type+'.passwordupdate');
	}         
			
	return permissionsService;
}])