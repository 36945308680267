angular.module('channel-manager')

    .controller('ModalCmApartmentViewController', function ($scope, data, title, permissions, ChannelManagerService, 
        UtilBroadcastService, $timeout, $uibModalInstance, ApartmentsService) {

        $scope.title = title;
        $scope.data = data;
        $scope.showSaveSpinner = false;
        $scope.showAlert = false;
        $scope.alertDetails = {};
        $scope.permissions = permissions;
        $scope.data = data;
        $scope.apartment_id;
        $scope.apartments;

        $scope.types = ['Initial', 'Modify', 'Active', 'Deactivated'];
        $scope.roomTypes = ApartmentsService.getTypesForApartments();

        var config = {};     

        $scope.updateApartment = function(){
            $scope.data.roomid = $scope.apartmentObject.id;
            $scope.data.text = $scope.apartmentObject.description;
            $scope.data.maxoccupancy = $scope.apartmentObject.max_occupancy;
            $scope.data.roomrate = $scope.apartmentObject.default_room_rate;
            $scope.data.sizemeasurement = $scope.apartmentObject.size_measurement;
            $scope.data.sizemeasurementunit = 'sqft';
        }
    

        $scope.saveRecord = function () {
            var message;
            message = 'Record updated';		
            $scope.showSaveSpinner = true;
            ChannelManagerService.postApartment($scope.data).then(function (success) {
                $scope.roomtypeForm.$setPristine();		
            	UtilBroadcastService.showAlert($scope, 'Success', message, false);
            }, function (error) {
                console.log(error);
            	UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
            }).finally(function(){
                $scope.showSaveSpinner = false;            				
            	$timeout(function() {
            		$uibModalInstance.close('close');
            	}, 2000);						
            });		
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

        // $scope.addApartmentsFromDevelopment = function () {
        //     var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
        //         'Warning!',
        //         'Are you sure you would like to add every apartment for this development?',
        //         'alert-danger')
        //     );

        //     modalInstance.result.then(function (msg) {
        //         ChannelManagerService.postApartmentsByDevelopment($scope.data.hotelcode).then(function (success) {
        //             var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'All apartments added successfully!', 'alert-success', true));
        //             $timeout(function () {
        //                 modalInstance1.close('cancel');
        //                 $uibModalInstance.close('confirmed');
        //             }, 2000);

        //         }, function (error) {
        //             console.log(error);
        //             var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
        //                 'Unable to add apartments. Please add apartments individually.', 'alert-danger', true));
        //             $timeout(function () { modalInstance1.close('cancel'); }, 2000);
        //         });
        //     });
        // }

    })