angular.module('developments.controllers', [])

.controller('DevelopmentsController', ['$state', 'DevelopmentsService', '$scope', '$filter', 'PermissionsService', 
        function($state, DevelopmentsService, $scope, $filter, PermissionsService) {
    var self = this;	
   
    self.dataLoaded = false;
    self.developments = [];
    self.developments.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	self.status = 'active';
	self.development_states = DevelopmentsService.getStatusForDevelopments();
	
	var config = {
		params: { 	
			orderby: 'developments.name',
			page: self.developments.current_page, 				
			per_page: 20
		}				 
	};
    
    PermissionsService.getByModuleByUser('developments').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
    
	function getDevelopments(){
		self.dataLoaded = false;
		config.params.findby = 'developments.status';
		config.params.findbyvalue = self.status;
		DevelopmentsService.getDevelopmentsPaginate(config).then(function(success){   		
			self.developments = success.data;        
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		}); 
	}
	
	getDevelopments();
	
	self.searchByStatus = function(state){
		self.status=state;
		getDevelopments();
	}	
	      
    self.go = function(id){
        $state.go('main.development', {'id': id}, {reload: true});
    }
   
    self.pagination = function(){			
		config.params.page = self.developments.current_page;
		getDevelopments();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getDevelopments();
	   
	}
	
	self.search = function(){
		self.developments.current_page = 1;
		config.params.page = self.developments.current_page;
		config.params.search = self.search_value;
		getDevelopments();
	}
        
    
}])