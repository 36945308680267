angular.module('contacts.controllers')

.controller('ContactReportsController', ['ContactsService', function(ContactsService) {
		var self = this;

		self.report_data = {'data': 'no'};
		self.contacts = [];
	
		ContactsService.getContacts().then(function(success){
			self.contacts = success.data;
		}, function(error){
			console.log(error);
		});
		
		self.goToSummary = function(){
	
			ContactsService.getContactSummaryReport().then(function(success) {
				self.report_data= {
					"template": { "shortid" : "4kWXH9Ase" },
					"title": "A Title",
					"data": success.data	
				};					
				
			}, function(error){
				console.log(error);
			});
				
			   
		};
		
		self.goToDetail = function(contact) {
			ContactsService.getContactDetailedReport(contact.id).then(function(success) {
				self.report_data= {
					"template": { "shortid" : "4kGgSc0ox" },
					"title": contact.contact_name,
					"data": success.data
				};			
			}, function(error){
				console.log(error);
			});
			  
		}


	}
])