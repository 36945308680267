angular.module('invoices.controllers')

	.controller('InvoiceController', ['$scope', '$rootScope', 'UtilBroadcastService', 'InvoicesService', 'BookingsService', 'UtilService', '$stateParams', '$uibModal', '$state',
		'ModalGetEmailAddress', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout', '$window', 'SettingsService', 
		function ($scope, $rootScope, UtilBroadcastService, InvoicesService, BookingsService, UtilService,
			$stateParams, $uibModal, $state, ModalGetEmailAddress, ModalConfirmAction, ModalFeedback,
			PermissionsService, $timeout, $window, SettingsService) {

			var self = this;
			var config = { params: {} };

			var id = $stateParams.id;	

			self.booking_id = $stateParams.booking_id;
			self.booking = {};
			self.dataLoaded = false;
			self.showSaveSpinner = false;
			self.showEmailSpinner = false;
			self.showSendToXeroSpinner = false;
			self.showCommissionSpinner = false;
			self.showCommissionButton = false;
			self.showAlert = false;
			self.alertDetails = {};
			self.title;
			self.invoice = {};
			self.invoices = [];
			self.show_invoice_details_alert = false;
			self.booking = {};
			self.invoicemode = true;
			self.isNew = (id == 'add-new');
			self.permissions = [];
			self.report_data = { 'data': 'no' };
			self.invoice_states = InvoicesService.getStatusForInvoices();
			self.invoice_types = InvoicesService.getTypesForInvoices();
			self.invoice_account_types = InvoicesService.getAccountTypes();

			self.invoiceSentToXero = true;
			self.xero_payments = 0;
			self.sams_payments = 0;
			self.paymentsReconciled = true;

			var config;

			self.params = $stateParams;

			PermissionsService.getByModuleByUser('invoices|bookings|channel-manager|accountancy').then(function (success) {
				self.permissions = success.data;
				init();
			}, function (error) {
				console.log(error);
			});

			function init() {
				if (!self.isNew) {
					getInvoice();		
					if(self.permissions['accountancy.integration']){
						getXeroInvoiceExists();
						getXeroPayments();
					}		
					
				} else {
					self.invoice.invoice_date = moment().format('YYYY-MM-DD');
					self.invoice.raise_room_rate = 0;
					self.invoice.credit_note = 0;
					self.invoice.status = 'new';
					self.invoice.type = 'standard';
					self.invoice.xero_account_type = '200-Sales';					
					getVatRate();
					self.dataLoaded = true;

				}
			}

			

			function getVatRate() {
				config = {};
				config.setting = 'vat_rate_1';
				SettingsService.postBySetting(config)
					.then(function (success) {
						self.invoice.vat_rate = success.data.value;
					}, function (error) {
						console.log(error);
					});
			}

			function getInvoices() {
				config.params = {};
				config.params.dropdown = true;
				config.params.orderby = 'invoices.invoice_number';
				config.params.findby = 'invoices.credit_note';
				config.params.findbyvalue = '0';
				config.params.sort = 'DESC';
				InvoicesService.getInvoices(config).then(function (success) {
					self.invoices = success.data;
				}, function (error) {
					console.log(error);
				});
			}

			getInvoices();		

			function getXeroInvoiceExists() {
				InvoicesService.getXeroInvoice(id).then(function (success) {
					self.invoiceSentToXero = (success.data != false);
				}, function (error) {
					console.log(error);
				});
			}

			function getXeroPayments() {
				InvoicesService.getXeroInvoicePaidAmount(id).then(function (success) {
					self.xero_payments = success.data;
					//reconcilePayments();				
				}, function (error) {
					console.log(error);
				});
			}

			function getBookings() {
				/*************Get Booking ***********/
				config.params = {};
				config.params.orderby = 'bookings.id';
				config.params.sort = 'DESC';
				BookingsService.getBookings(config).then(function (success) {
					self.bookings = success.data;
				}, function (error) {
					console.log(error);
				});
			}

			getBookings();

			$scope.$watch(angular.bind(this, function () {
				return self.invoice.booking_id;
			}), function (oldVal, newVal) {
				if ((oldVal != newVal) && self.invoice.booking_id != null) getBooking();
			});

			function getBooking() {
				BookingsService.getBooking(self.invoice.booking_id).then(function (success) {
					self.booking = success.data;
					self.dataLoaded = true;
					if (self.isNew) {
						self.invoice.booking_confirmation = self.booking.default_booking_confirmation;
						self.invoice.attention_of = self.booking.default_attention_of;
						self.lastinvoiceNumber = self.booking.last_invoice_number;
						self.invoice.invoice_number = self.booking.current_invoice_number;
						self.invoice.start_date = self.booking.next_invoice_date;
						self.invoice.due_date = self.booking.invoice_due_date;
					}				
				}, function (error) {
					console.log(error);
				});
			}

			//loads booking when arriving from booking-show
			if (self.booking_id != null) {
				self.invoice.booking_id = self.booking_id;
				getBooking();
			}

			function getInvoice() {
				InvoicesService.getInvoice(id).then(function (success) {
					self.title = success.data.invoice_number;
					self.invoice = success.data;
					if(self.invoice.credit_note == 0) self.showCommissionButton = true;					
					if (self.invoice.invoicedetails.length === 0 && !self.isNew) {
						var msg = {}; msg.title = 'No Invoice Details'; msg.description = 'Warning: Please add invoice details to this invoice.';
						modalMissingInfoAlert(msg);
					}				
					self.dataLoaded = true;	
					broadcastUpdate();					
				}, function (error) {
					console.log(error);
				});
			}

			if (id == 'add-new' && self.booking_id != null) {
				self.invoice.booking_id = self.booking_id;
			}

			function validateDates(){

				if(self.invoice.start_date == false || self.invoice.end_date == false) return;
				
				if( self.invoice.start_date > self.invoice.end_date ){
					$scope.invoiceForm.end_date.$setValidity("endDateBeforeStartDate", false);
				} else {
					$scope.invoiceForm.end_date.$setValidity("endDateBeforeStartDate", true);					
				}		
			}		

			self.updateDatePaid = function (type) {
				if (type == 'status') {
					if (self.invoice.status == 'sent') {
						self.invoice.date_paid = null;
					}
					if (self.invoice.status == 'paid') {
						var d = moment.utc();
						self.invoice.date_paid = d;
					}
				}

				if (type == 'date-paid') {
					if (self.invoice.date_paid != null) {
						self.invoice.status = 'paid';
					}
				}
			}

			/**************************************/
			/*			Modal Missing Info        */
			/**************************************/


			function modalMissingInfoAlert(msg) {
				var modalInstance = $uibModal.open(ModalFeedback.showFeedback(msg.title, msg.description, 'alert-default', false));
			}

			self.useBookingDates = function () {
				self.invoice.start_date = self.booking.check_in_date;
				self.invoice.end_date = self.booking.check_out_date;
			}

			self.addCalculateEndDate = function (days) {
				var date = moment.utc(self.invoice.start_date).startOf('day');
				self.invoice.end_date = date.add(days, 'days')
			}

			/**************************************/
			/*			Send Emails               */
			/**************************************/

			self.sendEmail = function () {

				var modalInstance = $uibModal.open(ModalGetEmailAddress.show(self.invoice.booking_id, self.permissions, 'Email Invoice'));

				modalInstance.result.then(function (email_address) {
					var mailConfig = { params: {} };
					mailConfig.params.email_address = email_address;
					self.showEmailSpinner = true;
					InvoicesService.getEmailInvoice(id, mailConfig).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to send email.', 'alert-danger'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}).finally(function () {
						self.showEmailSpinner = false;
					});

				});
			}

			/**************************************/
			/*			Add Commission             */
			/**************************************/

			self.addCommission = function () {

				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to add a commission credit note for this invoice.',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {
					self.showCommissionSpinner = true;
					InvoicesService.getAddCommission(id).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', success.data, 'alert-success'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							error.data, 'alert-danger'));
						$timeout(function () { modalInstance1.close('cancel'); }, 5000);
					}).finally(function () {
						getInvoice();
						self.showCommissionSpinner = false;
					});

				});
			}

			self.sendInvoiceToXero = function () {
				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to send this invoice to Xero.',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {
					self.showSendToXeroSpinner = true;

					InvoicesService.getSentInvoiceToXero(id).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Invoice sent to Xero!', 'alert-success'));
						$timeout(function () {
							modalInstance1.close('cancel');
							init();
						}, 2000);

					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to send to Xero. Please contact a system administrator.', 'alert-danger'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}).finally(function () {
						self.showSendToXeroSpinner = false;
					});
				});
			}

			/**************************************/
			/*		  	Save Record              */
			/**************************************/

			self.saveRecord = function () {
				self.showSaveSpinner = true;
				var promise; var message;
				if (self.isNew) {
					self.showSaveSpinner = true;
					promise = InvoicesService.postStore(self.invoice)
					message = 'Record created';
				} else {
					promise = InvoicesService.postUpdate(id, self.invoice);
					message = 'Record updated';
				}

				promise.then(function (success) {
					var newId = false;
					if (self.isNew) newId = success.data;
					$scope.invoiceForm.$setPristine();
					UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.invoice');
				}, function (error) {
					console.log(error);
					UtilBroadcastService.showAlert(self, 'Failure', 'Unable to create/update record.');
					broadcastUpdate();
				}).finally(function () {
					if (!self.isNew) getInvoice();
					UtilBroadcastService.broadcastUpdate();
				});
			}

			function broadcastUpdate() {
				$rootScope.$broadcast('updateSidebars', {
					data: true
				});
			}

			self.deleteRecord = function () {

				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to delete this record? Deletions cannot be undone.',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {
					InvoicesService.getDelete(id).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
						$state.go('main.invoices', {}, { reload: true });
					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					});
				});

			}

			self.print = function (type) {
				var config = { params: { type: type, credit_note: self.invoice.credit_note } };
				self.showSpinner = 1;

				InvoicesService.getStandardInvoiceReport(id, config).then(function (url) {
					var printwWindow = $window.open(url);
				}, function (error) {
					console.log(error);
				}).finally(function () {
					self.showSpinner = 0;
				});
			}

			self.printWord = function (type) {
				var params = {};
				params.type = type;
				var data = {};
				data.type = type;
				data.credit_note = self.invoice.credit_note;
				self.showSpinner = 1;

				InvoicesService.getStandardInvoiceReportForWord(id, data, params).then(function (url) {
					var hiddenElement = document.createElement('a');
					hiddenElement.href = url;
					hiddenElement.target = '_blank';
					hiddenElement.download = self.invoice.invoice_number + '.docx';
					document.body.appendChild(hiddenElement);
					hiddenElement.click();
					$timeout(function () {
						hiddenElement.remove();
					}, 50);
				}, function (error) {
					console.log(error);
				}).finally(function () {
					self.showSpinner = 0;
				});
			}

			$scope.$on('updateInvoice', function (event, result) {
				getInvoice();
				getXeroInvoiceExists();
				getXeroPayments();
			});

			$scope.$watch(function () {
				return self.invoice.end_date;
			},function(newValue, oldValue){
				if( (newValue != oldValue) && angular.isDefined(newValue)) 
				{
					validateDates();
				}		
			});
			
			$scope.$watch(function () {
				return self.invoice.start_date;
			},function(newValue, oldValue){
				if( (newValue != oldValue) && angular.isDefined(newValue)) 
				{
					validateDates();
				}		
			});
		}])