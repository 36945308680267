angular.module('apartments.directives')

	.directive('apartmentIssues', function ($rootScope, IssuesService, $uibModal, ModalApartmentIssue) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				apartmentId: '=',
				address1: '=',
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<p ng-show="!permissions['issues.list']">Unable to view due to permissions</p>
								<table ng-show="permissions['issues.list']" class="table table-hover clickable"  >
									<thead>
										<tr>
												<th>Issue</th>								
												<th>Type</th>
												<th>Status</th>
												<th>Date</th>
										</tr>
									</thead>
									<tbody >
										<tr ng-repeat="item in issues" class="table-row" href="" 
											ng-click="showApartmentIssue(item)" >
											<td>{{item.issue}}</td>
											<td><span class="lb-sm label"
												ng-class="{'label-danger': item.type=='red',
														'label-warning': item.type=='amber',
														'label-success': item.type=='green'}"
												  >{{item.type | capitalize}}</span></td>
											<td>{{item.status | capitalize }}</td>
											<td>{{item.created_at }}</td>																
										</tr>										
									</tbody>									
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['issues.create']" 
									click-fn="createApartmentIssue()" button-type="add">
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.apartmentId;
				const address1 = scope.address1;
				var config={params: {}};
				var apartmentIssueData;
				scope.dataLoaded = false;
				scope.issues = [];	
				scope.issue_states = IssuesService.getStatusForIssues();
				scope.issue_types = IssuesService.getTypesForIssues();
								
				function getIssues(){
					config.params = {};	
					config = {	params: { 	findby: 'apartment_id', findbyvalue: id, extended: true, with: 'issueDetailsExtended'	}  };	
					IssuesService.getIssues(config).then(function(success){     
						scope.issues = success.data;                       
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;						
					});
				}
				
				getIssues();
				
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getIssues();
				});	
				
				
				function showIssue(){
					var modalInstance = $uibModal.open(
						ModalApartmentIssue.show(apartmentIssueData, 
														scope.permissions,
														scope.issue_types,
														scope.issue_states)
					);
					
					modalInstance.result.then(function (data) {
						if(data == 'success'){
							getIssues();
						}						
					});
				}
				
				scope.showApartmentIssue = function(item){					
					apartmentIssueData = item;
					showIssue();
					
				}
				
				scope.createApartmentIssue = function(){
					apartmentIssueData = {id: 'new', apartment_id: id, address1: address1};
					showIssue();
				}				
				
			}
		};
	})