angular.module('issues.controllers')

.controller('IssueController', ['$scope', 'UtilBroadcastService', 'IssuesService', '$stateParams', '$uibModal', '$state', 
				'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout', 'SettingsService', 
        function($scope, UtilBroadcastService, IssuesService, $stateParams, $uibModal, $state, 
			ModalConfirmAction, ModalFeedback, PermissionsService, $timeout, SettingsService) {
        var self = this;

        self.dataLoaded = false;        
        self.issue={};               
		self.apartments = [];
        self.showSpinner = 0;
        var id = $stateParams.id;
        self.isNew = (id=='add-new');
        self.permissions = [];
        self.issue_states = IssuesService.getStatusForIssues();
		self.issue_types = IssuesService.getTypesForIssues();
		self.issue_related_to = IssuesService.getRelatedToForIssues();
		self.issue_focus = IssuesService.getFocusForIssues();
		self.showSaveSpinner = false;	
		self.showAlert = false;
		self.alertDetails = {};
		self.dateCompletedReadOnly = true;
		self.statusDisabled = true;
		self.issue_response_times = [];		
		var config = {	params: {}	};

		    
        PermissionsService.getByModuleByUser('issues').then(function(success){   			
			self.permissions = success.data;			
		}, function(error){
			console.log(error);
		});   

        function getIssue(){
            IssuesService.getShow(id).then(function(success){    
				self.issue = success.data;			
				self.dateCompletedReadOnly = (self.issue.date_completed != null);
				self.statusDisabled = (self.issue.status == 'completed');				
                self.dataLoaded = true;
            }, function(error){
                console.log(error);
            });
        }		
	
        if( !self.isNew ){ 
            getIssue();			
        } else {			
            self.issue.status = 'new';
            self.dataLoaded = true;
        }  
				
		function getIssueTimeResponses(){
			SettingsService.postByGroup('Issue Response Times')
				.then(function(success){
					self.issue_response_times = success.data;		
				}, function(error){
					console.log(error);
				});
		}
		
		getIssueTimeResponses();
		
		self.updateDueBy = function(){			
			self.issue.due_by = IssuesService.getDueByDate(self.issue_response_times, self.issue.type);
		}		

	

		self.increaseType = function(){
			if(self.issue.type == 'red'){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
				'Cannot increase type beyond Red.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 4000);
				return;
			}

			var type, newType;

			if(self.issue.type == 'amber'){
				type = 'amber';
				newType = 'red';
			}

			if(self.issue.type == 'green'){
				type = 'green';
				newType = 'amber';
			}

			var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
				'Warning!', 
				'Are you sure you would like to increase the type from '+type+' to '+newType+'. This will decrease the response time that you will have to resolve this issue.', 
				'alert-danger')
			);

			modalInstance.result.then(function (msg) {				
				var hoursToAdd = 0;
				hoursToAdd = IssuesService.getHoursToAdd(self.issue_response_times, newType);			
				var now = moment(self.issue.created_at, "DD/MM/YYYY HH:mm:ss").add(hoursToAdd, 'h');
				self.issue.due_by = now.format("DD/MM/YYYY HH:mm:ss");
				self.issue.type = newType;
				
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success!',
				'Please save to commit changes.', 'alert-success', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
				return;
			});
		}

        self.saveRecord = function(){  
			self.showSaveSpinner = true; 
			
			IssuesService.postUpdate(id, self.issue).then(function(success){	
				console.log(self.issue);			
				$scope.issueForm.$setPristine();
				UtilBroadcastService.showAlert(self, 'Success', 'Record created', false, 'main.issue');				
			}, function(error){
				console.log(error);				
				UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
			});
        }
		
		self.createRecord = function(){ 
			self.showSaveSpinner = true; 
			IssuesService.postStore(self.issue).then(function(success){
				$scope.issueForm.$setPristine();
				UtilBroadcastService.showAlert(self, 'Success', 'Record updated', success.data, 'main.issue');			
			}, function(error){
				console.log(error);
				UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');			
            });
        }


        self.deleteRecord = function(){          

            var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);

            modalInstance.result.then(function (msg) {
                IssuesService.getDestroy(id).then(function(success){
                    var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
					$timeout(function() { 
						modalInstance1.close('cancel'); 
						$state.go('main.issues', {}, {reload: true});
					}, 2000);
					
				}, function(error){
					console.log(error);
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
								'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
					$timeout(function() { modalInstance1.close('cancel'); }, 2000);
				});
            });

        }
		
		self.updateDateCompleted = function(type){
			if(type == 'status'){
				if (self.issue.status != 'completed'){
					self.issue.date_completed = null;
				}
				if(self.issue.status == 'completed'){
					var d = moment.utc();
					self.issue.date_completed  = d;
				}
			}
			
			if(type == 'date-completed'){
				if (self.issue.date_completed  != null){
					self.issue.status = 'completed';
				}
			}
		}

		$scope.$watch(function () {
			return self.issue.focus;
		},function(newValue, oldValue){
			if( (newValue != oldValue) && angular.isDefined(newValue)){
				if( newValue == 'apartment' || newValue == 'other' ) self.issue.development_id = null;
				if (newValue == 'development' || newValue =='other' ) self.issue.apartment_id = null;
			}
		});		
	
	
}])