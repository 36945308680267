angular.module('developments.services', [])

.factory("DevelopmentsService", ['$http',
	function($http){
	 
	var developmentsService = {};                  
	
		
	developmentsService.getDevelopments = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/development', config);
	}
	
	developmentsService.getDevelopmentsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/development/paginate', config);
	}
	
	developmentsService.getDevelopment = function(id){            
		return $http.get('api/development/show/'+id);
	}
	
	developmentsService.postUpdate = function(id, development){            
		return $http.post('api/development/update/'+id, development);
	}
	
	developmentsService.postStore = function(development){            
		return $http.post('api/development/store', development);
	}     
	
	developmentsService.getDelete = function(id){            
		return $http.get('api/development/destroy/'+id);
	}
	
	developmentsService.getStatusForDevelopments = function(){            
		return ['active', 'inactive'];
	}

	    /*
     *             Development Attachment
     */

    developmentsService.getAttachmentTypes = function(){            
        return ['booking_confirmation_attachment', 'marketing_attachment', 'other'];
    }
    
    developmentsService.getDevelopmentAttachmentByDevelopment = function(id){            
        return $http.get('api/development-attachments/by-development/'+id);
    }
    
    developmentsService.postDevelopmentAttachmentUpdate = function(id, data){            
        return $http.post('api/development-attachments/update/'+id, data);
    }
    
    developmentsService.postDevelopmentAttachmentStore = function(data){            
        return $http.post('api/development-attachments/store', data);
    }
    
    developmentsService.getDevelopmentAttachmentDelete = function(id){            
        return $http.get('api/development-attachments/destroy/'+id);
    }


	
			
	return developmentsService;
}])