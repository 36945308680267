angular.module('invoices.directives')

	.directive('invoicePayments', function ($rootScope, InvoicesService, ModalConfigurationService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				invoiceId: '=',					
				showAlert: '=',
				permissions: '=',
				amountDue: '=',
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Date</th>										
										<th>Amount</th>										
										<th>Paid To</th>
										<th colspan="2" ng-if="permissions['invoices.show']" style="width: 10%"><div class="text-center">Action</div></th>
									<tr ng-repeat="item in invoice_payments">                               
										<td>{{item.date_paid | date:'mediumDate'}}</td>
										<td>{{item.amount_paid | number:2}}</td>
										<td>{{item.paid_to}}</td>
										<td><my-sub-form-button click-fn="showInvoicePayment(item)" 
											ng-if="permissions['invoices.show']" 
											button-type="edit"></my-sub-form-button></td>
										<!--<td><my-sub-form-button 
											ng-if="permissions['invoices.delete']" 
											click-fn="deleteInvoicePayment(item)" 
											button-type="delete"></my-sub-form-button>
										</td>    -->        										
									</tr>                            
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-if="permissions['invoices.create']"  
												click-fn="createInvoicePayment()" 
												button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {				
				
				var invoicePaymentData;
				scope.dataLoaded = false;				
				scope.invoice_payments = [];			
							
				function updateAlert(value){
					setTimeout(function(){
						scope.$apply(function(){
							scope.showAlert=value;							
						});		
					}, 500);						
				}
				
				function getInvoicePayments(){
					scope.dataLoaded = false;
					InvoicesService.getInvoicePaymentByInvoice(scope.invoiceId).then(function(success){
						scope.invoice_payments = success.data;					
						updateAlert(scope.invoice_payments.length==0);		
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;
					});
				}				
			
				getInvoicePayments();	
												
				
				
				function showPayment(){
					var modalInstance = ModalConfigurationService.getInvoicePayment(invoicePaymentData, scope.permissions, scope.amountDue);
					
					modalInstance.result.then(function (data) {
						getInvoicePayments(); 
						broadcastUpdate();
					});
				}
				
				scope.showInvoicePayment = function(item){
					invoicePaymentData = item;				
					showPayment();
					
				}
				
				scope.createInvoicePayment = function(){
					invoicePaymentData = {id: 'new', invoice_id: scope.invoiceId, paid_to: 'Metro business account'};
					showPayment();					
				}

				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateInvoice', {
						data: true
					});
				}		
				
				scope.$on('updateSidebars', function (event, result) {					
					getInvoicePayments();
				});
				
				scope.$watch('invoiceId', function(newVal, oldVal){
					if(newVal != oldVal){
						getInvoicePayments();	
					}
				});
			}
		};
	})