angular.module('login-activity')

.controller('LoginActivitiesController', ['$state', 'LoginActivityService', 
			'$scope', '$filter', 'PermissionsService', '$http', '$timeout',
        function($state, LoginActivityService, $scope, $filter, PermissionsService, $http, $timeout) {
        var self = this;

        self.dataLoaded = false;
        self.login_activities = [];    
        self.showSpinner=0;
        self.login_activities.current_page = 1;        
		self.maxSize = 5;
		self.search_value;
		self.sortASC = true;
        self.permissions = [];
		
		var config = {
			params: { 	
				page:self.login_activities.current_page, 				
				per_page:30,
				orderby: 'id',
				sort: 'DESC'
			}				 
		};

        PermissionsService.getByModuleByUser('logins').then(function(success){   			
			self.permissions = success.data;
		}, function(error){
			console.log(error);
		});   
		
		function getLoginActivities(){
			self.dataLoaded = false;
			LoginActivityService.getPaginate(config).then(function(success){
				self.login_activities = success.data;				
				self.dataLoaded = true;
			}, function(error){
				console.log(error);
			}).finally(function(){
				defaultConfig();
			});
		}
		
		getLoginActivities();        
      
        self.go = function(id){
            $state.go('main.login-activity', {'id': id}, {reload: true});
        }
		
		self.pagination = function(){			
			config.params.page = self.login_activities.current_page;
			getLoginActivities();
		}
		
		self.sortTable = function(heading){
			config.params.orderby = heading;
			config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
			self.sortASC = !self.sortASC;
			getLoginActivities();
		   
		}
		
		self.search = function(){
			self.login_activities.current_page = 1;
			config.params.page = self.login_activities.current_page;
			config.params.search = self.search_value;
			getLoginActivities();
		}
        
       
}])