angular.module('versions.services', [])

.factory("VersionsService", ['$http',
	function($http){
	 
	var versionsService = {};                  
	
	versionsService.getVersionsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/version/paginate', config);
	}
	
	versionsService.getCurrentVersion = function(){            
		return $http.get('api/version/current-version');
	}

	versionsService.getShow = function(id){            
		return $http.get('api/version/show/'+id);
	}
	
	versionsService.postUpdate = function(id, data){            
		return $http.post('api/version/update/'+id, data);
	}
	
	versionsService.postStore = function(data){            
		return $http.post('api/version/store', data);
	}     
	
	versionsService.getDelete = function(id){            
		return $http.get('api/version/destroy/'+id);
	}
				
	return versionsService;
}])