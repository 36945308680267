angular.module('bookings.directives')

	.directive('bookingFinancials', function (BookingsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				bookingId: '='
			},      
			template:	`<div class="panel panel-default" >
							<div class="panel-heading">
								<h3 class="panel-title"><i class="fa fa-clock-o fa-fw"></i> Booking Financials</h3>
							</div>
							<div class="panel-body">	
								<div ng-show="!dataLoaded" class="row text-center">
									<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
								</div>
								<table ng-show="dataLoaded" class="table"  >							
									<tbody >
										<tr>
											<td><b>Booking Value</b></td>
											<td class="text-right">{{financials.booking_value | number:2}}</td>
										</tr>
										<tr>
											<td><b>Invoices</b></td>
											<td class="text-right">{{financials.invoice_value | number:2}}</td>
										</tr>
										<tr>
											<td><b>Credit Notes</b></td>
											<td class="text-right">{{financials.credit_note_value | number:2}}</td>
										</tr>
										<tr>
											<td><b>Invoices - Credit Notes</b></td>
											<td class="text-right">{{financials.invoice_less_credit_note_value | number:2}}</td>
										</tr>
										<tr>
											<td><b>Paid Invoices</b></td>
											<td class="text-right">{{financials.paid_invoices_value | number:2}}</td>
										</tr>
										<tr>
											<td><b>Overdue Invoices</b></td>
											<td class="text-right">{{financials.overdue_invoices_value | number:2}}</td>
										</tr>
										
									</tbody>									
								</table>                
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				
				var	id = scope.bookingId;
				scope.dataLoaded = false;
				scope.financials = {};	
								
				function getBookingFinancials(){		
					scope.dataLoaded = false;
					BookingsService.getBookingFinancials(id).then(function(success){
						scope.financials = success.data;						
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getBookingFinancials();		
				
			}
		};
	})