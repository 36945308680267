angular.module('settings.services', [])

.factory("SettingsService", ['$http', function($http){

	var settingsService = {};

	settingsService.getSettings = function(){
		return $http.get('api/setting');
	}

	settingsService.getSetting = function(id){
		return $http.get('api/setting/show/'+id);
	}
	
	settingsService.getSettingGroups = function(id){
		return $http.get('api/setting/setting-groups');
	}
	
	settingsService.postByGroup = function(group){
		var object = {}; object.group = group;
		return $http.post('api/setting/by-group', object);
	}

	settingsService.postByGroupV2 = function(group){
		var object = {}; object.group = group;
		return $http.post('api/setting/by-group-v2', object);
	}
	
	settingsService.postBySetting = function(object){
		return $http.post('api/setting/by-setting', object);
	}

	settingsService.postUpdate = function(settings){
		return $http.post('api/setting/update', settings);
	}

	return settingsService;
}])