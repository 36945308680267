angular.module('channel-manager')

    .controller('CmPushedReservationController', ['$scope', 'UtilService', 'ChannelManagerService', 'SchedulersService', 'ModalConfigurationService',
        '$stateParams', '$state',  'PermissionsService', '$timeout', '$uibModal', 'ModalConfirmAction', 'ModalFeedback',
        function ($scope, UtilService, ChannelManagerService, SchedulersService, ModalConfigurationService,
            $stateParams,  $state,  PermissionsService, $timeout, $uibModal, ModalConfirmAction, ModalFeedback) {
            var self = this;

            self.dataLoaded = false;
            self.dataLoadedScheduler = false;
            self.reservation = {};
            self.showSaveSpinner = false;
            self.showAlert = false;
            self.alertDetails = {};
            var id = $stateParams.id;
            self.permissions = [];


            self.query = {};
            self.dates = {};
            var no_of_days = 34;
            

            PermissionsService.getByModuleByUser('channel-manager').then(function (success) {
                self.permissions = success.data;
            }, function (error) {
                console.log(error);
            });

            function getReservation() {
                self.dataLoaded = false;
                ChannelManagerService.getReservationsShow(id).then(function (success) {
                    self.reservation = success.data;
                    self.dataLoaded = true;
                }, function (error) {
                    console.log(error);
                });
            }

            getReservation();

            function defaultDates() {
                var now = new Date(self.reservation.cm_room_reservations[0].arrival_date);
                self.dates.start_date = now;
                var d = new Date();
                d.setDate(d.getDate() + no_of_days);
                d.setHours(0, 0, 0, 0);
                self.dates.end_date = d;
            }

            function initQuery() {
                self.query.development = UtilService.formatHotelId(self.reservation.hotel_id);
                self.query.area = -1;
                self.query.beds = -1;
            }

            function getData() {
                self.dataLoadedScheduler = false;
                var optional = [];
                var parameters = {};
                parameters.dates = self.dates;               
                parameters.optional = self.query;
                SchedulersService.postShowNew(parameters).then(function (success) {
                    self.data = success.data;
                    self.dataLoadedScheduler = true;
                }, function (error) {
                    console.log(error);
                });
            }

            /***********************************/
            /*Navigation Buttons*/
            /***********************************/
            self.backDouble = function () {
                var d = new Date(self.dates.start_date);
                d.setMonth(d.getMonth() - 1);
                self.dates.start_date = d;

                var e = new Date();
                e.setTime(d.getTime() + no_of_days * 86400000);
                self.dates.end_date = e;
                getData();
            }

            self.backSingle = function () {
                var d = new Date(self.dates.start_date);
                d.setDate(d.getDate() - 1);
                self.dates.start_date = d;

                var e = new Date();
                e.setTime(d.getTime() + no_of_days * 86400000);
                self.dates.end_date = e;
                getData();
            }

            self.forwardSingle = function () {
                var d = new Date(self.dates.start_date);
                d.setDate(d.getDate() + 1);
                self.dates.start_date = d;

                var e = new Date();
                e.setTime(d.getTime() + no_of_days * 86400000);
                self.dates.end_date = e;
                getData();
            }

            self.forwardDouble = function () {
                var d = new Date(self.dates.start_date);
                d.setMonth(d.getMonth() + 1);
                self.dates.start_date = d;

                var e = new Date();
                e.setTime(d.getTime() + no_of_days * 86400000);
                self.dates.end_date = e;
                getData();
            }

            self.dateChange = function () {
                var d = new Date(self.dates.start_date);
                self.dates.start_date = d;

                var e = new Date();
                e.setTime(d.getTime() + no_of_days * 86400000);
                self.dates.end_date = e;
                getData();
            }

            self.home = function () {
                defaultDates();
                getData();
                var container = document.getElementById('stageContainer1');
                container.scrollLeft = 0;
            }

            self.createBooking = function(room_res_id){
                var modalInstance = ModalConfigurationService.channelManagerCreateBookingView({ 'development_id': UtilService.formatHotelId(self.reservation.hotel_id), 'cm_room_reservation_id': room_res_id }, self.permissions);
                
                modalInstance.result.then(function (booking_id) {
                    if(booking_id != false){
                        $timeout( function(){
                            $state.go('main.booking', {'id': booking_id}, {reload: true});
                        }, 2000 );                        
                    }
                }, function (error) {
                    console.log(error);
                });

                modalInstance.close('cancel');
            }

            self.assignReservation = function(){
                var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!', 
					'Are you sure you would like to convert this reservation to Assigned.', 
					'alert-danger')
				);

                modalInstance.result.then(function (msg) {
                    ChannelManagerService.getAssignReservation(id).then(function (success) {
                        var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Reservation assigned!', 'alert-success', true));						
						$timeout(function() { 
							modalInstance1.close('cancel'); 
							getReservation();
						}, 2000);
						
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
									'Unable to assign reservation. Please contact a system administrator.', 'alert-danger', true));			
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					});
                });
            }

            $scope.$watch(function () {
                return self.reservation.hotel_id;
            }, function (newValue, oldValue) {
                if (newValue != null) {
                    defaultDates();
                    initQuery();
                    getData();
                }
            }, true);

        }])
