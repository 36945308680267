angular.module('form-controls.directives')

	.directive('developmentDropdown', function (DevelopmentsService, $uibModal, ModalAddDevelopment) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				developmentId: '=ngModel',
				formName: '=',
				isNew: '=',
				isDisabled: '=',
				showAddButton: '=',
				isRequired: '=',
				title: '@'
			},    
			require: 'ngModel',
			template:	`<div class="form-group" ng-class="{'has-error': formName.development_id.$invalid && formName.development_id.$touched}">
							<label>{{title}}</label>
							<div ng-class="{'input-group': showAddButton && !isDisabled }">	
								<select class="form-control" name="development_id" ng-disabled="isDisabled"									
									ng-model="developmentId" 
									ng-options="option.id as option.name for option in developments" ng-required="isRequired">
								</select>
								<span class="input-group-btn" ng-show="showAddButton && !isDisabled">
									<button type="button" ng-disabled="isDisabled" class="btn btn-success" ng-click="addDevelopment()"><i class="fa fa-plus"></i></button>
								</span>		
							</div>
							<p ng-show="formName.development_id.$invalid && formName.development_id.$touched" 
								class="help-block">
								Required
							</p>							
											
						</div>`,
			link: function (scope, element, attr) {				
				var config={};
				var newId = -1;
				scope.developments;				
				
				function setDefaults(){					
					if (angular.isUndefined(scope.showAddButton)) scope.showAddButton = false;
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = false;
					if (angular.isUndefined(scope.isRequired)) scope.isRequired = true;
				}
				
				setDefaults();
								
				function getDevelopments(){
					
					config.params={};
					config.params.orderby = 'developments.name';
					config.params.dropdown = true;
					if(scope.isNew){
						config.params.findby = 'status';
						config.params.findbyvalue = 'active';
					}
					
					DevelopmentsService.getDevelopments(config).then(function(success){    				
						scope.developments = success.data;						
					}, function(error){
						console.log(error);
					}).finally(function(){
						if(newId>0) scope.developmentId = newId;
						newId = -1;
					});  				
				}
				
				getDevelopments();
				
				scope.addDevelopment= function(){
					var modalInstance = $uibModal.open( ModalAddDevelopment.addDevelopment() );
					
					modalInstance.result.then(function (data) {
						newId = data;
						getDevelopments();				
						modalInstance.close('cancel');                  
					});
				}
				
			}
		};
	})
