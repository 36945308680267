angular.module('invoices.directives')

	.directive('invoiceXeroDetails', function ($rootScope, InvoicesService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				invoiceId: '='				
			},      
			template:	`<div class="panel panel-default" >
			<div class="panel-heading">
				<h3 class="panel-title"><i class="fa fa-clock-o fa-fw"></i> Xero Reconciliation</h3>
			</div>

			<div class="panel-body" ng-show="!xeroInvoiceExists">	
				<div class="row text-center">
					<span ><i class="fa fa-cog fa-exclamation" style="font-size:24px"> Invoice not sent to Xero</i></span>
				</div>
			</div>

			<div class="panel-body" ng-show="xeroInvoiceExists">	
				<div ng-show="!dataLoaded" class="row text-center">
					<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
				</div>
				<table ng-show="dataLoaded" class="table"  >							
					<tbody >
						
						<tr>
							<td><b>Subtotal</b></td>
							<td align="right">{{xero_details.SubTotal | number:2 }}</td>
						</tr>
						<tr>
							<td><b>VAT</b></td>
							<td align="right">{{xero_details.TotalTax | number:2}}</td>
						</tr>
						<tr>
							<td><b>Total</b></td>
							<td align="right">{{xero_details.Total | number:2}}</td>
						</tr>
						<tr>
							<td><b>Total Due</b></td>
							<td align="right">{{xero_details.AmountDue | number:2 }}</td>
						</tr>
						<tr>
							<td><b>Total Paid</b></td>
							<td align="right">{{xero_details.AmountPaid | number: 2}}</td>
						</tr>
						
					</tbody>									
				</table>             
				
				<h4>Payments</h4>
				<table style="width: 75%" ng-show="dataLoaded" class="table">
					<thead>
						<td><b>Date</b></td>						
						<td><b>Ref</b></td>
						<td><b>Amount</b></td>
					</thead>
					<tbody>
					<tr ng-repeat="item in xero_details.Payments">     
							<td>{{item.Date | date:shortDate}}</td>							
							<td>{{item.Reference}}</td>
							<td  align="right">{{item.Amount | number:2}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>`,
			link: function (scope, element, attr) {				
								
				scope.dataLoaded = false;				
				scope.xero_details = [];
				scope.xeroInvoiceExists = false;
				
				function getXeroDetails(){
					scope.dataLoaded = false;
					InvoicesService.getXeroInvoice(scope.invoiceId).then(function(success){
						scope.xero_details = success.data;	
						scope.xeroInvoiceExists = (success.data != false);													
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;
					});
				}				
			
				getXeroDetails();
				
				scope.$on('updateSidebars', function (event, result) {					
					getXeroDetails();
				});
			
			}
		};
	})