angular.module('areas.controllers', [])

.controller('AreasController', ['$state', 'AreasService', '$scope', '$filter', 'PermissionsService', 
        function($state, AreasService, $scope, $filter, PermissionsService) {
    var self = this;	
    
    self.dataLoaded = false;
	
	self.areas = [];
	self.areas.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	
	var config = {
		params: { 	
			page:self.areas.current_page, 				
			per_page:20,
			status:'active'
		}				 
	};
    
    PermissionsService.getByModuleByUser('areas').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
    
	function getAreas(){
		self.dataLoaded = false;
		AreasService.getAreasPaginate(config).then(function(success){   		
			self.areas = success.data;        
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		}); 
	}
	
	getAreas();
  
    self.go = function(id){		
        $state.go('main.area', {'id': id}, {reload: true});
    }
	self.pagination = function(){			
		config.params.page = self.areas.current_page;
		getAreas();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getAreas();
	   
	}
	
	self.search = function(){
		self.areas.current_page = 1;
		config.params.page = self.areas.current_page;
		config.params.search = self.search_value;
		getAreas();
	}
		
}])