angular.module('channel-manager')

    .controller('ModalCmRatePlanViewController', function ($scope, $uibModalInstance, data, title, permissions, 
        ChannelManagerService, UtilBroadcastService, $timeout) {
        $scope.title = title;
        $scope.data = data;
        $scope.showSaveSpinner = false;
        $scope.showAlert = false;
        $scope.alertDetails = {};
        $scope.permissions = permissions;
        $scope.channeldata = {};

        $scope.types = ['New', 'Overlay', 'Remove', 'Activate'];

       
        $scope.$watch('data.name', function(newVal, oldVal){
            if(newVal) $scope.data.ratePlanId = $scope.data.name.replace(/\s+/g, '');
        }, true);
       

        $scope.saveRecord = function () {      
            
            $scope.showSaveSpinner = true;

            var promise; var message;	

            message = 'Record Updated';
            promise = ChannelManagerService.postRatePlan($scope.data);

            promise.then(function(success){						
                $scope.ratePlanForm.$setPristine();		               
                UtilBroadcastService.showAlert($scope, 'Success', message, false);
                
            }, function(error){
                console.log(error);             
            	UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
            }).finally(function(){
                $scope.showSaveSpinner = false;	
            	UtilBroadcastService.broadcastUpdate();							
            	$timeout(function() {
            		$uibModalInstance.close('close');
            	}, 2000);						
            });							
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

    })