angular.module('test-reports.controllers')

.controller('TestReportController2', ['$state', '$http', function($state, $http) {
    var self = this;	
    self.pdfContent = '';
   
    self.reportData = {
        "template": { "shortid" : "4kxNhftMse" },
                    "title": "A Title",
        "data": { "books": [{"name" : "A Tale of Two Cities", "author":"Charles Dickesn", "sales": 5},
                    {"name" : "A Christmas Carol", "author":"Charles Dickesn", "sales": 5}] }
        };
    
    self.getReport = function(){
        $http({
            method: 'POST',
            skipAuthorization: true,
            responseType: 'arraybuffer',
            url: 'https://sams-wla-reports.jsreportonline.net/api/report',
            data: reportdata,
            headers: {'Authorization': 'Basic YXVzdGlucm9iZXJ0NDM3QGdtYWlsLmNvbTpqNSJlOVw4OT4jNCpMe0Y=',
                    "Content-Type": "application/json"}
        }).then(function(success){
            console.log(success);
            var file = new Blob([success.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            self.pdfContent = fileURL;
        }, function(error){
            console.log(error);
        });
    }
}])
