angular.module('dashboard.directives')

	.directive('dashboardClientPb', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-primary">
										<div class="panel-heading" >
											<h3 class="panel-title"><i class="fa fa-long-arrow-right fa-fw"></i> {{chartTitle}}</h3>
										</div>
										<div class="panel-body" >
											<div class="col-lg-6">
												<label>Start Date</label>
												<p class="input-group">
													<input ng-model="dates.start_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="income_start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getClientPb()"
														ng-focus="focus=false" ng-blur="focus=true" name="income_start_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" 
															ng-click="income_start_date_open = !income_start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>
											</div>
											
											<div class="col-lg-6">
												<label>End Date</label>
												<p class="input-group">
													<input ng-model="dates.end_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="income_end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getClientPb()"
														ng-focus="focus=false" ng-blur="focus=true" name="income_end_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" 
															ng-click="income_end_date_open = !income_end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>
											</div>
										
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="200px" id="pie" class="chart chart-pie"
												
													chart-data="chart.data" 
													chart-labels="chart.labels" 
													chart-options="chart.options"													
													chart-legend="true"
												></canvas>

											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
			
				scope.dataLoaded = false;
				scope.dates = {};
				scope.chart = {};	
				scope.chart.labels = [];			

			
				function defaultDates(){
					scope.dates.start_date =  moment.utc().startOf('month').format('YYYY-MM-DD');				
					scope.dates.end_date = moment.utc().endOf('month').format('YYYY-MM-DD');					
				}
				
				defaultDates();				
				
				scope.getClientPb = function(){
					scope.dataLoaded = false;
					
					var object = {};
					object.end_date = scope.dates.end_date;		
					object.start_date = scope.dates.start_date;	
					object.type = 'PBClient';
					
					scope.chart.options = {
						//scaleLabel: (label) => $filter('currency')(label.value, '£'),
						//tooltipTemplate: (data) => `${data.label}: ${$filter('number')(data.value * 100) + '%'}`,
						legend: {
							display: true,
							labels: {
								fontColor: 'rgb(255, 99, 132)'								
							},
							position: 'left'
						},
						tooltips: {
							mode: 'label',
							callbacks: {
								label: function(tooltipItem, data) { 
									var indice = tooltipItem.index;                 
									return  data.labels[indice] +': '+ $filter('number')(data.datasets[0].data[indice] * 100, 2) + '%';
								}
							}
						},

					};
					
					
					scope.chart.data = [];					
					scope.chart.series = ['Series A'];
					scope.chart.colors = [
						{
							fillColor: 'Green',
							strokeColor: 'rgba(47, 132, 71, 0.8)',
						  }
						];
					
					DashboardService.postBookingFigures(object).then(function(success){	
						angular.forEach(success.data, function(value, key) {							
							scope.chart.labels.push(value.organisation_name);
							scope.chart.data.push(value.PercentOfBookings);						
						  });				
						
					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				scope.getClientPb();								
				
			}
		};
	})