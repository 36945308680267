angular.module('attachments.services', [])

.factory("AttachmentsService", ['$http', '$q',
	function($http, $q){
	 
	var attachmentsService = {};   

	attachmentsService.getAttachment = function(params){ 
		var defer = $q.defer();
		var fileType = params.file.split('.')[1].toLowerCase();
		var dataType;

		switch (fileType) {
            case 'pdf':
				dataType = 'application/pdf';
                break;
			case 'jpg':
				dataType = 'image/jpeg';
				break;
			case 'png':
				dataType = 'image/png';
				break;
			case 'gif':
				dataType = 'image/gif';				
				break;
			case 'xlsx':
				dataType = 'application/xlsx';				
				break;
			case 'xls':
				dataType = 'application/xls';				
				break;
			case 'doc':
				dataType = 'application/doc';				
				break;
			case 'docx':
				dataType = 'application/docx';				
				break;
			case 'txt':
				dataType = 'application/txt';				
				break;
			case 'msg':
				dataType = 'application/msg';				
				break;
            default:

		}
		
		$http({
			url : 'api/attachment',
			method : 'GET',			
			responseType : 'arraybuffer',
			params: params
		}).success(function(data, status, headers, config) {
			var pdfFile = new Blob([ data ], {
				type : dataType
			});
			var pdfUrl = URL.createObjectURL(pdfFile);		
			
			defer.resolve(pdfUrl);
		}).error(function(data, status, headers, config) {
			defer.resolve(data);
		});	

		

		return defer.promise;

	}
			
	return attachmentsService;
}])