angular.module('apartment-details-defaults.services', [])

.factory("ApartmentDetailsDefaultsService", ['$http',
	function($http){
	 
	var apartmentDetailsDefaultsService = {};          
	
	apartmentDetailsDefaultsService.getApartmentDetailsDefaults = function(){
		return $http.get('api/apartment-details-defaults');
	}

	apartmentDetailsDefaultsService.getApartmentDetailsDefaultsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/apartment-details-defaults/paginate', config);
	}
	
	apartmentDetailsDefaultsService.getApartmentDetailsDefault = function(id){            
		return $http.get('api/apartment-details-defaults/show/'+id);
	}
	
	apartmentDetailsDefaultsService.postUpdate = function(id, apartment){            
		return $http.post('api/apartment-details-defaults/update/'+id, apartment);
	}
	
	apartmentDetailsDefaultsService.postStore = function(apartment){            
		return $http.post('api/apartment-details-defaults/store', apartment);
	}     
	
	apartmentDetailsDefaultsService.getDelete = function(id){            
		return $http.get('api/apartment-details-defaults/destroy/'+id);
	}     

	apartmentDetailsDefaultsService.getAddDefaultToAllApartments = function(id){            
		return $http.get('api/apartment-details-defaults/add-default-to-all-apartments/'+id);
	}

	apartmentDetailsDefaultsService.getAddAllDefaultsToAllApartments = function(id){            
		return $http.get('api/apartment-details-defaults/add-all-defaults-to-all-apartments');
	}
			
	return apartmentDetailsDefaultsService;
}])

  



