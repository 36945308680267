angular.module('areas.controllers')

.controller('AreaController', ['$scope', 'UtilBroadcastService', 'AreasService', 
			'$stateParams', '$uibModal', '$state', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout',
        function($scope, UtilBroadcastService, AreasService,
				$stateParams, $uibModal, $state, ModalConfirmAction, ModalFeedback, PermissionsService, $timeout) {
    var self = this;	
    
    self.dataLoaded = false;
    self.title;
    self.area;
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};
    var id = $stateParams.id;
    self.isNew = (id=='add-new');
    self.permissions = [];
    
    PermissionsService.getByModuleByUser('areas').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   
   
    function getArea(){
        AreasService.getArea(id).then(function(success){     
            self.title = success.data.area;
            self.area = success.data;
			self.dataLoaded = true;			
        }, function(error){
            console.log(error);
        }); 
    }
    
    if( !self.isNew ){ 
		getArea(); 
	} else {               
		self.dataLoaded = true;
	}
	    
    self.saveRecord = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = AreasService.postStore(self.area);
			message = 'Record created';			
		} else {
			promise = AreasService.postUpdate(id, self.area);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.areaForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.area');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});		
    }
    
    self.deleteRecord = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);
        
        modalInstance.result.then(function (msg) {
            AreasService.getDelete(id).then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.areas', {}, {reload: true});
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
        });
      
    }
    
     
}])
