angular.module('test-emails.controllers', [])

.controller('TestEmailController', ['$state', 'SettingsService', 'BookingsService', '$scope', 'UtilBroadcastService', 
			function($state, SettingsService, BookingsService, $scope, UtilBroadcastService) {
    var self = this;	
	
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};
	self.email_address = '';
	self.booking_id = '';
	
	var config = {};
   
	
	function getDefaultEmail(){
		self.showSaveSpinner = true; 	
		var object = {};
			object.setting = 'email';	
		SettingsService.postBySetting(object).then(function(success){
			self.email_address = success.data.value;				
			/*UtilBroadcastService.showAlert(self, 'Success', 'Email sent successfully.', false, false);		*/
		}, function(error){
			console.log(error);
		});  
	}

	getDefaultEmail();

	function getBookings(){
        /*************Get Booking ***********/
		config.params = {};		
		config.params.orderby = 'bookings.id';
		config.params.sort = 'DESC';
        BookingsService.getBookings(config).then(function(success){
            self.bookings = success.data;
        }, function(error){
			console.log(error);
        });
    }
	
	getBookings();
    
}])
