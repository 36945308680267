angular.module('bookings.controllers')

.controller('BookingSummaryReportController', ['BookingsService', '$timeout', 'REPORT_DETAILS', '$window',
	function(BookingsService, $timeout, REPORT_DETAILS, $window) {
		var self = this;
		self.reportSpinner = false;
		self.report_data = {'data': 'no'};
		self.dataLoaded = false;
		self.dates = {};			
		self.bookings = {};		
		self.report_details = REPORT_DETAILS.reportBookingSummary();
		
		
		
		function defaultDates(){
			self.dates.end_date = moment().format('YYYY-MM-DD');
			self.dates.start_date = moment().subtract(3, 'months').format('YYYY-MM-DD');
			BookingSummaryReport();		
		}
	
		defaultDates();
		
		function BookingSummaryReport(){
			self.dataLoaded = false;
			BookingsService.postSummaryReport(self.dates).then(function(success){
				self.bookings = success.data;
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			BookingSummaryReport();
		}
		
		self.printPDF = function(){			
			self.reportSpinner = true;		
			BookingsService.postSummaryReportPDF(self.dates).then(function(url){
				var printwWindow = $window.open(url);							
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.reportSpinner = false;		
			});			
	
		}
		
		self.printExcel = function(){			
			self.reportSpinner = true;		
			BookingsService.postSummaryReportExcel(self.dates).then(function(url){

				var reportName = "Bookings Summary For " + self.dates.start_date.replace("/","-")+" to "+self.dates.end_date.replace("/","-")
				var hiddenElement = document.createElement('a');	
				hiddenElement.href = url;
				hiddenElement.target = '_blank';
				hiddenElement.download = reportName +'.xlsx';
				document.body.appendChild(hiddenElement);
				hiddenElement.click();
				$timeout(function(){
					hiddenElement.remove();
				}, 50);

				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.reportSpinner = false;		
			});			
		}
		
		
					
	}
])