angular.module('cleanings.controllers', [])

.controller('CleaningsController', ['$state', 'CleaningsService', '$scope', '$filter', 'PermissionsService',
        function($state, CleaningsService, $scope, $filter, PermissionsService) {
    var self = this;

    self.dataLoaded = false;
    self.cleanings = [];
    self.apartments = [];
    self.areas = [];
	self.cleanings.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	
	var config = {
		params: { 	
			page:self.cleanings.current_page, 			
			per_page:30
		}				 
	};

    PermissionsService.getByModuleByUser('cleanings').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});  
	
	function getCleanings(){
		self.dataLoaded = false;
		CleaningsService.getCleaningsPaginate(config).then(function(success){			
			self.cleanings = success.data;       
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
	
	getCleanings();    

    self.go = function(id){
        $state.go('main.cleaning', {'id': id}, {'reload': true});
    }
	
	self.pagination = function(){			
		config.params.page = self.cleanings.current_page;
		getCleanings();	   
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getCleanings();	   
	}
	
	self.search = function(){
		self.cleanings.current_page = 1;
		config.params.page = self.cleanings.current_page;
		config.params.search = self.search_value;
		getCleanings();	   
	}



   
}])