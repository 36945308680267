angular.module('organisations.directives')

	.directive('clientBookings', function ($rootScope, BookingsService) {
		return {
			restrict: 'EAC',
			replace: true,
			scope: {
				clientId: '='
			},
			template: `<div>							
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">								
								<table  class="table"  >
									<tr>
										<th>Dates</th>													
										<th>Guest</th>									
										<th style="width: 10%">Action</th>	
									</tr>
									<tr ng-repeat="item in bookings">
										<td>{{item.check_in_date | date:'dd-MMM-yyyy'}} to {{item.check_out_date | date:'dd-MMM-yyyy'}}</td>										
										<td>{{item.guest}}</td>											
										<td><a href="" 
											ui-sref="main.booking({ id: '{{item.id}}' })" 
											class="btn btn-primary btn-outline btn-sm"><i class="fa fa-binoculars"></i></a></td>
									</tr>						
									
								</table>
							</div>				
						</div>`,
			link: function (scope, element, attr) {

				const id = scope.clientId;
				var config = { params: {} };
				scope.dataLoaded = false;
				scope.bookings = [];

				function getBookings() {
					config.params = {};
					config.params.findby = 'organisation_id';
					config.params.findbyvalue = id;

					BookingsService.getBookings(config).then(function (success) {
						scope.bookings = success.data;
					}, function (error) {
						console.log(error);
					}).finally(function () {
						scope.dataLoaded = true;
					});
				}

				getBookings();

				scope.$on('updateSidebars', function (event, result) {
					getBookings();
				});
			}
		};
	})