angular.module('apartments.directives')

	.directive('apartmentCharges', function ($rootScope, ApartmentsService, ModalFeedback, 
				ModalConfirmAction, $uibModal, $timeout, ModalApartmentCharges) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				apartmentId: '=',
				showAlert: '=',
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>
										<th>Start</th>
										<th>End</th>
										<th>Amount</th>
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>	
									</tr>
									<tr ng-repeat="item in apartment_charges">
										<td>{{item.start_nights}}</td>
										<td>{{item.end_nights}}</td>
										<td>{{item.amount}}</td>
										<td>
											<a href="" ng-click="showApartmentCharge(item)" 
												class="btn btn-primary btn-outline btn-sm"><i class="fa fa-binoculars" aria-hidden="true"></i>
											</a>
										</td>
										<td><a href="" 
											ng-show="permissions['apartments.delete']"
											ng-click="deleteApartmentCharge(item)" 
											class="btn btn-danger btn-outline btn-sm"><i class="fa fa-trash" aria-hidden="true"></i>
											</a></td>
									</tr>									
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['apartments.create']" 
									click-fn="createApartmentCharge()" button-type="add">
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.apartmentId;
				scope.dataLoaded = false;
				scope.apartment_charges = [];	
				var apartmentChargeData;
									
				function updateAlert(value){
					setTimeout(function(){
						scope.$apply(function(){
							scope.showAlert=value;							
						});		
					}, 500);						
				}
				
				function getApartmentCharges(){
					ApartmentsService.getApartmentChargeByApartment(id).then(function(success){     
						scope.apartment_charges = success.data; 						
						updateAlert(scope.apartment_charges.length==0);
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getApartmentCharges();
				
				function broadcastUpdate(){					
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getApartmentCharges();
				});
				
				function showCharge(){
					var modalInstance = $uibModal.open(ModalApartmentCharges.showCharge(apartmentChargeData, scope.permissions));
					
					modalInstance.result.then(function (data) {
						getApartmentCharges();
					});
				}
				
				scope.showApartmentCharge = function(item){
					apartmentChargeData = item;
					showCharge();
					
				}
				
				scope.createApartmentCharge = function(){
					apartmentChargeData = {id: 'new', apartment_id: id};
					showCharge();
				}
				
				
				scope.deleteApartmentCharge = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						ApartmentsService.getApartmentChargeDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();	
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						        
					});
				}
			}
		};
	})