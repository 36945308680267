angular.module('marketing', [])

.factory("MarketingService", [ 
	function(){
	 
	var marketingService = {};

	marketingService.getGroupEmailTypes = function(){
		return ['current_guests_(All)', 
				'current_guests_(By Development)',
				'previous_guests',
				'supply_team',
				'reservations_team',
				'direct_client',
				'potential_client',
				'potential_agent',
				'agent',
				];
	}
		  
	return marketingService;
}])