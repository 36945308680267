angular.module('form-controls.directives')

	.directive('contactDropdown', function (ContactsService, $uibModal, ModalAddContact, ModalShowContact) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				contactId: '=ngModel',
				formName: '=',
				isNew: '=',
				isDisabled: '=',
				showAddButton: '=',
				title: '@'
			},    
			require: 'ngModel',
			template:	`<div class="form-group" ng-class="{'has-error': formName.contact_id.$invalid && formName.contact_id.$touched}">
							<label>{{title}}</label>
							<div ng-class="{'input-group': showAddButton && !isDisabled }">	
								<select class="form-control" name="contact_id" ng-disabled="isDisabled"
									ng-change="getGuestWarnings()"
									ng-model="contactId" 
									ng-options="option.id as option.contact_name for option in contacts" required>
								</select>
								<span class="input-group-btn" ng-show="showAddButton && !isDisabled">
									<button type="button" ng-disabled="isDisabled" class="btn btn-info" ng-click="showContact()"><i class="fa fa-binoculars"></i></button>
									<button type="button" ng-disabled="isDisabled" class="btn btn-success" ng-click="addContact()"><i class="fa fa-plus"></i></button>
								</span>		
							</div>
							<p ng-show="formName.contact_id.$invalid && 
									formName.contact_id.$touched && contactWarnings.length == 0" 
								class="help-block">
								Required
							</p>
							<p ng-show="formName.contact_id.$invalid && 
									formName.contact_id.$touched && contactWarnings.length > 0" 
								class="help-block">								
								You cannot select this guest due to warnings.
							</p>
							<div style="margin-top: 10px;" ng-show="contactWarnings.length > 0">							
								<div  class="alert alert-danger" role="alert">
									<table class="table"  >
										<tr>
											<th>Warning</th>
											<th>Date</th>
											<th>User</th>
											<th>Status</th>										
										</tr>
										<tr ng-repeat="item in contactWarnings">
											<td>{{item.warning}}</td>
											<td>{{item.created_at}}</td>
											<td>{{item.user}}</td>
											<td><span class="badge">{{item.status | capitalize}}</span></td>														   
										</tr>                           
									</table>
								</div>
							</div>							
						</div>`,
			link: function (scope, element, attr) {				
				var config={};
				var newId = -1;
				scope.contacts;
				scope.contactWarnings = [];
				
				function setDefaults(){					
					if (angular.isUndefined(scope.showAddButton)) scope.showAddButton = false;
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = true;
				}
				
				setDefaults();
								
				function getContacts(){
					config.params={};
					config.params.orderby = 'contacts.contact_name';
					config.params.dropdown = true;
					if(scope.isNew){
						config.params.findby = 'status';
						config.params.findbyvalue = 'active';
					}
					
					ContactsService.getContacts(config).then(function(success){    				
						scope.contacts = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						if(newId>0) scope.contactId = newId;
						newId = -1;
					});  				
				}
				
				getContacts();
				
				scope.addContact= function(){
					var modalInstance = $uibModal.open( ModalAddContact.addContact() );
					
					modalInstance.result.then(function (data) {
						newId = data;
						getContacts();				
						modalInstance.close('cancel');                  
					});
				}

				scope.showContact = function(){
					ContactsService.getContact(scope.contactId).then(function(success){
						var modalInstance = $uibModal.open( ModalShowContact.showContact(success.data) );
					
						modalInstance.result.then(function () {			
							modalInstance.close('cancel');                  
						});
					}, function(error){
						console.log(error);
					});
				}
				
				scope.getGuestWarnings = function(){
					config.params = {};	
					config.params.findby = 'contact_id';
					config.params.findbyvalue = scope.contactId;
					config.params.status = 'active';
					ContactsService.getContactWarnings(config).then(function(success){     
						scope.contactWarnings = success.data;  				
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.formName.contact_id.$setValidity("warnings", scope.contactWarnings.length == 0);					
					});
				}	

				scope.$watch('contactId', function(oldVal, newVal){
					if(oldVal != newVal && angular.isNumber(scope.contactId)){
						scope.getGuestWarnings();
					}
					
				});		
			}
		};
	})