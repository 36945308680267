angular.module('angular-utils.directives')

.directive('confirmOnExit', function() {
	return {
		link: function($scope, elem, attrs, ctrl) {
			if($scope[attrs["name"]] != null){
				window.onbeforeunload = function(){
					if ($scope[attrs["name"]].$dirty) {
						return "Your edits will be lost.";
					}
				}
				
				$scope.$on('$stateChangeStart', function(event, next, current) {
					if ($scope[attrs["name"]].$dirty) {
						if(!confirm("This page is asking you to confirm that you want to leave - data you have entered may not be saved")) {
							event.preventDefault();
						}
					}
				});
			}
			
		}
	};
})

.directive('isolateForm', function() {
	return {
	  restrict: 'A',
	  require: '?form',
	  link: function(scope, element, attrs, formController) {
		if (!formController) {
		  return;
		}
  
		var parentForm = formController.$$parentForm; // Note this uses private API
		if (!parentForm) {
		  return;
		}
  
		// Remove this form from parent controller
		parentForm.$removeControl(formController);
	  }
	};
  })