angular.module('actionslog.directives', [])

	.directive('actionsLogSidebar', function ($rootScope, ActionsLogService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				type: '@type',
				id: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<table ng-show="dataLoaded" class="table table-striped table-hover clickable"  >
								<tr>
									<th>Name</th>								
									<th>Description</th>
									<th>Date</th>
								</tr>
								<tr class="table-row" ui-sref="main.actionslog({ id: '{{item.id}}' })" 
									ng-repeat="item in logs">
									<td><b>{{item.name}}</b></td>								
									<td>{{item.description}}</td>
									<td>{{item.created_at | fullDateFormatted}}</td>															
								</tr>							
							</table>
						</div>`,
			link: function (scope, element, attr) {
				
				scope.dataLoaded = false;	
				scope.logs = [];
				
				/*************GetLogs ***********/
				function getLogs(){
					scope.dataLoaded = false;
					ActionsLogService.getLogsByObject(scope.type, scope.id).then(function(success){				
						scope.logs = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;	
					});
				}
				
				getLogs();  

				scope.$on('updateSidebars', function (event, result) {					
					getLogs();
				});
				
			}
		};
	})