angular.module('issues.services', [])

.factory("IssuesService", ['$http',
	function($http){
	 
	var issuesService = {};
	
	/* Issues */

	issuesService.getIssues = function(config){
		return $http.get('api/issue', config == null ? [] : config);
	}
	
	issuesService.getMyIssues = function(){
		return $http.get('api/issue/my-issues');
	}
	

	issuesService.getIssuesPaginate = function(config){
		return $http.get('api/issue/paginate', config == null ? [] : config);
	}
		   
	issuesService.postStore = function(issue){            
		return $http.post('api/issue/store', issue);
	}
	
	issuesService.getShow = function(id){
		return $http.get('api/issue/show/'+id);
	}

	issuesService.postUpdate = function(id, issue){            
		return $http.post('api/issue/update/'+id, issue);
	}

	issuesService.getDestroy = function(id){
		return $http.get('api/issue/destroy/'+id);
	}
	
	issuesService.getStatusForIssues = function(){            
		return ['new', 'in progress', 'completed'];
	}
	
	issuesService.getTypesForIssues = function(){            
		return ['red', 'amber', 'green'];
	}

	issuesService.getRelatedToForIssues = function(){            
		return ['third party', 'guest related', 'in house', 'other'];
	}

	issuesService.getFocusForIssues = function(){            
		return ['apartment', 'development', 'other'];
	}
	
	issuesService.getIssuesOpenReport = function(config, type){
		return $http.get('api/issue/open-report/'+type, config == null ? [] : config);
	}
	
	/* Issue Details */
	
	issuesService.getDetailByIssue = function(issue_id){
		return $http.get('api/issue-detail/by-issue/'+issue_id);
	}
	 
	issuesService.postDetailStore = function(issue_detail){            
		return $http.post('api/issue-detail/store', issue_detail);
	}
	
	issuesService.getDetailShow = function(id){
		return $http.get('api/issue-detail/show/'+id);
	}




	issuesService.getIssueAttachment = function(config){
		return $http.get('api/attachment', config);
	}


	
	/* Drop Downs */
	issuesService.getApartments = function(){            
		return $http.get('api/apartment/for-dropdown');
	}
	
	/* Issue-Subcontractors*/
	
	issuesService.getIssueSubcontractors = function(config){
		return $http.get('api/issue-subcontractor', config == null ? [] : config);
	}	

	issuesService.getIssuesByOrganisation = function(organisation_id){
		return $http.get('api/issue-subcontractor/by-organisation/'+organisation_id);
	}
		  
		   
	issuesService.postIssueSubcontractorsStore = function(issue_subcontractor){            
		return $http.post('api/issue-subcontractor/store', issue_subcontractor);
	}
	

	issuesService.getIssueSubcontractorsDestroy = function(id){
		return $http.get('api/issue-subcontractor/destroy/'+id);
	}

	issuesService.getDueByDate = function(issue_response_times, type){
		var hoursToAdd = 0;
		hoursToAdd = this.getHoursToAdd(issue_response_times, type);				
		var now = moment().add(hoursToAdd, 'h');;
		return  now.format("DD/MM/YYYY HH:mm:ss");
	}

	issuesService.getHoursToAdd = function(issue_response_times, type){
		var returnVal = 0;
		var str = 'issue_response_times_'+type;				
		angular.forEach(issue_response_times, function(value, key){
			if(value.setting==str) returnVal = value.value;				
		});
		return returnVal;
	}

	/*
     *             Issue Attachment
     */
    
    issuesService.getIssueAttachmentByIssue = function(id){            
        return $http.get('api/issue-attachments/by-issue/'+id);
    }
    
    issuesService.postIssueAttachmentUpdate = function(id, data){            
        return $http.post('api/issue-attachments/update/'+id, data);
    }
    
    issuesService.postIssueAttachmentStore = function(data){            
        return $http.post('api/issue-attachments/store', data);
    }
    
    issuesService.getIssueAttachmentDelete = function(id){            
        return $http.get('api/issue-attachments/destroy/'+id);
    }
	
		  
	return issuesService;
}])