angular.module('data-clean-up.controllers', [])

.controller('DataCleanUpController', ['ContactsService', 'DataCleanUpService', '$timeout', 
			'PermissionsService', '$uibModal', 'ModalConfirmAction', 'ModalFeedback', 'OrganisationsService',
	function(ContactsService, DataCleanUpService, $timeout, 
		PermissionsService, $uibModal, ModalConfirmAction, ModalFeedback, OrganisationsService) {

	var self = this;
	self.contacts = [];
	self.organisations = [];
	var config = {};
	config.params = {};
	self.showAlert = false;
	self.alertDetails = {};
	self.showAlertOrg = false;
	self.alertDetailsOrg = {};
	self.permissions = [];
	
	function getPermissions(){
		PermissionsService.getByModuleByUser('contacts|organisations').then(function(success){   			
			self.permissions = success.data;
		}, function(error){
			console.log(error);
		});  
	}
	
	getPermissions();
	
	function getContacts(){
		config.params = {};
		config.params.orderby = 'contacts.contact_name';
		config.params.dropdown = true;
		ContactsService.getContacts(config).then(function(success){
			self.contacts = success.data;
		}, function(error){
			console.log(error);
		});
	}
	
	getContacts();
	
	function getOrganisations(){
		config.params = {};
		config.params.orderby = 'organisations.organisation_name';
		config.params.dropdown = true;
		OrganisationsService.getOrganisations(config).then(function(success){
			self.organisations = success.data;			
		}, function(error){
			console.log(error);
		});
	}
	
	getOrganisations();
	
	function showAlert(type, message, id = false){
		self.showSaveSpinner = false;
		self.showAlert = true;
		self.alertDetails.type = type;
		self.alertDetails.message = message;                       
		
		$timeout(function() {
			self.showAlert = false;				
		}, 2000);
	}
	
	self.updateOrganisationInformation = function(){
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);
		
		modalInstance.result.then(function (msg) {
			var object = {};			
			object.organisationIdToKeep = self.organisationToKeep.id;
			object.organisationIdToDelete = self.organisationToDelete.id;
			
			self.showSaveSpinnerOrg = true; 
			DataCleanUpService.postOrganisationCleanUp(object).then(function(success){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
					$timeout(function() { 
						modalInstance1.close('cancel'); 						
					}, 2000);
				getOrganisations();				
				self.organisationToDelete = null;					
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			}).finally(function(){
				self.showSaveSpinnerOrg = false; 
			});			
		});
	}
	
	self.updateContactInformation = function(){
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);
		
		modalInstance.result.then(function (msg) {
			var object = {};			
			object.contactIdToKeep = self.contactToKeep.id;
			object.contactIdToDelete = self.contactToDelete.id;
			
			self.showSaveSpinner = true; 
			DataCleanUpService.postContactCleanUp(object).then(function(success){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 					
				}, 2000);	
				getContacts();				
				self.contactToDelete = null;
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			}).finally(function(){
				self.showSaveSpinner = false; 
			});
		});
	}
	
}])

