angular.module('apartment-details-defaults.controllers')
    
.controller('ApartmentDetailsDefaultController', ['$scope', 
		'ApartmentDetailsDefaultsService', '$stateParams', '$uibModal', 
		'$state', 'PermissionsService', 'ModalConfirmAction', 'ModalFeedback', '$timeout', 'UtilBroadcastService',
	function($scope, ApartmentDetailsDefaultsService, $stateParams, $uibModal, 
			$state, PermissionsService, ModalConfirmAction, ModalFeedback, $timeout, UtilBroadcastService) {
		var self = this;
		var id = $stateParams.id;
		self.dataLoaded = false;
		self.isNew = (id=='add-new');           
		self.apartmentDetailDefault={};
		self.showSaveSpinner = false;			
		self.showAlert = false;
		self.alertDetails = {};
	   
				   
		PermissionsService.getByModuleByUser('apartments').then(function(success){   			
			self.permissions = success.data;
		}, function(error){
			console.log(error);
		});          

		function getApartmentDetailDefault(){
			ApartmentDetailsDefaultsService.getApartmentDetailsDefault(id).then(function(success){                    
				self.apartmentDetailDefault = success.data;
				self.dataLoaded = true;
			}, function(error){
				console.log(error);
			});
		}
		
		if( !self.isNew ){ 
			getApartmentDetailDefault(); 
		} else {               
			self.dataLoaded = true;
		}
		
		          
			
		self.addToAllApartments = function(){
			
			var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
				'Confirm', 
				'Are you sure you would like to add this default to all apartments?', 
				self.permissions)
			);
			
			modalInstance.result.then(function (msg) {
				
				ApartmentDetailsDefaultsService
					.getAddDefaultToAllApartments(id)
					.then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
								'Success',
								'Records created!',
								'alert-success'
							)
						);						
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}, function(error){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
								'Error!',
								'Unable to create record. Please contact a system administrator.',
								'alert-danger'
							)
						);			
						$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					});		
			});
			
		}

		
		
		self.saveRecord = function(){
			self.showSaveSpinner = true; 
			var promise; var message;	
			if( self.isNew ){
				self.showSaveSpinner = true; 
				promise = ApartmentDetailsDefaultsService.postStore(self.apartmentDetailDefault);
				message = 'Record created';			
			} else {
				promise = ApartmentDetailsDefaultsService.postUpdate(id, self.apartmentDetailDefault);
				message = 'Record updated';			
			}
			
			promise.then(function(success){
				var newId=false;
				if(self.isNew) newId = success.data;
				$scope.apartmentDetailDefaultForm.$setPristine();				
				UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.apartment-details-default');											   
			}, function(error){
				console.log(error);
				UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
			}).finally(function(){
				UtilBroadcastService.broadcastUpdate();
			});		
		}

		self.deleteRecord = function() {		

			var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!', 
					'Are you sure you would like to delete this record? Deletions cannot be undone.', 
					'alert-danger')
				);

			modalInstance.result.then(function (msg) {
				ApartmentDetailsDefaultsService.getDelete(id).then(function (success) {
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
					$timeout(function() { 						
						modalInstance1.close('cancel'); 
						$state.go('main.apartment-details-defaults', {}, {reload: true});
					}, 2000);				
				}, function(error){
					console.log(error);
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
								'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
					$timeout(function() { modalInstance1.close('cancel'); }, 2000);
				});
			});
		}
}])
