angular.module('apartments.controllers')   

.controller('ApartmentController', ['$scope', 'ApartmentsService', 'DevelopmentsService', '$stateParams', '$uibModal', 
	'$state', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', 'GoogleMapsService', '$timeout', 'UtilBroadcastService',
	function($scope, ApartmentsService, DevelopmentsService, $stateParams, $uibModal, 
		$state, ModalConfirmAction, ModalFeedback, PermissionsService, GoogleMapsService, $timeout, UtilBroadcastService) {
		
	var self = this;
	var id = $stateParams.id;
	self.dataLoaded = false;
	self.showSaveSpinner = false;			
	self.showAlert = false;
	self.alertDetails = {};
	self.isNew = (id=='add-new');
	self.permissions = [];
	self.apartment={};			
	self.show_apartment_details_alert = false;	
	self.show_apartment_charges_alert = false;	
	self.show_apartment_contracts_alert = false;	
	self.apartment_states = ApartmentsService.getStatusForApartments();
	self.apartment_types = ApartmentsService.getTypesForApartments();
	
	var config={params: {}};
	
	PermissionsService.getByModuleByUser('apartments|bookings|issues').then(function(success){   			
		self.permissions = success.data;				
	}, function(error){
		console.log(error);
	}).finally(function(){
		if(self.isNew){   
			setDefaultValues();
		}
	});	

	function getApartment(){
		ApartmentsService.getApartment(id).then(function(success){                    
			self.apartment = success.data;
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
	
	function getDevelopments(){
		config.params = {};	
		config = {	params: { 	orderby: 'developments.name', dropdown: true, status: 'active'	}  };	
		DevelopmentsService.getDevelopments(config).then(function(success){     
			self.developments = success.data;                
		}, function(error){
			console.log(error);
		});
	}
	
	getDevelopments();
	
	
	if( !self.isNew ){ 
		getApartment(); 
	} 
	
	function setDefaultValues(){      
		      
		self.apartment.hand_over_completed = 0;
		self.apartment.key_code = '1234';
		
		if(!self.permissions['channel-manager.integration']){			
			self.apartment.max_occupancy = 2;			
			self.apartment.description = 'Generic Description';
			self.apartment.default_room_rate = 100;
		}

		self.dataLoaded = true;	
		
	}	
	
	
	self.getGeocode = function(){
		GoogleMapsService.getGeocode(self.apartment.postcode).then(function(success){
			self.apartment.lat = success.data.results[0].geometry.location.lat;
			self.apartment.lng = success.data.results[0].geometry.location.lng;
		}, function(error){
			console.log(error);
		});
	}
	
	self.updateDevelopment = function(){
		var modalInstance = $uibModal.open({
			animation: true,                  
			controller: 'modalListController as modal',   
			templateUrl: 'templates/modals/modal-list.html',					
			resolve: {
				data: function () {
				  return self.developments;
				},
				property_name: function(){
					return 'name';
				},
				title: function(){
					return "Developments"
				}
			}
		});
		
		modalInstance.result.then(function (data) {			
			self.apartment.development_id = data.id;
			self.apartment.development = data.name;
			modalInstance.close('cancel');                  
		});
	}
	
	self.saveRecord = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = ApartmentsService.postStore(self.apartment);
			message = 'Record created';			
		} else {
			promise = ApartmentsService.postUpdate(id, self.apartment);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.apartmentForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.apartment');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});		
	}

	self.deleteRecord = function() {		

		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
				'Warning!', 
				'Are you sure you would like to delete this record? Deletions cannot be undone.', 
				'alert-danger')
			);

		modalInstance.result.then(function (msg) {
			ApartmentsService.getDelete(id).then(function (success) {
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
					$timeout(function() { 
						modalInstance1.close('cancel'); 
						$state.go('main.apartments', {}, {reload: true});
					}, 2000);				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
		});
	}
}])