angular.module('modal-configuration', [])

    .factory("ModalConfigurationService", ['$uibModal', function ($uibModal) {

        var modalConfigurationService = {};

        modalConfigurationService.getInvoiceTemplate = function (data, permissions) {
            return $uibModal.open({
                templateUrl: 'invoice-modals/modal-invoice-template.html',
                animation: true,
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Invoice Template"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalInvoiceTemplatesController'
               
            });
        };

        modalConfigurationService.getSchedulerBooking = function (data, permissions) {
            return $uibModal.open({
                animation: true,     
			    windowClass: 'app-modal-window',
                templateUrl: 'schedulers/modals/modal-scheduler-booking.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Booking"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalSchedulerBookingController'
               
            });
        };

        modalConfigurationService.getApartmentAttachment = function (data, permissions) {
            return $uibModal.open({
                animation: true,     			   
                templateUrl: 'apartments/modals/modal-apartment-attachment-form.html',
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Apartment Attachment"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalApartmentAttachmentController'
               
            });
        };

        modalConfigurationService.getBookingAttachment = function (data, permissions) {
            return $uibModal.open({
                animation: true,     			   
                templateUrl: 'bookings/modals/modal-booking-attachment-form.html',
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Booking Attachment"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalBookingAttachmentController'
               
            });
        };

        modalConfigurationService.getDevelopmentAttachment = function (data, permissions) {
            return $uibModal.open({
                animation: true,                   
                templateUrl: 'developments/modals/modal-development-attachment-form.html',
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Development Attachment"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalDevelopmentAttachmentController'
               
            });
        };


        modalConfigurationService.getInvoicePayment = function (data, permissions, amount_due) {
         
            return $uibModal.open({
                animation: true,     
                templateUrl: 'invoices/modals/modal-invoice-payment.html',
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Add/View Invoice Payment"
                    },
                    permissions: function () {
                        return permissions;
                    },
                    amount_due: function(){
                        return amount_due;
                    }
                },
                controller: 'ModalInvoicePaymentsController'
               
            });
        };

        modalConfigurationService.channelManagerDevelopmentView = function (data, permissions, prefix) {
            return $uibModal.open({
                animation: true,     
			    windowClass: 'app-modal-window',
                templateUrl: 'channel-manager/modals/modal-cm-development-view.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Development"
                    },
                    prefix: function() {
                        return prefix;
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalCmDevelopmentViewController'
               
            });
        };

        modalConfigurationService.channelManagerRoomTypeView = function (data, permissions) {
            return $uibModal.open({
                animation: true,     
                templateUrl: 'channel-manager/modals/modal-cm-apartment-view.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    title: function () {
                        return "Add Room Type"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalCmApartmentViewController'
               
            });
        };

    

        modalConfigurationService.channelManagerRatePlanView = function (data, permissions) {
            return $uibModal.open({
                animation: true,     
                templateUrl: 'channel-manager/modals/modal-cm-rate-plan-view.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    title: function () {
                        return "Add Rate Plan"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalCmRatePlanViewController'
               
            });
        };


        modalConfigurationService.channelManagerAvailabilityView = function (data, apartments, rate_plans, permissions) {
            return $uibModal.open({
                animation: true,     
                templateUrl: 'channel-manager/modals/modal-cm-availability-view.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    apartments: function(){
                        return apartments;
                    },
                    ratePlans: function(){
                        return rate_plans;
                    },
                    title: function () {
                        return "Add Availability"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalCmAvailabilityViewController'
               
            });
        };

        modalConfigurationService.channelManagerCreateBookingView = function (data, permissions) {
            return $uibModal.open({
                animation: true,     
                templateUrl: 'channel-manager/modals/modal-cm-create-booking-view.html',
                resolve: {
                    data: function () {                       
                        return data;
                    },
                    title: function () {
                        return "Create Booking"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalCmCreateBookingController'
               
            });
        };


        modalConfigurationService.getContactOrganisation = function (data, permissions) {
            return $uibModal.open({
                animation: true,                   
                templateUrl: 'organisations/modals/modal-contact-organisation-form.html',
                resolve: {
                    data: function () {
                        return data;
                    },
                    title: function () {
                        return "Add/Edit Contact for Organisation"
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalContactOrganisationController'
               
            });
        };


        modalConfigurationService.unsentInvoicesByBooking = function ( permissions, id ) {
            return $uibModal.open({
                animation: true,     
                windowClass: 'app-modal-window',
                templateUrl: 'bookings/modals/modal-unsent-invoices-by-booking.html',
                resolve: {
                    title: function () {
                        return "Unsent Invoices"
                    },
                    id: function (){
                        return id;
                    },
                    permissions: function () {
                        return permissions;
                    }
                },
                controller: 'ModalUnsentInvoicesByInvoiceController'
               
            });
        };


        return modalConfigurationService;
    }
])


