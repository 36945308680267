angular.module('invoices.controllers')

.controller('InvoiceDetailedDateToDateReportController', ['InvoicesService', '$timeout',
    function(InvoicesService, $timeout) {
        var self = this; 		
		self.dataLoaded = false;
		self.showSpinner = false;		
		self.dates = {};
		self.invoices = [];
		
		
		console.log('InvoiceDetailedDateToDateReportController');
		
		function defaultDates(){
			var now = moment(); 
			self.dates.end_date = now.format('YYYY-MM-DD');
			self.dates.start_date = moment().year(now.year()).month(now.month()-1).date(now.date()).format('YYYY-MM-DD');	
			InvoiceDetailedReport();
		}
	
		defaultDates();
		
		function InvoiceDetailedReport(){
			self.dataLoaded = false;
			console.log('InvoiceDetailedReport');
			InvoicesService.postInvoiceDetailedReportDateToDate(self.dates).then(function(success){
				self.invoices = success.data.data;				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			InvoiceDetailedReport();			
		}		
		
		
		self.sendToXLS = function(){			
			self.reportSpinner = true;		
			InvoicesService.postInvoiceDetailedReportExcel(self.dates).then(function(url){
				
				var reportName = "Detailed Invoices For " + self.dates.start_date.replace("/","-")+" to "+self.dates.end_date.replace("/","-")
				var hiddenElement = document.createElement('a');	
				hiddenElement.href = url;
				hiddenElement.target = '_blank';
				hiddenElement.download = reportName +'.xlsx';
				document.body.appendChild(hiddenElement);
				hiddenElement.click();
				$timeout(function(){
					hiddenElement.remove();
				}, 50);

				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.reportSpinner = false;		
			});			
		}
		
	}
])