(function() {
	
	window.ui = window.ui || {};
	
	var BookingStrip = function (booking_id, show_booking_start, contact, xValCheckIn, yVal, color, borderColor, width, showStart, showEnd) {
		this.booking_id = booking_id;
		this.show_booking_start = show_booking_start;
		this.contact = contact;
		this.xValCheckIn=xValCheckIn;		
		this.yVal=yVal;
		this.color=color;
		this.borderColor=borderColor;
		this.width=width;
		this.showStart=showStart;		
		this.showEnd=showEnd;		
		this.initialize();		
	}
	
	var b = BookingStrip.prototype = new createjs.Container();
	
	b.booking_id;
	b.show_booking_start;
	b.contact;
	b.xValCheckIn;
	b.yVal;
	b.color;
	b.borderColor;
	b.width;
	b.showStart;
	b.showEnd;
	
	b.Container_initialize = b.initialize;
	
	b.initialize = function () {
		
		this.Container_initialize();
		this.createBookingStrip();
		this.setButtonListeners();		
	}
	
	
	
	b.setButtonListeners = function (){
		this.cursor = 'pointer';			
	}
	
	b.createBookingStrip = function(){	
		this.addRectangle();		
		
		if(this.showStart && this.show_booking_start=='1'){
			this.addStart();
		}
		if(this.showEnd){
			this.addEnd();
		}
		this.addContact();
				
	}	
	
	b.addRectangle = function(){
		var shape = new createjs.Shape();	
		var outline = new createjs.Shape();		
		var startRound=0;
		var endRound=0;
		var borderWidth = 2;
		
		shape.graphics.beginFill(this.color).drawRect(this.xValCheckIn, this.yVal-5, this.width, 25);		
		shape.shadow = new createjs.Shadow("#000000", -3, 3, 10);

		var line = new createjs.Shape();                   
		line.graphics.setStrokeStyle(borderWidth).beginStroke(this.borderColor);
		line.graphics.moveTo(this.xValCheckIn, this.yVal-5); 
		line.graphics.lineTo(this.xValCheckIn + this.width, this.yVal-5);
		line.graphics.endStroke();
		

		var line1 = new createjs.Shape();                   
		line1.graphics.setStrokeStyle(borderWidth).beginStroke(this.borderColor);
		line1.graphics.moveTo(this.xValCheckIn, this.yVal+20); 
		line1.graphics.lineTo(this.xValCheckIn + this.width, this.yVal+20);
		line1.graphics.endStroke();
		
			
		this.addChild(shape);	
		this.addChild(line);
		this.addChild(line1);
		
	}
	
	b.addContact = function(){
		var text = this.contact;
		var offset = 20;
		var label = new createjs.Text(text, "12px Arial", "#000");
		label.maxWidth = this.width-offset;
		label.name = 'label'; label.textAlign = 'left';   label.x = this.xValCheckIn+offset;  label.y = this.yVal+2;                    
		this.addChild(label);
	}
	
	b.addStart = function(){
		
		var circle = new createjs.Shape();		
		circle.graphics.beginFill(this.color).drawCircle(0, 0, 15);
		circle.x = this.xValCheckIn;
		circle.y = this.yVal+7;	
		circle.shadow = new createjs.Shadow("#000000", -3, 3, 10);	
		this.addChild(circle);
		
		var circleOutline = new createjs.Shape();
		circleOutline.graphics.setStrokeStyle(3).beginStroke('#000').drawCircle(0, 0, 15);
		circleOutline.x = this.xValCheckIn;
		circleOutline.y = this.yVal+7;		
		this.addChild(circleOutline);
	}

	b.addEnd = function(){
		var borderWidth = 2;
		var circle = new createjs.Shape();
		circle.graphics.beginFill(this.color).drawCircle(0, 0, 15);
		circle.x = this.xValCheckIn+this.width;
		circle.y = this.yVal+7.5;		
		circle.shadow = new createjs.Shadow("#000000", 3, 3, 15);
		this.addChild(circle);

		var outline = new createjs.Shape();
		outline.graphics.setStrokeStyle(borderWidth).beginStroke(this.borderColor).drawCircle(0, 0, 15);
		outline.x = this.xValCheckIn+this.width;
		outline.y = this.yVal+7.5;		
		this.addChild(outline);

		
	}

	window.ui.BookingStrip = BookingStrip;
	
}());