angular.module('apartments.controllers', [])

.controller('ApartmentsController', ['$state', 'ApartmentsService', '$scope', '$filter', 'PermissionsService',
	function($state, ApartmentsService, $scope, $filter, PermissionsService) {
	var self = this;
	
	self.dataLoaded = false;
	self.report_data = {'data': 'no'};
	self.apartments = [];
	self.apartments.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	self.apartment_states = ApartmentsService.getStatusForApartments();
	self.status = 'active';
	self.errorMessage = '';
	
	var config = {
		params: { 	
			page:self.apartments.current_page, 	
			orderby: 'apartments.address1',
			per_page:30,
			status: self.status
		}				 
	};
	
	PermissionsService.getByModuleByUser('apartments').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
	
	
	function getApartments(){
		self.dataLoaded = false;
		
		config.params.status = self.status;
		ApartmentsService.getApartmentsPaginate(config).then(function(success){		
			self.apartments = success.data;				
			self.dataLoaded = true;
			self.showSpinner = false;
		}, function(error){
			self.errorMessage = error.data.msg;			
		});
	}
	
	getApartments();
	
	self.updateStatus = function(status){
		self.showSpinner = true;
		self.status = status;
		getApartments();
	}
	

	self.go = function(id){			
		$state.go('main.apartment', {'id': id}, {});
	}
	
	self.pagination = function(){			
		config.params.page = self.apartments.current_page;
		getApartments();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getApartments();
	   
	}
	
	self.search = function(){
		self.apartments.current_page = 1;
		config.params.page = self.apartments.current_page;
		config.params.search = self.search_value;
		getApartments();
	}
	
	self.print = function(item) {
		item.reportSpinner = true;
		ApartmentsService.getApartmentDetailsReport(item.id).then(function(success) { 
			self.report_data= {
				"template": { "shortid" : "NyfOL7E7-" },
				"title": "Apartment Details",
				"data": success.data
			};                
		}, function(error){
			console.log(error);
		}).finally(function() {
			item.reportSpinner = false;
		});
		  
	}

}])