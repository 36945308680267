angular.module('users.controllers')

.controller('UserReportsController', ['UsersService', '$localstorage', '$state',
	function(UsersService, $localstorage, $state) {
		var self = this;

		self.report_data = {};
		self.users = [];

		UsersService.getUsers().then(function(success){
			self.users = success.data;
		}, function(error){
			console.log(error);
		});

		self.goToSummary = function(){
			self.report_data= {
				"template": { "shortid" : "Vy7bUTmhe" },
				"title": "A Title",
				"data": { }
			};
			self.report_data.data.users = self.users;
			$localstorage.setObject('report_data', self.report_data);
			$state.go('main.reports');
		};

		self.goToDetail = function(user) {
			self.report_data= {
				"template": { "shortid" : "E1w4xim3l" },
				"title": "A Title",
				"data": user
			};

			$localstorage.setObject('report_data', self.report_data);
			$state.go('main.reports');
		}
	}
])