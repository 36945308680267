angular.module('apartments.directives')

	.directive('apartmentHandOver', function ($rootScope, ApartmentsService, ModalFeedback, 
				ModalConfirmAction, $uibModal, $timeout, ModalApartmentHandOver, AttachmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				apartmentId: '=',					
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table">									
									<tr>
										<th>Title</th>
										<th>Start</th>                                
										<th>End</th>
										<th>Attachment</th>
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>	
									</tr>
									<tr ng-repeat="item in hand_overs">
										<td>{{item.title}}</td>
										<td>{{item.start_date | date:shortDate}}</td>
										<td>{{item.end_date | date:shortDate}}</td>
										<td><a href="" ng-click="openAttachment(item)">{{item.attachment}}</a></td>
										<td><my-sub-form-button click-fn="showApartmentHandOver(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['apartments.update']" click-fn="deleteApartmentHandOver(item)" button-type="delete"></my-sub-form-button></td>                                
									</tr>									
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['apartments.create']" 
									click-fn="createApartmentHandOver()" button-type="add">
								</div>
							</div>
						
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.apartmentId;
				var apartmentHandOverData;
				scope.dataLoaded = false;
				scope.hand_overs = [];									
					
				scope.dataLoaded = true;

				function getApartmentHandOvers(){					
					scope.dataLoaded = false;
					ApartmentsService.getApartmentHandOverByApartment(id).then(function(success){     
						scope.hand_overs = success.data;    						
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getApartmentHandOvers();
				
				function broadcastUpdate(){
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getApartmentHandOvers();
				});

				scope.openAttachment = function(item){
					var params = {};
					params.disk = 'hand-over-attachments';
					params.item_id = item.apartment_id;
					params.file = item.attachment;
					
					AttachmentsService.getAttachment(params).then(function(url){
						var hiddenElement = document.createElement('a');
	
						hiddenElement.href = url;
						hiddenElement.target = '_blank';
						hiddenElement.download = item.attachment;
						document.body.appendChild(hiddenElement);
						hiddenElement.click();
						$timeout(function(){
							hiddenElement.remove();
						}, 50);
					});
				}
				
				function showApartmentHandOver(){
					var modalInstance = $uibModal.open(ModalApartmentHandOver.show(apartmentHandOverData, scope.permissions));					
					modalInstance.result.then(function (data) {
						getApartmentHandOvers();     
					});
				}
				
				scope.showApartmentHandOver = function(item){
					apartmentHandOverData = item;
					showApartmentHandOver();					
				}
				
				scope.createApartmentHandOver = function(){
					apartmentHandOverData = {id: 'new', apartment_id: id};
					showApartmentHandOver();
				}
				
				scope.deleteApartmentHandOver = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						ApartmentsService.getApartmentHandOverDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();								
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});						      
					});
				}
			}
		};
	})