angular.module('areas.directives', [])

	.directive('areaDevelopments', function (DevelopmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				areaId: '='			
			},      
			template:	`<div class="panel-body">				
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>					
							<div ng-show="dataLoaded">
								<div  class="list-group">							
									<a href="#" ui-sref="main.development({ id: '{{item.id}}' })" ng-repeat="item in developments" class="list-group-item">																		
											<i class="fa fa-bank"></i> {{item.name}} <span class="badge">{{item.postcode}}</span>	 												
									</a>                                   
								</div>                                
							</div>    
						</div>`,
			link: function (scope, element, attr) {
				const id = scope.areaId;				
				var config={params: {}};
				scope.dataLoaded = false;
				scope.developments = [];	
								
				function getDevelopments(){									
					config.params = {};	
					config = {	params: { 	findby: 'area_id', findbyvalue: id, status: 'active'	}  };		
					DevelopmentsService.getDevelopments(config).then(function(success){     
						scope.developments = success.data; 
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;						
					});
				}
				
				getDevelopments();
								
				scope.$on('updateSidebars', function (event, result) {		
					getDevelopments();						
				});			
			}
		};
	})