angular.module('apartments.modals')

.factory("ModalApartmentContract", [function(){
	 
	var modalApartmentContract = {};          
	
	
	modalApartmentContract.show = function(data, permissions){ 
		return {
                template: 
				`<form name="apartmentContractsForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Contracts</h3>
					</div>
					<div class="modal-body default">  				
						 <input ng-focus="focus=false" ng-blur="focus=true" type="hidden" class="form-control" name="id"
								   ng-model="modal.data.id" readonly="" />

						<div class="form-group" ng-class="{'has-error': apartmentContractsForm.type.$invalid && apartmentContractsForm.type.$touched}">
							<label>Type</label>            
							<select class="form-control" name="type" ng-model="data.type"
								ng-disabled="!permissions['apartments.update']" required>
										<option value="fixed term">Fixed Term</option>
										<option value="rolling">Rolling</option>                        
							</select>
							<p ng-show="apartmentContractsForm.type.$invalid && apartmentContractsForm.type.$touched" class="help-block">
								Required
							</p>
						</div>
						
						<organisation-dropdown
							ng-model="data.organisation_id"
							form-name="apartmentContractsForm" 
							is-new="true"
							show-add-button="false"
							is-disabled="false"
							title="Managed By">
						</organisation-dropdown>
						
						<div class="form-group" ng-class="{'has-error': apartmentContractsForm.rent.$invalid && apartmentContractsForm.rent.$touched}">
							<label>Rent</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="rent"
							ng-readonly="!permissions['apartments.update']"
								   ng-model="data.rent" required/>            
							<p ng-show="apartmentContractsForm.rent.$invalid && apartmentContractsForm.rent.$touched" class="help-block">
								Required
							</p>
						</div>
						
						<div class="form-group" ng-class="{'has-error': apartmentContractsForm.start_date.$invalid && apartmentContractsForm.start_date.$touched}">
							<label>Start Date</label>
							<p class="input-group">
							<input ng-model="data.start_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
								   is-open="start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
								   ng-readonly="!permissions['apartments.update']"
								   datepicker-localdate ng-model-options="{timezone: 'utc'}"
								   close-text="Close" alt-input-formats="altInputFormats" 
								   ng-focus="focus=false" ng-blur="focus=true" name="start_date" required/>
							<span class="input-group-btn">
								<button type="button" ng-disabled="!permissions['apartments.update']" class="btn btn-default" ng-click="start_date_open = !start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
							</span>
							</p>
							<p ng-show="apartmentContractsForm.start_date.$invalid && apartmentContractsForm.start_date.$touched" class="help-block">
								Required
							</p>
						</div>
						
						<div class="form-group" ng-class="{'has-error': apartmentContractsForm.end_date.$invalid && apartmentContractsForm.end_date.$touched}">
							<label>End Date</label>
							<p class="input-group">
							<input ng-model="data.end_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
									ng-readonly="!permissions['apartments.update']"
								   is-open="end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
								   close-text="Close" alt-input-formats="altInputFormats" 
								   datepicker-localdate ng-model-options="{timezone: 'utc'}"
								   ng-focus="focus=false" ng-blur="focus=true" name="end_date" required/>
							<span class="input-group-btn">
								<button type="button" ng-disabled="!permissions['apartments.update']" class="btn btn-default" ng-click="end_date_open = !end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
							</span>
							</p>
							<p ng-show="apartmentContractsForm.end_date.$invalid && apartmentContractsForm.end_date.$touched" class="help-block">
								Required
							</p>
						</div>

						<div ng-show="data.id != 'new'" class="form-group" ng-class="{'has-error': apartmentContractsForm.file.$invalid && apartmentContractsForm.file.$touched}">
							<label>Attachment</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="attachment"
								ng-readonly="true"
								ng-model="data.attachment"/>						
						</div>  

						<div class="form-group" ng-class="{'has-error': apartmentHandOverForm.file.$invalid && apartmentHandOverForm.file.$touched}">
						
							<label>Add Attachment</label>
								<input type="file" ngf-select ng-model="file" name="file" ngf-pattern="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'"
									ngf-accept="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'" ngf-max-size="4MB">	
								<p ng-show="apartmentHandOverForm.file.$invalid && apartmentHandOverForm.file.$touched" class="help-block">
									Please ensure image size is no greater than 4MB
								</p>  					
						</div>  
						
						
						<div class="form-group" ng-class="{'has-error': apartmentContractsForm.notes.$invalid && apartmentContractsForm.notes.$touched}">
							<label>Notes</label>
							<textarea ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="notes"
									ng-readonly="!permissions['apartments.update']"
									  ng-model="data.notes"/></textarea>          
							<p ng-show="apartmentContractsForm.notes.$invalid && apartmentContractsForm.notes.$touched" class="help-block">
								Required
							</p>
						</div>
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="saveRecord()"
										show-spinner="showSaveSpinner"												
										ng-disabled="apartmentContractsForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,      
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Apartment Details"
					},
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, Upload, $uibModalInstance, data, title, permissions, ApartmentsService, UtilBroadcastService, $timeout) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;	
				
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var message, url;
						
						if($scope.data.id != 'new'){
							url = 'api/apartment-contracts/update/'+$scope.data.id;
							message = "Apartment Contract Updated."
						} else {
							url = 'api/apartment-contracts/store';
							message = "Apartment Contract Stored";
						}
						
						Upload.upload({
							url: url,
							data: {file: $scope.file, 
									'apartment_id': $scope.data.apartment_id,
									'organisation_id': $scope.data.organisation_id,
									'start_date': $scope.data.start_date,
									'end_date': $scope.data.end_date,
									'rent': $scope.data.rent,
									'notes': $scope.data.notes,
									'type': $scope.data.type
									}
						}).then(function (resp) {
							if($scope.file) console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
							
							self.sendSpinner = false;					
							
							$scope.apartmentContractsForm.$setPristine();	
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = resp.data;					
							
						}, function (resp) {
							console.log('Error status: ' + resp.status);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}, function (evt) {
							var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
		
							if($scope.file)console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();	
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);										
						});									
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}
				}
            };
	}	
	
			
	return modalApartmentContract;
}])