angular.module('bookings.modals')

.controller('ModalUnsentInvoicesByInvoiceController', ['$scope', 'title', 'id', 'permissions', '$uibModalInstance', 'ModalFeedback',
				'$timeout', 'BookingsService', 'InvoicesService', '$uibModal', 'ModalGetEmailAddress', '$rootScope', '$state',
				 function($scope,  title, id, permissions, $uibModalInstance, ModalFeedback,
             $timeout, BookingsService, InvoicesService, $uibModal, ModalGetEmailAddress, $rootScope, $state) {
    
		$scope.title = title;
		$scope.data = [];
		$scope.id = id;
		$scope.permissions = permissions;   
		$scope.showSaveSpinner = false;         
		$scope.showAlert = false;
		$scope.alertDetails = {};
		$scope.dataLoaded = false;

		function getUnsentInvoices(){
			$scope.dataLoaded = false;
			$scope.data = [];
			BookingsService.getUnsentInvoices($scope.id).then(function(success){
				$scope.data = success.data;
				broadcastUpdate();
			}, function(error){
				console.log(error);
			}).finally(function(){
				$scope.dataLoaded = true;
			});
		}

		getUnsentInvoices();

		$scope.goToInvoice = function(invoice){
			$uibModalInstance.close('saved');
			$state.go('main.invoice', {'id': invoice.id}, {reload: true});
		}

		/**************************************/
		/*			Send Emails               */
		/**************************************/

		$scope.sendEmail = function (invoice) {

			var modalInstance = $uibModal.open(ModalGetEmailAddress.show(invoice.booking_id, $scope.permissions, 'Email Invoice'));

			modalInstance.result.then(function (email_address) {
				var mailConfig = { params: {} };
				mailConfig.params.email_address = email_address;
				invoice.showEmailSpinner = true;
				InvoicesService.getEmailInvoice(invoice.id, mailConfig).then(function (success) {
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));
					$timeout(function () { modalInstance1.close('cancel'); }, 2000);
				}, function (error) {
					console.log(error);
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
						'Unable to send email.', 'alert-danger'));
					$timeout(function () { modalInstance1.close('cancel'); }, 2000);
				}).finally(function () {
					invoice.showEmailSpinner = false;
					getUnsentInvoices();
				});

			});
		}
		

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		}
		
		function broadcastUpdate(){
			$rootScope.$broadcast('updateSidebars', {
				data: true
			});
		}
       
    }   
])

