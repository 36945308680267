angular.module('apartment-details-defaults.controllers', [])

.controller('ApartmentDetailsDefaultsController', ['$state', 'ApartmentDetailsDefaultsService', 'PermissionsService', '$uibModal', 'ModalConfirmAction', 'ModalFeedback', '$timeout',
	function($state, ApartmentDetailsDefaultsService, PermissionsService, $uibModal, ModalConfirmAction, ModalFeedback, $timeout) {
	var self = this;
	
	self.dataLoaded = false;
	self.apartmentDetailDefaults = [];
	self.apartmentDetailDefaults.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];	

	var config = {
		params: { 	
			page:self.apartmentDetailDefaults.current_page, 	
			orderby: 'apartment_details_defaults.id',
			per_page:30
		}				 
	};
		
	PermissionsService.getByModuleByUser('apartments').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
	
	function getApartmentDetailsDefaults(){
		self.dataLoaded = false;		
		ApartmentDetailsDefaultsService.getApartmentDetailsDefaultsPaginate(config).then(function(success){
			self.apartmentDetailDefaults = success.data;		
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}

	getApartmentDetailsDefaults();	

	self.go = function(id){
		$state.go('main.apartment-details-default', {'id': id}, {reload: true});
	}

	self.pagination = function(){			
		config.params.page = self.apartmentDetailDefaults.current_page;
		getApartmentDetailsDefaults();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getApartmentDetailsDefaults();	   
	}
	
	self.search = function(){
		self.apartmentDetailDefaults.current_page = 1;
		config.params.page = self.apartmentDetailDefaults.current_page;
		config.params.search = self.search_value;
		getApartmentDetailsDefaults();
	}

	self.addAllDefaultsToAllApartments = function(){
			
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Confirm', 
			'Are you sure you would like to add all defaults to all apartments?', 
			self.permissions)
		);
		
		modalInstance.result.then(function (msg) {
			
			ApartmentDetailsDefaultsService
				.getAddAllDefaultsToAllApartments()
				.then(function(success){
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
							'Success',
							'Records created!',
							'alert-success'
						)
					);						
					$timeout(function() { modalInstance1.close('cancel'); }, 2000);
				}, function(error){
					var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
							'Error!',
							'Unable to add all defaults to all apartments. Please contact a system administrator.',
							'alert-danger'
						)
					);			
					$timeout(function() { modalInstance1.close('cancel'); }, 2000);
				});		
		});
		
	}
}])