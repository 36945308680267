angular.module('bookings.modals')

	.factory("ModalBookingDetail", [function () {

		var modalBookingDetail = {};


		modalBookingDetail.showDetail = function (data, booking_dates, permissions) {
			return {
				template:
					`<div class="modal-header">
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="modal-body alert">           
					<form name="bookingDetailsForm">
						<div class="row">
							<div class="col-md-6">
								<input ng-focus="focus=false" ng-blur="focus=true"  
											class="form-control" name="id" ng-model="data.id" readonly="" />
							</div>
							<div class="col-md-6">
								<a href="" ng-show="permissions['bookings.update']"  ng-click="useBookingDates()" class="btn btn-success pull-right" >Use Booking Dates</a>
							</div>
						</div>
						
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" ng-class="{'has-error': bookingDetailsForm.check_in_date.$invalid && bookingDetailsForm.check_in_date.$touched}">       
									<label>Check In Date</label>
									<p class="input-group">
										<input ng-model="data.check_in_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
											ng-readonly="!permissions['bookings.update']" 
											datepicker-localdate							
											
											is-open="checkindate_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
											ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
											ng-focus="focus=false" ng-blur="focus=true" name="check_in_date" ng-change="getApartments()" required/>
										<span class="input-group-btn">
											<button tabindex="-1" type="button" class="btn btn-default" 
											ng-disabled="!permissions['bookings.update']" 
											ng-click="checkindate_open = !checkindate_open"><i class="glyphicon glyphicon-calendar"></i></button>
										</span>
									</p>
									<p ng-show="bookingDetailsForm.check_in_date.$invalid && bookingDetailsForm.check_in_date.$touched" class="help-block"> 
										Required
									</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" ng-class="{'has-error': bookingDetailsForm.check_out_date.$invalid && bookingDetailsForm.check_out_date.$touched}">       
									<label>Check Out Date</label>
									<p class="input-group">
										<input ng-model="data.check_out_date" type="text" class="form-control"
										ng-readonly="!permissions['bookings.update']" 
										datepicker-localdate						 
										
										uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										is-open="checkoutdate_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										ng-focus="focus=false" ng-blur="focus=true" name="check_out_date" ng-change="getApartments()" required/>
										<span class="input-group-btn">
											<button tabindex="-1" type="button" class="btn btn-default" 
											ng-disabled="!permissions['bookings.update']" 
											ng-click="checkoutdate_open = !checkoutdate_open"><i class="glyphicon glyphicon-calendar"></i></button>
										</span>
									</p>
									<p ng-show="bookingDetailsForm.check_out_date.$invalid && bookingDetailsForm.check_out_date.$touched" 
										
										class="help-block">
										Required - check out date must be after check in date
									</p>
								</div>
							</div>
						</div>
						
						<div class="row">
							<div class="col-md-6">
								<div ng-if="data.id != 'new'" class="form-group">
								<label>Room Reservation ID</label>
								<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="cm_room_reservation_id"
										ng-model="data.cm_room_reservation_id" readonly/>
								</div>
								
							</div>
							<div class="col-md-6" >
								<div ng-if="data.id == 'new'" class="form-group" ng-class="{'has-error': bookingDetailsForm.apartment_id.$invalid && bookingDetailsForm.apartment_id.$touched}">
									
									<!--<label>Apartment</label>
									<select class="form-control" 
											ng-disabled="data.check_in_date==null || data.check_out_date==null"					
											ng-model="data.apartment_id" 
											ng-change="checkDates()"                   
											ng-options="apartment.id as apartment.address1 for apartment in apartments" required>
									</select>
									<p ng-show="bookingDetailsForm.apartment_id.$invalid && bookingDetailsForm.apartment_id.$touched" class="help-block">
										Required
									</p>-->
									
									<apartment-dropdown
										ng-model="data.apartment_id" 
										form-name="enquiryDetailForm" 
										is-new="true"
										available-by-dates="true"
										available-start-date="data.check_in_date"
										available-end-date="data.check_out_date"
										is-disabled="data.check_in_date==null || data.check_out_date==null"
										title="Apartment">
									</apartment-dropdown>
									
								</div>
								
								<div ng-if="data.id != 'new'" class="form-group" ng-class="{'has-error': bookingDetailsForm.apartment_id.$invalid && bookingDetailsForm.apartment_id.$touched}">
									<label>Apartment</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="apartment_id"
										   ng-model="data.address1" readonly/>
								</div>
								
							</div>          
						</div> 					
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" ng-class="{'has-error': bookingDetailsForm.detail.$invalid && bookingDetailsForm.detail.$touched}">       
									<label>Notes</label>
									<textarea ng-readonly="!permissions['bookings.update']" 
											ng-focus="focus=false" 
											ng-blur="focus=true" type="text" class="form-control" name="detail"
											ng-model="data.notes" /> </textarea>
									<p ng-show="bookingDetailsForm.detail.$invalid && bookingDetailsForm.detail.$touched" class="help-block">
													Required
									</p>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">   	
					<div class="form-group">
						<div class="row">
							<div class="col-sm-3">
								<my-save-button
									click-fn="saveRecord()"
									show-spinner="showSaveSpinner"												
									ng-disabled="bookingDetailsForm.$invalid || overlapping_bookings.length>0"									
								></my-save-button>
								
							</div>
							<div class="col-sm-6">
								<my-form-alert				
									show-alert="showAlert"
									alert-type="alertDetails.type"
									alert-message="alertDetails.message"											
								></my-form-alert>											
							</div>
							<div class="col-sm-3">
								<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
									ng-click="cancel()">Cancel</button>										
							</div>	
						</div>    
					</div>
				</div>
				<!--<div class="modal-footer">        
					<button class="btn btn-success btn-lg pull-left" type="button" 
							ng-show="permissions['bookings.update']"
							ng-disabled="bookingDetailsForm.$invalid || overlapping_bookings.length>0 || !invalid_apartment" 
							ng-click="save()">Save</button>
					<button class="btn btn-warning btn-lg" type="button" ng-click="cancel()">Cancel</button>
				</div>-->
				`,
				animation: true,
				resolve: {
					data: function () {
						return data;
					},
					title: function () {
						return "Booking Details"
					},
					booking_dates: function () {
						return booking_dates;
					},
					permissions: function () {
						return permissions;
					}
				},
				controller: function ($scope, $uibModalInstance, data, title, booking_dates, permissions, BookingsService, UtilBroadcastService, $timeout) {

					$scope.title = title;
					$scope.data = data;

					$scope.booking_dates = booking_dates;
					$scope.showSaveSpinner = false;
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;


					var config = {};


					$scope.saveRecord = function () {
						$scope.showSaveSpinner = true;

						var promise; var message;
						if ($scope.data.id === 'new') {
							promise = BookingsService.postBookingDetailStore($scope.data);
							message = 'Record created';
						} else {
							promise = BookingsService.postBookingDetailUpdate($scope.data.id, $scope.data);
							message = 'Record updated';
						}

						promise.then(function (success) {
							$scope.bookingDetailsForm.$setPristine();
							UtilBroadcastService.showAlert($scope, 'Success', message, false);
							if ($scope.data.id === 'new') $scope.data.id = success.data;
						}, function (error) {
							console.log(error);
							UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
						}).finally(function () {
							UtilBroadcastService.broadcastUpdateRecord();
							$timeout(function () {
								$uibModalInstance.close('close');
							}, 2000);
						});
					};

					$scope.cancel = function () {
						$uibModalInstance.dismiss();
					}

					$scope.useBookingDates = function () {
						$scope.data.check_in_date = $scope.booking_dates.check_in_date;
						$scope.data.check_out_date = $scope.booking_dates.check_out_date;
					}

					function checkDates() {
						if ($scope.data.check_in_date == null || $scope.data.check_out_date == null) return;
						if ($scope.data.check_in_date > $scope.data.check_out_date) {
							$scope.bookingDetailsForm.check_out_date.$setValidity("checkOutBeforeCheckIn", false);
							return;
						} else {
							$scope.bookingDetailsForm.check_out_date.$setValidity("checkOutBeforeCheckIn", true);
						}
					}


					$scope.$watch(function () {
						return $scope.data.apartment_id;
					}, function (value) {
						checkDates();
					}, true);

					$scope.$watch(function () {
						return $scope.data.check_in_date;
					}, function (value) {
						checkDates();
					}, true);

					$scope.$watch(function () {
						return $scope.data.check_out_date;
					}, function (value) {
						checkDates();
					}, true);
				}
			};
		}


		return modalBookingDetail;
	}])