angular.module('organisations.controllers')

.controller('OrganisationController', ['$scope', 'UtilBroadcastService', 'OrganisationsService', '$stateParams', '$uibModal',
				'$state', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', 'organisation_type', '$timeout', 'UtilLookUpService',
        function($scope, UtilBroadcastService, OrganisationsService,$stateParams, $uibModal, $state, 
				ModalConfirmAction, ModalFeedback, PermissionsService, organisation_type, $timeout, UtilLookUpService) {
    var self = this;

    self.dataLoaded = false;
    self.organisation={};
    var id = $stateParams.id;
    self.isNew = (id=='add-new');       
	self.permissions= [];	
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};
	var config = {	params: {}	};
	self.organisation_type = organisation_type;
	self.booking_payment_terms = UtilLookUpService.getPaymentTermsForBookings();
	self.booking_cancellation_terms = UtilLookUpService.getCancellationTermsForBookings();
	self.booking_deposit_information = UtilLookUpService.getDepositInformationForBookings();

	
    PermissionsService.getByModuleByUser('organisations').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   

    function getOrganisation(){
        OrganisationsService.getOrganisation(id).then(function(success){
            self.organisation = success.data;
            self.dataLoaded = true;
        }, function(error){
            console.log(error);
        });
    }
	

    if( !self.isNew ){ 
		getOrganisation();
	} else {			
		self.organisation.status = 'active';
		self.organisation.type = organisation_type.type;
		self.dataLoaded = true;
	}
	
    self.saveRecord = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = OrganisationsService.postStore(self.organisation);
			message = 'Record created';			
		} else {
			promise = OrganisationsService.postUpdate(id, self.organisation);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.organisationForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, self.organisation_type.state_single);								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});		
    }

    self.deleteRecord = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);

        modalInstance.result.then(function (msg) {
            OrganisationsService.getDelete(id).then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.clients', {}, {reload: true});
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
		});
       

    }


}])