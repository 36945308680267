angular.module('menus.services', [])

.factory("MenuService", ['$http', '$rootScope', '$auth',
 	function ($http, $rootScope, $auth) {

			var menuService = {};

			menuService.getAuthenticatedUser = function () {
				return $http.get('api/authenticate/user');
			}

			menuService.getLogMeOutAs = function() {
				
				return $http.get('api/user/log-me-out-as')
				.then(function (success) {
					$auth.setToken(success.data.token);
					return $http.get('api/authenticate/user');
				}).then(function (response) {		
					var user = JSON.stringify(response.data);
					localStorage.setItem('user', user);
					$rootScope.authenticated = true;
					$rootScope.currentUser = response.data;	
				}, function (error) {
					console.log(error.data.error);
				});
			}


			return menuService;
		}])



