angular.module('schedulers.modals')

.controller('ModalSchedulerBookingController', ['$scope', 'data', 'title', '$uibModalInstance',  'ModalSchedulerAddEditClean', 'ModalSchedulerMultipleClean', 'permissions',
    'BookingsService', 'ApartmentsService', 'OrganisationsService', 'ContactsService', 'DevelopmentsService', '$timeout', '$uibModal', 'UtilLookUpService', 'UsersService', 'ModalConfigurationService',
		function($scope, data, title, $uibModalInstance, ModalSchedulerAddEditClean, ModalSchedulerMultipleClean, permissions,
            BookingsService, ApartmentsService, OrganisationsService, ContactsService, DevelopmentsService, $timeout, $uibModal, UtilLookUpService, UsersService, ModalConfigurationService) {
	
        $scope.title = title;
        $scope.data = data;

        $scope.page = 1;
        $scope.add_booking_detail=false;		
        $scope.apartments_with_data;				
        
        $scope.booking_states = UtilLookUpService.getStatusForBookings();
        $scope.booking_types = UtilLookUpService.getTypeForBookings();
        $scope.booking_subtypes = UtilLookUpService.getSubtypesForBookings(); 
        $scope.booking_payment_terms = UtilLookUpService.getPaymentTermsForBookings();
        $scope.booking_cancellation_terms = UtilLookUpService.getCancellationTermsForBookings();
        $scope.booking_deposit_information = UtilLookUpService.getDepositInformationForBookings();  	
        $scope.booking_check_in_types = UtilLookUpService.getCheckInTypesForBookings();  	

        $scope.apartments = [];
        $scope.developments = [];				
        $scope.invalid_apartment = true;		
        $scope.saveContactSpinner = false;
        $scope.saveOrganisationSpinner = false;
        $scope.saveBookingDetailSpinner = false;
        $scope.saveSpinner = false;
        $scope.showSuccessAlert = false;
        $scope.confirmationSuccessMessage = ($scope.data.id==='new') ? 'created' : 'updated';
        $scope.permissions = permissions;
        $scope.booking_extras_new = {};

        $scope.bookingDetail = {};	
                
        var config={};
        config.params = {};	
            
                    
        function showAlert(type){
            if(type==='success')$scope.showSuccessAlert = true;
            if(type==='error')$scope.showFailureAlert = true;		
            
            $timeout(function () {
                $scope.showSuccessAlert = false;
                $scope.showFailureAlert = false;				
            }, 2000);			
        }

            /*************Get Organisation ***********/
        function getOrganisations(){
            config.params = {};	
            config.params.orderby = 'organisations.organisation_name';
            config.params.dropdown = true;
            config.params.findby = 'type';
            config.params.findbyvalue = 'client';
            OrganisationsService.getOrganisations(config).then(function(success){ 				
                $scope.organisations = success.data;						
            }, function(error){
                console.log(error);
            });
        }
        
        getOrganisations();

        /*************Get Contact ***********/
        function getContacts(){
            config.params = {};	
            config = {	params: { 	orderby: 'contacts.contact_name', dropdown: true	}  };
            ContactsService.getContacts(config).then(function(success){    				
                $scope.contacts = success.data;
            }, function(error){
                console.log(error);
            });				
        }
        
        getContacts();
    
        function getDevelopments(){
            config.params = {};	
            config = {	params: { 	orderby: 'developments.name', findby: 'status', findbyvalue: 'active', dropdown: true	}  };	
            DevelopmentsService.getDevelopments(config).then(function(success){     
                $scope.developments = success.data;      				
            }, function(error){
                console.log(error);
            });
        }
        
        getDevelopments();

        function getUsers(){
            UsersService.getUsers().then(function(success){
				$scope.users = success.data
			}, function(error){
				console.log(error)
			});
        }

        getUsers();

        if($scope.data.id==='new'){
            $scope.data.booking_date = moment().format('YYYY-MM-DD');
            $scope.data.fully_invoiced = 'No';
            $scope.data.vat_reduction = 'No';
            $scope.data.unbalanced_invoice = 'No';
            $scope.data.paid = 'No';
            $scope.data.number_of_guests = 1;
            $scope.data.parking_required = 'Yes';
            $scope.data.payment_terms = 'payment_in_advance';
            $scope.data.cancellation_terms = '7_nights';
            $scope.data.disable_invoice_notification = 'No';
            $scope.data.show_booking_start_on_schduler = 1;
            $scope.data.show_end_booking_notification = 'Yes';
            $scope.data.automatic_invoicing = 'No';
            $scope.data.check_in_details_type = 'full_service';
            $scope.data.person_checking_in = 5;
            $scope.data.parking_required = 'No';
            $scope.data.type = 'booking';
            $scope.data.subtype = 'standard';
            $scope.data.status = 'active';
            $scope.data.booking_extras = [];
            $scope.dataLoaded = true;

            if($scope.data.organisation_id != null) getAccountingInformation();
        }

        $scope.changePage = function(page_num){
            $scope.page = page_num;
        }

        $scope.updateDuration = function(){					
            if($scope.data.check_out_date != null && $scope.data.check_in_date != null){							
                $scope.data.duration = BookingsService.getDuration($scope.data.check_in_date, $scope.data.check_out_date);					
            }				
            $scope.checkBookingDates();			
        }
        
        $scope.checkBookingDates = function(){
            
            if(typeof $scope.data.check_out_date === "undefined"){
                return;
            }
            
            if(typeof $scope.data.check_in_date === "undefined"){
                return;
            }			
            
            if( $scope.data.check_in_date > $scope.data.check_out_date ){
                $scope.bookingForm.check_out_date.$setValidity("checkOutBeforeCheckIn", false);				
                return;
            } else {
                $scope.bookingForm.check_out_date.$setValidity("checkOutBeforeCheckIn", true);
            }	
        }

        function showUnsentInvoices(){
            BookingsService.getUnsentInvoices($scope.data.id).then(function(success){
                if(success.data.length > 0) {
                    var modalInstance = ModalConfigurationService.unsentInvoicesByBooking($scope.permissions, $scope.data.id);
					
                    modalInstance.result.then(function (data) {
                        
                    });
                }
            }, function(error){
                console.log(error);
            });
        }

        $scope.saveBookingExtra = function(){
            $scope.booking_extras_new.booking_id = $scope.data.id;
            $scope.data.booking_extras.push($scope.booking_extras_new);
            $scope.booking_extras_new = {};
            $scope.bookingExtraForm.$setPristine();		
        }

        $scope.removeBookingExtra = function(item){
            $scope.data.booking_extras.splice( $scope.data.booking_extras.indexOf(item), 1 );
        }
                            
        $scope.saveBooking = function () {
            $scope.saveSpinner = true;
            $scope.data.status = 'active';
            $scope.data.show_end_booking_notification = 'Yes';
            
            if($scope.data.id==='new'){			
                    
                BookingsService.postStore($scope.data).then(function(bookingsResponse){			
                    return BookingsService.getBookingWithBookingDetails(bookingsResponse.data);		
                }).then(function(bookingDetailsResponse){
                    $scope.data = bookingDetailsResponse.data;
                    showAlert('success');
                    showUnsentInvoices();
                }, function(error){
                    var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!', error.data.message, 'alert-danger'));			
					$timeout(function() { 
						modalInstance1.close('cancel'); 
						var newId=false;
						if(self.isNew) newId = error.data.id;
						$scope.bookingForm.$setPristine();		
						UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.booking');	
					}, 5000);
                }).finally(function(){
                    $scope.saveSpinner = false;
                    $scope.page = 1;												
                });	
                
                
            } else {								
                BookingsService.postUpdate($scope.data.id, $scope.data).then(function(success){                
                                
                }, function(error){
                    console.log(error);
                }).finally(function(){
                    $scope.saveSpinner = false;
                    $scope.page = 1;
                    showAlert();
                });
            }			
        }	
        
                
        /* Add new detail to details table*/
        $scope.addDetail = function(withDates = false){
            $scope.bookingDetail.booking_id = $scope.data.id;
            $scope.bookingDetail.apartment_id = data.selectedApartmentID;
            if(withDates){
                $scope.bookingDetail.check_in_date = $scope.data.check_in_date;
                $scope.bookingDetail.check_out_date = $scope.data.check_out_date;
            } else {
                $scope.bookingDetail.check_in_date = null;
                $scope.bookingDetail.check_out_date = null;
            }		
            $scope.add_booking_detail = true;
        }
        
        $scope.showBooking = function(){					
            $scope.add_booking_detail=false;	
            $scope.page = 1;	
        }		
    
        
        $scope.goToBooking = function(){			
            if($scope.data.id!='new'){	
                $uibModalInstance.close($scope.data.id);				
            }
        }				
            
        $scope.cancel = function() {
            $uibModalInstance.close('close');
        }			
        
        $scope.saveBookingDetail = function(){
            $scope.saveBookingDetailSpinner = true;
            BookingsService.postBookingDetailStore($scope.bookingDetail).then(function(success){
                return BookingsService.getBookingWithBookingDetails($scope.data.id);		
            }).then(function(getBookingsResponse){
                $scope.data = getBookingsResponse.data;
                $scope.showBooking();
            }, function(error){
                console.log(error);
            }).finally(function(){
                $scope.saveBookingDetailSpinner = false;
            });
        }
        
        
        
        
        function getApartments(){	
        
            config.params = {};	
            config = {	params: {  	
                status_by_dates: true, 
                status_start_date: $scope.bookingDetail.check_in_date,
                status_end_date: $scope.bookingDetail.check_out_date,
                orderby: 'apartments.address1', 
                dropdown: true	
            }  };
            ApartmentsService.getApartments(config).then(function(success){     		
                $scope.apartments = success.data;
            }, function(error){
                console.log(error);
            });
        }	

        function getAccountingInformation(){				
            BookingsService.getAccountingInformation($scope.data.organisation_id).then(function(success){     	
                $scope.data.commission = success.data.commission;
                $scope.data.deposit_information = success.data.deposit_information;
                $scope.data.payment_terms = success.data.payment_terms;
                $scope.data.cancellation_terms = success.data.cancellation_terms;	
                $scope.data.booking_confirmation_email_address = success.data.sales_email_address;
				$scope.data.accounting_email_address = success.data.accounting_email_address;						
            }, function(error){
                console.log(error);
            });
        }

        $scope.$watch(function () {			
            return $scope.data.organisation_id;
        }, function(newValue, oldValue){
            if( (newValue != oldValue) && angular.isDefined(newValue))	getAccountingInformation();	
        }, true);
        
        $scope.$watch(function () {			
            return $scope.bookingDetail.apartment_id;
        },function(value){			
            if(angular.isNumber($scope.bookingDetail.apartment_id)) checkDates();
        }, true);
        
        $scope.$watch(function () {			
            return $scope.bookingDetail.check_in_date;
        },function(value){
            checkDates();
        }, true);
        
        $scope.$watch(function () {
            return $scope.bookingDetail.check_out_date;
        },function(value){
            checkDates();
        }, true);

        $scope.$watch(function () {
            return $scope.data.type;
        },function(newValue, oldValue){
            if( (newValue != oldValue) && angular.isDefined(newValue)) 
            {
                $scope.data.subtype = 'standard';
            }		
        }, true);		
        
            
        function checkDates(){	
            if($scope.bookingDetail.check_in_date == null || $scope.bookingDetail.check_out_date ==  null) return;  
            if( $scope.bookingDetail.check_in_date > $scope.bookingDetail.check_out_date ){
                $scope.bookingForm.bookingDetailsForm.booking_detail_check_out_date.$setValidity("checkOutBeforeCheckIn", false);					
                return;
            } else {				
                if($scope.bookingForm.bookingDetailsForm)
                $scope.bookingForm.bookingDetailsForm.booking_detail_check_out_date.$setValidity("checkOutBeforeCheckIn", true);
                getApartments();
            }	
                    
            if($scope.bookingDetail.apartment_id != null && 
                angular.isNumber($scope.bookingDetail.apartment_id) &&
                $scope.bookingDetail.check_in_date != null &&
                $scope.bookingDetail.check_out_date != null) 
                validateDates();	
        }
            
        
        function validateDates(){
                        
            BookingsService.postValidateDates($scope.bookingDetail.id, $scope.bookingDetail).then(function(success){
                $scope.overlapping_bookings = success.data;
            }, function(error){
                console.log(error);
            });
            
            BookingsService.postValidateApartment($scope.bookingDetail).then(function(success){
                $scope.invalid_apartment = success.data;
            }, function(error){
                console.log(error);
            });
            
        }

        $scope.addNewCleaning = function(booking_detail){			
            data = {}; data.id = 'new';			
            if(booking_detail != null) data.apartment_id = booking_detail.apartment_id;				
            showCleaningModal(data);	
        }
        
        $scope.multipleCleans = function(booking_detail){	
            data = {};
            if(booking_detail != null){
                data.apartment_id = booking_detail.apartment_id;
                data.start_date = booking_detail.check_in_date;
                data.end_date = booking_detail.check_out_date;				
            }
            showMulitpleCleaningModal(data);	
        }	
        
        function showCleaningModal(data){
            var permissions = {};
            permissions['cleanings.create'] = $scope.permissions['cleanings.create'];
            permissions['cleanings.update'] = $scope.permissions['cleanings.update'];
            permissions['cleanings.delete'] = $scope.permissions['cleanings.delete'];
            permissions['cleanings.show'] = $scope.permissions['cleanings.show'];
            
            var modalInstance = $uibModal.open(ModalSchedulerAddEditClean.AddEditClean(data, $scope.apartments, permissions));
            
            modalInstance.result.then(function(data){  					
                
            }, function(error){
                console.log(error);                        
            });			
            
            modalInstance.close('cancel');
        }
                
        function showMulitpleCleaningModal(data){
            var modalInstance = $uibModal.open(
                ModalSchedulerMultipleClean.AddMultipleClean(data, $scope.apartments, $scope.permissions)
            );		
            
            modalInstance.result.then(function (data) {                        
                
            }, function(error){
                console.log(error);                        
            });
            modalInstance.close('cancel');
        }
    }	
])