angular.module('bookings.services', [])

.factory("BookingsService", ['$http', '$q', 'UtilService',
	function($http, $q, UtilService){
	 
	var bookingsService = {};                  
	
	bookingsService.getBookings = function(config){            
		if(config==null) config=[];		
		return $http.get('api/booking', config);
	}
	
	bookingsService.getBookingsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/booking/paginate', config);
	}

	bookingsService.getBooking = function(id){            
		return $http.get('api/booking/show/'+id);
	}
	
	bookingsService.getFigures = function(id){            
		return $http.get('api/booking/figures/'+id);
	}
	
	bookingsService.postValidateDates = function(id, object){
		return $http.post('api/booking-details/validate-dates/'+id, object);
	}
	
	bookingsService.postValidateApartment = function(object){
		return $http.post('api/apartment/validate-apartment', object);
	}
	
	bookingsService.getBookingWithBookingDetails = function(id){            
		return $http.get('api/booking/with-booking-details/'+id);
	}
	
	bookingsService.postUpdate = function(id, booking){            
		return $http.post('api/booking/update/'+id, booking);
	}
	/*
	bookingsService.postUpdateWithDetails = function(id, booking){            
		return $http.post('api/booking/update-with-details/'+id, booking);
	}
	*/
	bookingsService.postStore = function(booking){            
		return $http.post('api/booking/store', booking);
	}  

	bookingsService.postStoreWithDetails = function(booking){    
		
		return $http.post('api/booking/store-with-details', booking);
	}  		
	/*
	bookingsService.postStoreWithDetail = function(booking){            
		return $http.post('api/booking/store', booking)
			.then(function(result){
				booking.booking_id = result.data;
			return $http.post('api/booking-details/store', booking)
			})            
	}    
	*/
	bookingsService.getDelete = function(id){            
		return $http.get('api/booking/destroy/'+id);
	}  
	
	bookingsService.postSendConfirmation = function(id, type){            
		return $http.post('api/booking/send-booking-confirmation/'+id, type);
	}
	
	bookingsService.postSendConfirmationWithAttachment = function(data){  			
		return $http.post('api/booking/send-booking-confirmation-with-attachment', data);
	}
	
	bookingsService.getBookingConfirmationData = function(id, type){            
		return $http.get('api/booking/booking-confirmation-data/'+id);
	}

	bookingsService.getUnsentInvoices = function(id){         
		var config = { params: { type: 'unsent' } };
		return $http.get('api/invoice/by-booking/'+id, config);
	}
	
	/* Reports */
	bookingsService.postSummaryReport = function(dates){      
		return $http.post('api/booking/summary-report', dates);
	}

	bookingsService.postSummaryReportPDF = function(dates){            
		//return $http.post('api/booking/summary-report-pdf', dates);

		var defer = $q.defer();
		$http({
			url : 'api/booking/summary-report-pdf',
			method : 'POST',			
			responseType : 'arraybuffer',
			data: dates
		}).success(function(data, status, headers, config) {
			var fileBlob = new Blob([ data ], {
				type : 'application/pdf'
			});
			var fileUrl = URL.createObjectURL(fileBlob);		
			defer.resolve(fileUrl);
		}).error(function(data, status, headers, config) {
			defer.resolve(data);
		});	

		return defer.promise;
	}

	bookingsService.postSummaryReportExcel = function(dates){            
		//return $http.post('api/booking/summary-report-excel', dates);

		var defer = $q.defer();
		$http({
			url : 'api/booking/summary-report-excel',
			method : 'POST',			
			responseType : 'arraybuffer',
			data: dates
		}).success(function(data, status, headers, config) {
			var fileBlob = new Blob([ data ], {
				type : 'application/xlsx'
			});
			var fileUrl = URL.createObjectURL(fileBlob);		
			defer.resolve(fileUrl);
		}).error(function(data, status, headers, config) {
			defer.resolve(data);
		});	

		return defer.promise;
	}
	
	/*Booking Details*/

	 bookingsService.getBookingDetailByBooking = function(id){            
		return $http.get('api/booking-details/by-booking/'+id);
	}
	
	bookingsService.postBookingDetailUpdate = function(id, data){            
		return $http.post('api/booking-details/update/'+id, data);
	}
	
	bookingsService.postBookingDetailStore = function(data){            
		return $http.post('api/booking-details/store', data);
	}
	
	bookingsService.getBookingDetailDelete = function(id){            
		return $http.get('api/booking-details/destroy/'+id);
	}   
	
	bookingsService.getDuration = function(check_in_date, check_out_date){
		return UtilService.getDateDiff(check_in_date, check_out_date);	
	}		

	
	/*Invoices*/
	 
	bookingsService.getInvoicesByBooking = function(booking_id){
		 return $http.get('api/invoice/by-booking/'+booking_id);
	}
	
	/*Drop Downs*/
	
	
	bookingsService.getApartmentsForDropDown = function(){            
		return $http.get('api/apartment/for-dropdown');
	}
	
	/*Validations*/
	
	bookingsService.postValidateDates = function(id, data){
		return $http.post('api/booking-details/validate-dates/'+id, data);
	}
	
	bookingsService.getGenerateInvoiceForBooking = function(id){            
		return $http.get('api/invoice/generate-invoice-for-booking/'+id);
	}

	bookingsService.getAccountingInformation = function(organisation_id){            
		return $http.get('api/organisation/accounting-information/'+organisation_id);
	}

	/* Emails */ 

	bookingsService.getSendEmailConfirmation = function(id, config){
		return $http.get('api/booking/send-email-confirmation/'+id, config == null ? [] : config);
	}

	bookingsService.getSendCheckInEmail = function(id, config){
		return $http.get('api/booking/send-check-in-email/'+id, config == null ? [] : config);
	}

	bookingsService.getSendCheckOutEmail = function(id, config){
		return $http.get('api/booking/send-check-out-email/'+id, config == null ? [] : config);
	}

	/* related email addresses */
	bookingsService.getRelatedEmailAddress = function(id, config){
		return $http.get('api/booking/related-email-address/'+id, config == null ? [] : config);
	}


	/*
     *             Apartment Attachment
     */

	bookingsService.getAttachmentTypes = function(){            
		return ['end_of_stay_report', 'booking_confirmation', 'other'];
	}
    
    bookingsService.getBookingAttachmentByBooking = function(id){            
        return $http.get('api/booking-attachments/by-booking/'+id);
    }
    
    bookingsService.postBookingAttachmentUpdate = function(id, data){            
        return $http.post('api/booking-attachments/update/'+id, data);
    }
    
    bookingsService.postBookingAttachmentStore = function(data){            
        return $http.post('api/booking-attachments/store', data);
    }
    
    bookingsService.getBookingAttachmentDelete = function(id){            
        return $http.get('api/booking-attachments/destroy/'+id);
	}
	
	bookingsService.getNextInvoiceDate = function(id){            
		return $http.get('api/booking/next-invoice-date/'+id);
	}

	bookingsService.getBookingFinancials = function(id){            
		return $http.get('api/booking/booking-financials/'+id);
	}
			
	return bookingsService;
}])