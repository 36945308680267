angular.module('settings.controllers', [])

.controller('SettingsController', ['$state', 'SettingsService', '$scope', '$filter', 'PermissionsService', '$group', 'UtilBroadcastService',
	function($state, SettingsService, $scope, $filter, PermissionsService, $group, UtilBroadcastService) {

	var self = this;
	self.dataLoaded = false;
	self.settings = [];
	self.settingGroups = [];   
	self.permissions = [];
	
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};
	
	
	
	const group = $group;
	
	PermissionsService.getByModuleByUser('settings').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   
    
	
	function getByGroup(){	
		self.dataLoaded = false;		
		SettingsService.postByGroup(group).then(function(success){				
			self.settings = success.data;				
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
	
	getByGroup();
	
	self.saveRecord = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		
		promise = SettingsService.postUpdate(self.settings);
		message = 'Record updated';			
		
		promise.then(function(success){			
			$scope.settingsForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, false, false);								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		});        
    }
	


}])
