angular.module('developments.directives', [])

	.directive('developmentApartments', function (ApartmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				developmentId: '='			
			},      
			template:	`<div class="panel-body">
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded" class="list-group">							
								<a href="#" ui-sref="main.apartment({ id: '{{item.id}}' })" ng-repeat="item in apartments" class="list-group-item">																		
										<i class="fa fa-bank"></i> {{item.address1}} <span class="badge">{{item.beds}} bed(s) / {{item.baths}} bath(s)</span>	 												
								</a>                                   
							</div>                                
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.developmentId;				
				var config = {	params: {}	};
				scope.dataLoaded = false;
				scope.apartments = [];	
				
				function getApartments(){
					config.params={};
					config.params.orderby = 'address1';
					config.params.findby = 'apartments.development_id';
					config.params.findbyvalue = id;
					config.params.status = 'active';
					ApartmentsService.getApartments(config).then(function(success){     		
						scope.apartments = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getApartments();
			}
		};
	})