angular.module('organisations.controllers')

.controller('OrganisationAnalyticsController', ['PermissionsService', 
			function(PermissionsService) {
	var self = this;	
	self.permissions = [];	
	
	var config = {	params: {}	};
		
	PermissionsService.getByModuleByUser('dashboard').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	}); 
	
}]);