angular.module('cleanings.controllers')

.controller('CleaningRotaReportController', ['CleaningsService', '$timeout', 'AreasService',
    function(CleaningsService, $timeout, AreasService) {
        var self = this; 
		
		self.dataLoaded = false;
		self.report_data = {'data': 'no'};
		self.showSpinner = false;		
		self.dates = {};
		self.cleanings = [];	
		self.areas = [];
		self.query = {};
		self.query.area = -1;
		
		function defaultDates(){
			var now = moment(); 
			self.dates.end_date = now.format('YYYY-MM-DD');
			self.dates.start_date = now.format('YYYY-MM-DD');			
			CleaningRota();
		}
	
		defaultDates();
		
		function CleaningRota(){			
			self.dataLoaded = false;
			var data = {};
			data.start_date = self.dates.start_date;
			data.end_date = self.dates.end_date;
			data.area = self.query.area;
			CleaningsService.postCleaningRotaReport(data).then(function(success){
				self.cleanings = success.data;				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;				
			});
		}
		
		function getAreas(){
			var config = {params: {}};
			config.params.orderby = 'areas.area';
			config.params.findby = 'status';
			config.params.findbyvalue = 'active';
			AreasService.getAreas(config).then(function(success){
				self.areas = success.data;
				self.areas.unshift({'id': -1, 'area': '--Any--'});		
			}, function(error){
				console.log(error);
			});
		}
		
		getAreas();
		
		self.refreshData = function(){	
			self.showRotaSpinner = true;
			CleaningRota();	
		}		
		
		
		self.print = function(){
			self.showSpinner = true;
			self.report_data= {
				"template": { "shortid" : "NJDOR75Db" },
				"title": "Cleaning Rota",
				"data": self.cleanings				
				
		   };  
		   $timeout(function() {
				self.showSpinner = false;				
			}, 1000);
		}
		
	
		
	}
])