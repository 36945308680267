angular.module('email-templates', [])

.factory("EmailTemplatesService", ['$http',
    function($http){
     
    var emailTemplatesService = {};                  
    
        
    emailTemplatesService.getEmailTemplates = function(config){ 
        if(config==null) config=[];     
        return $http.get('api/email-template', config);
    }
    
    emailTemplatesService.getShow = function(id){            
        return $http.get('api/email-template/show/'+id);
    }
    
    emailTemplatesService.postUpdate = function(id, data){            
        return $http.post('api/email-template/update/'+id, data);
    }
    
    emailTemplatesService.postStore = function(data){            
        return $http.post('api/email-template/store', data);
    }     
    
    emailTemplatesService.getDelete = function(id){            
        return $http.get('api/email-template/destroy/'+id);
    }
            
    return emailTemplatesService;
}])

