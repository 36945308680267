angular
	.module("form-controls.directives")

	.factory("ModalShowOrganisation", [
		function () {
			var modalShowOrganisation = {};

			modalShowOrganisation.showOrganisation = function (data) {
				return {
					template: `<form name="organisationForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Organisation Details</h3>
					</div>
					<div class="modal-body default">           
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Organisation Name</label>
									<input  type="text" class="form-control" name="organisation_name" 
										ng-model="data.organisation_name" readonly />               
								</div>
							</div>
						
							<div class="col-md-6">
								<div class="form-group">
									<label>Website</label>
									<input  type="text" class="form-control" name="website" 
										ng-model="data.website" readonly />               
								</div>
							</div>

						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Address1</label>
									<input  type="text" class="form-control" name="address1" 
										ng-model="data.address1" readonly />               
								</div>							
							</div>
					
							<div class="col-md-6">
								<div class="form-group">
									<label>Postcode</label>
									<input  type="text" class="form-control" name="postcode" 
										ng-model="data.postcode" readonly />               
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Telephone Number</label>
									<input  type="text" class="form-control" name="tel_number" 
										ng-model="data.tel_number" readonly />               
								</div>
							</div>
				
							<div class="col-md-6">
								<div class="form-group">
									<label>Information Email Address</label>
									<input  type="text" class="form-control" name="information_email_address" 
										ng-model="data.information_email_address" readonly />               
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Sales Email Address</label>
									<input  type="text" class="form-control" name="sales_email_address" 
										ng-model="data.sales_email_address" readonly />               
								</div>
							</div>
			
							<div class="col-md-6">
								<div class="form-group">
									<label>Accounting Email Address</label>
									<input  type="text" class="form-control" name="accounting_email_address" 
										ng-model="data.accounting_email_address" readonly />               
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Supply Team Email Address</label>
									<input  type="text" class="form-control" name="supply_team_email" 
										ng-model="data.supply_team_email" readonly />               
								</div>
							</div>
		
							<div class="col-md-6">


							</div>
						</div>				
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-9"></div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
					animation: true,
					resolve: {
						data: function () {
							return data;
						}
					},
					windowClass: "app-modal-window",
					controller: function ($uibModalInstance, $scope, data) {
						$scope.data = data;

						$scope.cancel = function () {
							$uibModalInstance.dismiss();
						};
					}
				};
			};

			return modalShowOrganisation;
		}
	]);
