angular.module('schedulers.directives', [])

.directive('scheduler', ['$filter', 'UtilService', function($filter, UtilService) {		
    "use strict";
    return {
        restrict : 'EAC',
        replace : true,
        scope :{
			data: '=',		
			resetCurrentPage: '=',	
            dateRange: '=',
			showCleaning: '=',
			runTicker: '=',
			viewBooking: '=',
			viewCleaning: '=',
			viewApartment: '=',
			apartmentsPerPage: '=',
			canvasHeight: '@'

        },
		template: "<canvas style='border: 1px solid black;' id='myCanvas' width='1200' height='{{canvasHeight}}'>",
        link: function (scope, element, attribute) {       
	
			const CANVAS_HEIGHT = 780;
			const CANVAS_WIDTH = 1200;
			const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
			const WEEKNAMES = new Array('SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY');
			const TOP_BAR = 60;
			const ROW_HEIGHT = 50;
			const INNER_ROW_DATE_HEIGHT = 0;
			const COLUMN_WIDTH = 25;
			
			const APARTMENTS_PER_PAGE = scope.apartmentsPerPage;
			
			const APARTMENT_CONTAINER_WIDTH = 280;
			const APARTMENT_PADDING_LEFT = 10;
			const APARTMENT_PADDING_TOP = 10;
			const APARTMENT_WIDTH = 250;
			const APARTMENT_HEIGHT = 40;
			
			var total_apartments = 0;
			var total_apartment_pages = 0;
			var current_page = 0;
			var pagination_start;
			var pagination_end;
			
			//var canvas_height, canvas_width;
            var apartments = [];
            var apartment_y;  var apartment_x;
            var start_grid_x; var start_grid_y;
            var dates=[];
			var cleanings=[];			
            var containerApartments = new createjs.Container();
			var containerDates = new createjs.Container();
			var containerCleans = new createjs.Container();
			var containerBookingInfo = new createjs.Container();
			
			var charLimitApartment=32;
			var charLimitDev=10;
			var charLimitBedConfig=10;
            
			var apartments_data = [];
			var bank_holidays = [];
			var renewal_notification_duration = 0;
			
			function init(){                
                scope.stage = new createjs.Stage(document.getElementById('myCanvas'));
				scope.stage.enableMouseOver();
			}
            
            function render() { 
				scope.stage.removeAllChildren();	
				
				apartments_data = scope.data.data;				
				bank_holidays = scope.data.bank_holidays;
				renewal_notification_duration = scope.data.renewal_notification_duration;
				
                loadDatesArray();                
                initApartments(); 	
			
				addGridLines();              
				addDateLabels();
                addApartments();
				
				addBookings();				
				addCleans();
				
				scope.stage.update();				
							
            }
			
			function initApartments(){
							
				total_apartments = scope.data.data.length;			
				//console.log('total apartments: ' + total_apartments);				
				total_apartment_pages = Math.ceil(total_apartments / APARTMENTS_PER_PAGE);
				//console.log('apartments per page: ' + APARTMENTS_PER_PAGE);
				//console.log('total apartment pages: ' + total_apartment_pages);
				if(current_page>=total_apartment_pages) current_page = 0;				
				//console.log('current page:' + current_page);				
				pagination_start = APARTMENTS_PER_PAGE * current_page;
				//console.log('start: '+pagination_start);
				pagination_end = (current_page == (total_apartment_pages-1)) ? total_apartments : (APARTMENTS_PER_PAGE * (current_page+1));
				//console.log('end: '+pagination_end);
				
				
				
				apartments = [];
                for(var i = 0; i<total_apartments; i++){
                    apartments[i] = {};
                    apartments[i].id = apartments_data[i].id;
					apartments[i].address1 = apartments_data[i].address1;
					apartments[i].address2 = apartments_data[i].address2;
					apartments[i].beds = apartments_data[i].beds;
					apartments[i].bedconfiguration = apartments_data[i].bedconfiguration;
					apartments[i].development = apartments_data[i].development;
					apartments[i].dev_code = apartments_data[i].dev_code;
					apartments[i].distance = apartments_data[i].distance;
					apartments[i].charge = apartments_data[i].charge;                    
					apartments[i].inactivedate = apartments_data[i].inactivedate;
					apartments[i].activedate = apartments_data[i].activedate;
					apartments[i].dev_color = apartments_data[i].dev_color;
					apartments[i].type = apartments_data[i].type;
					apartments[i].subtype = apartments_data[i].subtype;

					                    
                }
				
            }
			
			function addApartments(){
				containerApartments.removeAllChildren();			
				
				var backgroundShape = new createjs.Shape(); 							
                backgroundShape.graphics.beginFill("#FFF").drawRect(0, 0, APARTMENT_CONTAINER_WIDTH, CANVAS_HEIGHT);
				containerApartments.addChild(backgroundShape);
				
				
				//buttonDown				
				var buttonDownContainer = new createjs.Container();				
				
				var buttonBackDown = new createjs.Shape();                         
                buttonBackDown.graphics.beginFill("#fff").drawRoundRect(0, 0, 48, 48, 2, 2, 2, 2);
				buttonDownContainer.addChild(buttonBackDown);
				
				var buttonOutlineDown = new createjs.Shape();                         
                buttonOutlineDown.graphics.beginStroke("#000").drawRoundRect(0, 0, 48, 48, 2, 2, 2, 2);
				buttonDownContainer.addChild(buttonOutlineDown);
				
				var bitmapDown = new createjs.Bitmap("img/chevron-arrow-down.png");
				buttonDownContainer.addChild(bitmapDown);
				bitmapDown.x = 16;
				bitmapDown.y = 16;
				bitmapDown.image.onload = function(){
					scope.stage.update();
				}
				
				buttonDownContainer.x = APARTMENT_PADDING_LEFT+APARTMENT_WIDTH-48;
				buttonDownContainer.y = 5;				
				buttonDownContainer.cursor = 'pointer';
				
				buttonDownContainer.addEventListener('click', function(ev){
					if(current_page<(total_apartment_pages-1)) current_page++;
					//console.log('current_page: '+current_page);
					render();
				});					
					
				containerApartments.addChild(buttonDownContainer);
				
				//buttonUp
				var buttonUpContainer = new createjs.Container();	

				var buttonBackUp = new createjs.Shape();                         
                buttonBackUp.graphics.beginFill("#fff").drawRoundRect(0, 0, 48, 48, 2, 2, 2, 2);
				buttonUpContainer.addChild(buttonBackUp);
				
				var buttonOutlineUp = new createjs.Shape();                         
                buttonOutlineUp.graphics.beginStroke("#000").drawRoundRect(0, 0, 48, 48, 2, 2, 2, 2);
				buttonUpContainer.addChild(buttonOutlineUp);
				
				var bitmapUp = new createjs.Bitmap("img/chevron-arrow-up.png");
				buttonUpContainer.addChild(bitmapUp);
				bitmapUp.x = 16;
				bitmapUp.y = 16;
				bitmapUp.image.onload = function(){
					scope.stage.update();
				}
				
				buttonUpContainer.x = APARTMENT_PADDING_LEFT;
				buttonUpContainer.y = 5;				
				buttonUpContainer.cursor = 'pointer';
				
				buttonUpContainer.addEventListener('click', function(ev){
					if(current_page>0) current_page--;
					//console.log('current_page: '+current_page);
					render();
				});					
					
				containerApartments.addChild(buttonUpContainer);
				
				//pagination text
				var text = (current_page+1) + ' of ' + total_apartment_pages;
				var labelPagination = new createjs.Text(text, "24px Arial", "#000");
				labelPagination.name = 'labelPagination';
				labelPagination.textAlign = 'center';
				labelPagination.x = APARTMENT_CONTAINER_WIDTH/2;
				labelPagination.y = 10;
				
				containerApartments.addChild(labelPagination);
				
				
				var line = new createjs.Shape();                   
				line.graphics.setStrokeStyle(3).beginStroke("#000000");
				line.graphics.moveTo(APARTMENT_CONTAINER_WIDTH , 0); 
				line.graphics.lineTo(APARTMENT_CONTAINER_WIDTH, CANVAS_HEIGHT);
				line.graphics.endStroke();
				containerApartments.addChild(line);
				
				var height = TOP_BAR;				
								
				if(total_apartments > 0){
				
					for(var i = pagination_start; i<pagination_end; i++){	
					
						var address1 = apartments[i].address1+', '+ apartments[i].address2;
						if(address1.length > charLimitApartment){
							var address1 = $filter('limitTo')(address1, charLimitApartment)+'...';
						}
						
						var dev_code = apartments[i].dev_code;				

						var bedconfiguration = apartments[i].bedconfiguration;
						if(apartments[i].bedconfiguration.length > charLimitBedConfig){
							var bedconfiguration = $filter('limitTo')(apartments[i].bedconfiguration, charLimitDev)+'...';
						}
						
						var text1 = dev_code+' - '+address1;						
						var text2;

						if(apartments[i].distance != -1) { text2 = text2 + apartments[i].distance  };
						if(apartments[i].charge != -1) { text2 = text2 + ' ' + apartments[i].charge  };

						var apartmentColor = UtilService.hexToRgb(apartments[i].dev_color);

					
						
						if(apartments[i].type === 'Apartment'){

							switch (apartments[i].beds){
								case 1:
									
									break;
								case 2:
									apartmentColor = UtilService.generateColorShades(apartmentColor, 1);
									break;
								case 3:
									apartmentColor = UtilService.generateColorShades(apartmentColor, 2);
									break;									
							}
							text2 = apartments[i].beds + ' bed - ' + bedconfiguration + ' ';
						}

						if(apartments[i].type === 'Studio'){
							apartmentColor = UtilService.generateColorShades(apartmentColor, 3);
							text2 = 'Studio - ' + bedconfiguration + ' ';
						}

						apartmentColor = UtilService.rgbToHex(apartmentColor.r, apartmentColor.g, apartmentColor.b);
																		
						var apartmentData = {}; apartmentData.id = apartments[i].id; apartmentData.charge = apartments[i].charge; 
						var apartmentStrip = new ui.ApartmentStrip(apartmentData , APARTMENT_PADDING_LEFT, height, APARTMENT_WIDTH, APARTMENT_HEIGHT, text1, text2, apartmentColor);
						
						apartmentStrip.addEventListener('click', function(ev){
							
							var apartmentData  = ev.target.parent.apartmentData ;						
																				
							scope.$apply(function(){								
								scope.viewApartment=apartmentData;					   
							});	
						});	
				
						containerApartments.addChild(apartmentStrip);		
						
						height = height + ROW_HEIGHT;
						
					}
					
				}
                
                containerApartments.x = 0;
                containerApartments.y = 0;                
                scope.stage.addChild(containerApartments);
				
				
            }
			
			function loadDatesArray(){
				dates = [];
                var dates_converted = {};
				var grid_x = APARTMENT_CONTAINER_WIDTH;
				
				var start_dt = scope.dateRange.start_date;				
                dates_converted.start_date = moment({year: start_dt.getFullYear(), 
													month: start_dt.getMonth(), 
													day: start_dt.getDate()}).startOf('day');
				
                var end_dt = scope.dateRange.end_date;
				dates_converted.end_date = moment({year: end_dt.getFullYear(), 
													month: end_dt.getMonth(), 
													day: end_dt.getDate()}).startOf('day');
                
                for (var d = dates_converted.start_date; d <= dates_converted.end_date; d.add(1, 'days')) {                    
                    var obj = {};
					obj.date = moment(d).startOf('day');
                    obj.x = grid_x;
                    dates.push(obj);   
                    grid_x += 25;
                }
                
            }
            
            function addDateLabels(){
				containerDates.removeAllChildren();
				
				var backgroundShape = new createjs.Shape();   
				var width = APARTMENT_CONTAINER_WIDTH+(dates.length*COLUMN_WIDTH);			
                backgroundShape.graphics.beginFill("#fff").drawRect(0, -20, width, 80);
								
				containerDates.addChild(backgroundShape);
				
                var grid_x = APARTMENT_CONTAINER_WIDTH;
                var grid_y = 5;
                
                for (var i = 0; i < dates.length; i++) {
                    
                    var d = dates[i];
					var dt = d.date;
					
                    var shape = new createjs.Shape();        
					shape.graphics.beginFill("#DDA5F0").drawRoundRect(grid_x, grid_y, 20, 50, 2, 2, 2, 2);
					shape.shadow = new createjs.Shadow("#000000", 3, 3, 10);
                    containerDates.addChild(shape);
                    
                    var shape = new createjs.Shape();        
					shape.graphics.beginStroke("#000").drawRoundRect(grid_x, grid_y, 20, 50, 2, 2, 2, 2);					
                    containerDates.addChild(shape);
                    
                    var label = new createjs.Text(dt.date(), "10px Arial", "#000");
                    label.name = 'label'; label.textAlign = 'center';   label.x = grid_x+10;  label.y = grid_y+5;                    
                    containerDates.addChild(label);
                    					
					var text1 = MONTHS[dt.month() ] || null;					
					
                    var label = new createjs.Text(text1.substr(0,1), "10px Arial", "#000");
                    label.name = 'label'; label.textAlign = 'center';   label.x = grid_x+10;  label.y = grid_y+17;                    
                    containerDates.addChild(label);
					
					var label = new createjs.Text(text1.substr(1,1), "10px Arial", "#000");
                    label.name = 'label'; label.textAlign = 'center';   label.x = grid_x+10;  label.y = grid_y+25;                    
                    containerDates.addChild(label);
					
					var label = new createjs.Text(text1.substr(2,2), "10px Arial", "#000");
                    label.name = 'label'; label.textAlign = 'center';   label.x = grid_x+10;  label.y = grid_y+33;                    
                    containerDates.addChild(label);                   
								
					
                    grid_x += COLUMN_WIDTH;
                    
                }
				
				/*	Add dates between lines		*/			
					
				var box_width;	
				var box_height;					
				
				for(var i = 0; i<4; i++){				
					
					var yVal;	
					var xVal;
					
					for (var j = 0; j < dates.length; j++) {					
							
						                   
						var d = dates[j];						
						var dt = d.date;	
												
						box_width = (dt.day()!=6) ? 14 : 22;	
						box_height = 15;
						
						yVal = TOP_BAR+(ROW_HEIGHT * ((i+1)*3))-(box_height);	
						xVal = APARTMENT_CONTAINER_WIDTH+10-(box_width/2)+(COLUMN_WIDTH*j);					
						
						var containerMiniDates = new createjs.Container();
						containerMiniDates.x = xVal;
						containerMiniDates.y = yVal;
						
						var shapeOutline = new createjs.Shape(); 					
						shapeOutline.graphics.beginFill("#ddd").drawRoundRect(0, 0, box_width, box_height, 1, 1, 1, 1);						
						containerMiniDates.addChild(shapeOutline);
						
						var shapeBorder = new createjs.Shape();        
						shapeBorder.graphics.beginStroke("#000").drawRoundRect(0, 0, box_width, box_height, 1, 1, 1, 1);
						containerMiniDates.addChild(shapeBorder);				
						
						var text =  (dt.day()!=6) ? WEEKNAMES[dt.day()].substr(0,1) : moment(dt).format("DD/MM");	
						var fontSize =  (dt.day()!=6) ? 10 : 8;	
						
						var label = new createjs.Text(text, fontSize+"px Arial", "#000");
						label.name = 'label'; label.textAlign = 'center';   
						label.x = box_width/2;  
						label.y = (box_height/2)-4;                    
						containerMiniDates.addChild(label);
						
						containerDates.addChild(containerMiniDates);
											
						
					}
					
					yVal = yVal +ROW_HEIGHT;
				}
				
				scope.stage.addChild(containerDates);		
				
            }
			
			function addGridLines(){
				var grid_x = APARTMENT_CONTAINER_WIDTH;
                var grid_y = TOP_BAR;
				var now = moment().startOf('day');				
				var then = moment().startOf('day').add(7, 'days');
				for (var i = 0; i < dates.length; i++) {
                    
                    var d = dates[i];
					var dt = d.date;
					var day = moment(d.date);				
										
					var line = new createjs.Shape();
					
					//check for bank holiday
					var isBankHoliday = false;
					for (var j = 0; j < bank_holidays.length; j++) {
						var bank_holiday = moment(bank_holidays[j]);						
						if(bank_holiday.isSame(day)) isBankHoliday=true;
					}
					
					if(isBankHoliday){
						line.graphics.setStrokeStyle(5).beginStroke("#00ff00");
					} else {
						if(day.isBetween(now, then, 'days', '[]')===true){
							line.graphics.setStrokeStyle(3).beginStroke("#ff0000");
						} else {
							line.graphics.setStrokeStyle(1).beginStroke("#000000");
						}
					}
					
					//gridlines		                  
                    
                    line.graphics.moveTo(grid_x+10 ,grid_y); 
                    line.graphics.lineTo(grid_x+10, grid_y+(14*ROW_HEIGHT));
                    line.graphics.endStroke();
                    scope.stage.addChild(line);
					
					grid_x += 25;
				}
			}
            
            function addBookings(){
				
				var yVal = TOP_BAR;
								
				if(total_apartments > 0){
                //loop through apartments
				
					for(var i = pagination_start; i<pagination_end; i++){
                    
						var data = apartments_data[i].data;
											
						if(data == null){
							return;
						};
						
						var last = dates.length - 1;
						
						//loop through all data items for an apartment
						for(var j = 0; j<data.length; j++){	
														
							//set colour

							var check_in_date, check_out_date, show_start_s1, show_end_s1, show_start_s2, show_end_s2, int_date, color, color1, borderColor, borderColor1, strip1, strip2;					

							if(data[j].type==='booking'){
								if(data[j].subtype==='standard'){
									color = color1 = UtilService.rgbToHex(111, 200, 227);
									borderColor = borderColor1 =  UtilService.rgbToHex(60, 183, 220);
								}
								if(data[j].subtype==='insurance'){
									color = color1 = UtilService.rgbToHex(235, 210, 52);
									borderColor = borderColor1 =  UtilService.rgbToHex(235, 150, 52);
								}
								
							}

							if(data[j].type==='maintenance') {
								color = color1 = UtilService.rgbToHex(212,161,144);
								borderColor = borderColor1 = UtilService.rgbToHex(209,134,107);
							}

							if(data[j].type==='held') {
								color = color1 = UtilService.rgbToHex(20,163,24);
								borderColor = borderColor1 = UtilService.rgbToHex(8, 135, 12);
							}											
							
							check_in_date = moment(data[j].check_in_date).startOf('day'); 
							int_date =  moment(data[j].check_out_date).subtract(renewal_notification_duration, 'days').startOf('day'); 
							check_out_date =  moment(data[j].check_out_date).startOf('day'); 
							
							//ensure booking is longer than 14 days	
							if(int_date.isBefore(check_in_date)){
								int_date = check_out_date;
								strip1 = true;
								strip2 = false;		
								show_start_s1 = check_in_date.isSameOrAfter(dates[0].date);
								show_end_s1 = check_out_date.isSameOrBefore(dates[last].date);
								show_start_s2 = show_end_s2 = false;	
								
							} else {
								
								if(int_date.isBefore(dates[0].date)){									
									strip1 = false;
									strip2 = true;		
									show_start_s1 = show_end_s1 = false;
									show_start_s2 = false;
									show_end_s2 = check_out_date.isSameOrBefore(dates[last].date);
								}

								if(int_date.isAfter(dates[last].date)){																
									strip1 = true;
									strip2 = false;		
									show_start_s1 = check_in_date.isSameOrAfter(dates[0].date);
									show_end_s1 = false;
									show_start_s2 = show_end_s2 = false;
									
								}

								if(int_date.isSameOrAfter(dates[0].date) && int_date.isSameOrBefore(dates[last].date)){
									strip1 = true;
									strip2 = true;		
									show_start_s1 = check_in_date.isSameOrAfter(dates[0].date);
									show_end_s1 = false;
									show_start_s2 = false;
									show_end_s2 = check_out_date.isSameOrBefore(dates[last].date);
								}
							}
							
							if(data[j].show_booking_start_on_schduler==0) show_start_s1 = show_start_s2 = false;

							if(strip2){								
								var contact='';
								if(!strip1) contact = data[j].contact;
								addBookingStrip(data[j].booking_id, color1, borderColor1, yVal, int_date, check_out_date, 
													show_start_s2, show_end_s2, contact, last);	
							}

							if(strip1){														
								addBookingStrip(data[j].booking_id, color, borderColor, yVal, check_in_date, int_date, 
													show_start_s1, show_end_s1, data[j].contact, last);					
							}
					
						}     
						//Set inactivation values
									
						var activedate = moment(apartments[i].activedate).startOf('day');					
						var startdate = moment(dates[0].date).startOf('day');
						var enddate = moment(dates[last].date).startOf('day');				
											
						var dateDiffActive = activedate.diff(startdate, 'days');
						
						var inactivedate;
						var dateDiffInactive=-1;
						
						if(apartments[i].inactivedate != null){					
							inactivedate = moment(apartments[i].inactivedate).startOf('day');
							dateDiffInactive = enddate.diff(inactivedate, 'days');
						}
						
						
						
						if(dateDiffActive>=0){
							//apartment becomes active after startdate
							
							if(activedate.diff(enddate, 'days')<=0){
								//active date is prior to end date						
								var xValInactiveBarStart = dates[0].x+10;
								var dateDiffToEndDate= enddate.diff(activedate, 'days');														
								var xValInactiveBarEnd = dates[dates.length - 1 - dateDiffToEndDate].x+10;	
								var width  = xValInactiveBarEnd - xValInactiveBarStart;
								
								var	bookingStrip = new ui.BookingStrip(-1, 0, '', xValInactiveBarStart, yVal+10, 
																		UtilService.rgbToHex(0,0,0), UtilService.rgbToHex(0,0,0), width, false, false);
								scope.stage.addChild(bookingStrip); 								
							} else {
								//active date is after end date
								var xValInactiveBarStart = dates[0].x+10;
								var xValInactiveBarEnd = dates[last].x+10;
								var width  = xValInactiveBarEnd - xValInactiveBarStart;
								var	bookingStrip = new ui.BookingStrip(-1, 0, '', xValInactiveBarStart, yVal+10, 
																		UtilService.rgbToHex(0,0,0), UtilService.rgbToHex(0,0,0), width, false, false);
								scope.stage.addChild(bookingStrip);    
							}
							
						}
						
						if(dateDiffInactive>=0){
							if(startdate.diff(inactivedate, 'days')>=0){
								//inactive date is prior to start date	
								var xValInactiveBarStart = dates[0].x+10;
								var xValInactiveBarEnd = dates[last].x+10;
								var width  = xValInactiveBarEnd - xValInactiveBarStart;
								var	bookingStrip = new ui.BookingStrip(-1, 0, '', xValInactiveBarStart, yVal+10, 
																		UtilService.rgbToHex(0,0,0), UtilService.rgbToHex(0,0,0), width, false, false);
								scope.stage.addChild(bookingStrip);    
							} else {
								//inactive date is after start date
								
								var xValInactiveBarStart = dates[inactivedate.diff(startdate, 'days')].x+10;
								var xValInactiveBarEnd = dates[last].x+10;
								var width  = xValInactiveBarEnd - xValInactiveBarStart;								
								var	bookingStrip = new ui.BookingStrip(-1, 0, '', xValInactiveBarStart, yVal+10, 
																		UtilService.rgbToHex(0,0,0), UtilService.rgbToHex(0,0,0), width, false, false);
								scope.stage.addChild(bookingStrip);  								
							}
							
						}
						
						yVal = yVal +ROW_HEIGHT;
					
					}
                }
            
            }
			
			function showBookingDetails(ev){				
				
				var booking_id = ev.target.parent.booking_id;		
				
				scope.$apply(function(){
				   scope.viewBooking=booking_id;					   
				});
				               
			}
			
			function addCleans(){
				containerCleans.removeAllChildren();	
					
				var yVal = TOP_BAR+7;
				
				if(total_apartments > 0){
				 //loop through apartments
					for(var i = pagination_start; i<pagination_end; i++){
						
						var cleaning_data = apartments_data[i].cleaning_data;
						var last = dates.length - 1;
						
						//loop through all data items for an apartment
						for(var j = 0; j<cleaning_data.length; j++){
							
							var color = UtilService.rgbToHex(0,0,0);
							var xVal;
							var yVal;
							if(cleaning_data[j].type==='Depart') color = UtilService.rgbToHex(0, 0, 204);							
							if(cleaning_data[j].type==='Service') color = UtilService.rgbToHex(0,0,0);
							if(cleaning_data[j].type==='Note') color = UtilService.rgbToHex(102, 0, 51);
							
							var cleaning_date = moment(cleaning_data[j].cleaning_date).startOf('day');											
							xVal = dates[cleaning_date.diff(dates[0].date, 'days')].x;
							//yVal = yVal+7;

							var cleaningStrip = new ui.CleaningStrip(cleaning_data[j], xVal, yVal, color);
							
							cleaningStrip.addEventListener('click', function(ev){
								showCleaningDetails(ev);		
							});	
							
							containerCleans.addChild(cleaningStrip);					
						}
						
						yVal = yVal +ROW_HEIGHT;
					}
					
				}
				
				scope.stage.addChild(containerCleans);
				
			}
			
			function showCleaningDetails(ev){
				var data = ev.target.parent.data;				
				
				scope.$apply(function(){
				   scope.viewCleaning=data.id;					   
				});			
				          
			}
            
            function startGame(){                
                init();
                render();                			
            }
			
            startGame();	
			
			
			scope.$watch('data', function(newValue, oldValue) {			
				if(newValue != oldValue){									
					render();					
				}				
            });
			
			scope.$watch('dateRange', function(newValue, oldValue) {				
				if(newValue != oldValue){					
					render();													
				}
			});

			scope.$watch('resetCurrentPage', function(newValue, oldValue) {			
				if(newValue > 0){									
					current_page = 0;
					scope.resetCurrentPage = -1;
				}				

            });
			
			function addBookingStrip(booking_id, color, borderColor, yVal, check_in_date, check_out_date, show_booking_start, show_booking_end, contact, last){
				//Set Check In Dates
				var xValCheckIn; var yValCheckIn;
				yValCheckIn = yVal+10;
				
				var xValCheckOut; var yValCheckOut;
				yValCheckOut = yVal+10;
				
				var dateDiffCheckIn = check_in_date.diff(dates[0].date, 'days');
								
				if(dateDiffCheckIn<0){
					//check in date before start date
					xValCheckIn = dates[0].x+10;									
				} else {
					//check in date after start date
					xValCheckIn = dates[dateDiffCheckIn].x+10;						
				}						
			
				var dateDiffCheckOut = dates[last].date.diff(check_out_date, 'days');
			
				if(dateDiffCheckOut<0){
					//check out date after end date
					xValCheckOut = dates[last].x+10;				
				} else {							
					xValCheckOut = dates[dates.length - 1 - dateDiffCheckOut].x+10;												
				}
				
				var width = xValCheckOut - xValCheckIn;
				
				//only show contact name if there is sufficient space
				if (width < 100) contact = '';		
										
				var	bookingStrip = new ui.BookingStrip(booking_id,  show_booking_start, contact, xValCheckIn, yValCheckIn, color, borderColor, width, show_booking_start, show_booking_end);
				
				
				bookingStrip.addEventListener('click', function(ev){					
					showBookingDetails(ev);
				});		
				
				scope.stage.addChild(bookingStrip);
			}
			
		
		
			
           
        }
    };
  
		

}])
