angular.module('organisations.directives', [])

	.directive('organisationFigures', function (BookingsService, REPORT_DETAILS) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				organisationId: '='
			},      
			template:	`<div class="panel panel-default" >
							<div class="panel-heading">
								<h3 class="panel-title"><i class="fa fa-clock-o fa-fw"></i> Figures</h3>
							</div>
							<div class="panel-body">	
								
								<div ng-show="!dataLoaded" class="row text-center">
									<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
								</div>
								<div ng-show="dataLoaded">
									<table class="table"  >
										<tr class="table-row">
											<td colspan="2"><b>All Time</b></td>									
										</tr>	
										<tr class="table-row">
											<td><b>Total</b></td>								
											<td>{{figures.all_time.value}}</td>	
										</tr>
										<tr class="table-row">
											<td><b>Count</b></td>								
											<td>{{figures.all_time.count}}</td>	
										</tr>
										<tr class="table-row">
											<td colspan="2"><b>Last Year</b></td>									
										</tr>								
										<tr class="table-row">
											<td><b>Total</b></td>								
											<td>{{figures.last_year.value}}</td>	
										</tr>
										<tr class="table-row">
											<td><b>Count</b></td>								
											<td>{{figures.last_year.count}}</td>	
										</tr>	
									</table>
									<div class="row">
										<div class="col-lg-12">
											<report-details  report-items="report_details"></report-details>
										</div>
									</div>
								</div>
						
							</div>
						</div>
						
			
						`,
			link: function (scope, element, attr) {
				
				
				var	id = scope.organisationId;
				scope.dataLoaded = false;
				scope.figures = {};
				scope.report_details = REPORT_DETAILS.organisationFigures();
								
				function getFigures(){
					BookingsService.getFigures(id).then(function(success){			
						scope.figures = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getFigures();
				
			}
		};
	})